import React, { Component } from 'react'
import password from '../../../svg/illustrations/password.svg'
import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { ToastContainer, toast } from 'react-toastify'
const eye = <FontAwesomeIcon icon={faEye} />
const closed_eye = <FontAwesomeIcon icon={faEyeSlash} />

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            newPassword: '',
            confirmPassword: '',
            match: null,
            passwordShown: false,
            repeatPasswordShown: false,
            newPasswordShown: false
        }
    }

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    };

    toggleRepeatPasswordVisiblity = () => {
        if (this.state.repeatPasswordShown) {
            this.setState({
                repeatPasswordShown: false
            })
        } else if (!this.state.repeatPasswordShown) {
            this.setState({
                repeatPasswordShown: true
            })
        }
    };

    toggleNewPasswordVisiblity = () => {
        if (this.state.newPasswordShown) {
            this.setState({
                newPasswordShown: false
            })
        } else if (!this.state.newPasswordShown) {
            this.setState({
                newPasswordShown: true
            })
        }
    };

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
    };

    handleNewPasswordChange = (event) => {
        this.setState({
            newPassword: event.target.value
        })
    };

    handleConfirmPasswordChange = (event) => {
        this.setState({
            confirmPassword: event.target.value,
            match: null
        })
    };

    comparePassword = () => {
        if (this.state.newPassword === this.state.confirmPassword) {
            this.setState({
                match: true
            })
        } else {
            this.setState({
                match: false
            })
        }
    }

    onChangePassword = () => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, this.state.password, this.state.newPassword);
            })
            .then(data => {
                toast.success('Password successfully changed!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setTimeout(() => {
                    this.props.handleCloseModal(true)
                }, 1500);

            })
            .catch(err => console.log(err));
    }

    render() {
        const { t } = this.props;

        return (
            <div className="w-70">
                <span className="yg-greeting-parent-name">{t('change_password')}</span>
                <div className="change-email-modal-form">
                    <img src={password} alt="Password" className="yg-parentdb-nochildimg" />
                    <div className="form-group yg-form">
                        <div className="yg-password-form-div-entnewpass w-100">
                            <label htmlFor="yg-password" className="yg-headline-label-dark">{t('password')}</label>
                            <div className="yg-password-form">
                                <input
                                    placeholder={t('type_your_pass')}
                                    name="password"
                                    type={this.state.passwordShown ? "text" : "password"}
                                    className="form-control"
                                    id="yg-password"
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange}
                                />
                                {
                                    this.state.passwordShown ?
                                        <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                        : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                                }
                            </div>
                        </div>
                        <div className="yg-password-form-div-entnewpass w-100">
                            <label htmlFor="yg-password" className="yg-headline-label-dark">{t('new_password')}</label>
                            <div className="yg-password-form">
                                <input
                                    placeholder={t('type_new_pass')}
                                    name="newpassword"
                                    type={this.state.newPasswordShown ? "text" : "password"}
                                    className="form-control"
                                    id="yg-password"
                                    value={this.state.newPassword}
                                    onChange={(event) => this.handleNewPasswordChange(event)}
                                />
                                {
                                    this.state.passwordShown ?
                                        <i onClick={this.toggleNewPasswordVisiblity}>{eye}</i>
                                        : <i onClick={this.toggleNewPasswordVisiblity}>{closed_eye}</i>
                                }
                            </div>
                        </div>
                        <div className="yg-password-form-div-entnewpass w-100">
                            <label htmlFor="yg-password" className="yg-headline-label-dark">{t('repeat_new_pass')}</label>
                            <div className="yg-password-form">
                                <input
                                    placeholder={t('repeat_new_pass_placeholder')}
                                    name="password"
                                    type={this.state.repeatPasswordShown ? "text" : "password"}
                                    className="form-control"
                                    id="yg-password"
                                    value={this.state.confirmPassword}
                                    onChange={(event) => this.handleConfirmPasswordChange(event)}
                                    onBlur={this.comparePassword}
                                />
                                {
                                    this.state.repeatPasswordShown ?
                                        <i onClick={this.toggleRepeatPasswordVisiblity}>{eye}</i>
                                        : <i onClick={this.toggleRepeatPasswordVisiblity}>{closed_eye}</i>
                                }
                            </div>
                            {
                                this.state.match === false ?
                                    <p className="error-msg">{t('passwords_dont_match')}</p>
                                    : null
                            }
                        </div>
                        <div className="yg-btn-form w-100" style={{ display: 'flex' }}>
                            <button
                                type="button"
                                onClick={this.onChangePassword}
                                className="btn yg-btn-min yg-btn-black"
                            >
                                {t('change')}
                            </button>
                            <button
                                type="button"
                                className="btn yg-btn-min yg-btn-gray"
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        );
    }
}