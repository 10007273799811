import React, { Component } from 'react'
import logo_single_big from '../../svg/logos/logo_single_big.svg'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

class Loader extends Component {
    render() {
        return (
            <>
                {
                    this.props.parent.fetchingApi ?
                        <div className="animlogo-div">
                            <img id="animlogo" src={logo_single_big} alt="Logo" />
                        </div> : null
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Loader))