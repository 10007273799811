import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import phone from '../../../svg/illustrations/phone.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
const eye = <FontAwesomeIcon icon={faEye} />
const closed_eye = <FontAwesomeIcon icon={faEyeSlash} />

export default class NewPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            match: null,
            charNumberValid: false,
            specialCharValid: false,
            uppercaseValid: false,
            numberValid: false,
            passwordShown: false,
            repeatPasswordShown: false
        }
    }

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    };
    toggleRepeatPasswordVisiblity = () => {
        if (this.state.repeatPasswordShown) {
            this.setState({
                repeatPasswordShown: false
            })
        } else if (!this.state.repeatPasswordShown) {
            this.setState({
                repeatPasswordShown: true
            })
        }
    };
    // Check the length of the input
    checkPasswordLength = (password) => {
        if (password.length >= 8) {
            this.setState({
                charNumberValid: true
            })
        } else {
            this.setState({
                charNumberValid: false
            })
        }
    }
    // Check for special characters
    checkSpecialCharacters = (password) => {
        const pattern = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]/g;
        if (pattern.test(password)) {
            this.setState({
                specialCharValid: true
            })
        } else {
            this.setState({
                specialCharValid: false
            })
        }
    }
    // Check for an uppercase character
    checkUppercase = (password) => {
        const pattern = /[A-Z]/;
        if (pattern.test(password)) {
            this.setState({
                uppercaseValid: true
            })
        } else {
            this.setState({
                uppercaseValid: false
            })
        }
    }
    // Check for a number
    checkNumber = (password) => {
        const pattern = /[0-9]/;
        if (pattern.test(password)) {
            this.setState({
                numberValid: true
            })
        } else {
            this.setState({
                numberValid: false
            })
        }
    }

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
        this.checkPasswordLength(event.target.value);
        this.checkSpecialCharacters(event.target.value);
        this.checkUppercase(event.target.value);
        this.checkNumber(event.target.value);
    };

    handleConfirmPasswordChange = (event) => {
        this.setState({
            confirmPassword: event.target.value,
            match: null
        })
    };

    comparePassword = (event) => {
        if (this.state.password === this.state.confirmPassword) {
            this.setState({
                match: true
            })
        } else {
            this.setState({
                match: false
            })
        }
    }

    onSubmitNewPass = () => {
        this.props.getUser().confirmPassword(this.props.code, this.state.password, {
            onSuccess: data => {
                toast.success('Password successfully changed!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    window.location.href = '/signin'
                }, 2000);
            },
            onFailure: err => {
                toast.error('Server error. Try again!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    onNavigateToSignIn = () => {
        window.location.href = '/signin'
    }

    render() {
        const { t } = this.props;

        return (
            <div className="connect-bank-from-child pt-5">
                <img src={phone} alt="Bank" />
                <div className="form-group yg-form pt-4">
                    <div className="yg-password-form-div-entnewpass">
                        <label htmlFor="yg-password" className="yg-headline-label-dark">{t('password')}</label>
                        <div className="yg-password-form">
                            <input
                                placeholder={t('type_new_pass')}
                                name="password"
                                type={this.state.passwordShown ? "text" : "password"}
                                className="form-control"
                                id="yg-password"
                                value={this.state.password}
                                onChange={(event) => this.handlePasswordChange(event)}
                            />
                            {
                                this.state.passwordShown ?
                                    <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                    : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                            }
                        </div>
                    </div>
                    <div className="yg-password-form-div-entnewpass">
                        <label htmlFor="yg-password" className="yg-headline-label-dark">{t('repeat_pass')}</label>
                        <div className="yg-password-form">
                            <input
                                placeholder={t('repeat_new_pass_placeholder')}
                                name="password"
                                type={this.state.repeatPasswordShown ? "text" : "password"}
                                className="form-control"
                                id="yg-password"
                                value={this.state.confirmPassword}
                                onChange={(event) => this.handleConfirmPasswordChange(event)}
                                onBlur={this.comparePassword}
                            />
                            {
                                this.state.repeatPasswordShown ?
                                    <i onClick={this.toggleRepeatPasswordVisiblity}>{eye}</i>
                                    : <i onClick={this.toggleRepeatPasswordVisiblity}>{closed_eye}</i>
                            }
                        </div>
                        {
                            this.state.match === false ?
                                <p className="error-msg">{t('passwords_dont_match')}</p>
                                : null
                        }
                    </div>
                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                        <button
                            type="button"
                            className="btn  yg-btn yg-btn-black"
                            onClick={this.onSubmitNewPass}
                        >
                            {t('send')}
                            <ToastContainer
                                position="bottom-left"
                                autoClose={1000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </button>
                        <button
                            type="button"
                            className="btn  yg-btn yg-btn-gray"
                            onClick={this.onNavigateToSignIn}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}