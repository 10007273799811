import { API } from 'aws-amplify';

const create = () => {

    const getCode = (body, token) => {
        console.log(body)
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("YounGoMoneyAPI", "/verification/getCode", requestOptions)
            .then(response => {
                console.log(response)
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return { 'error': true }
            });
    }

    return {
        getCode
    }
}

export default {
    create
}