/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_user_files_s3_bucket": "prod-users-bucket",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:b11654cc-9dd9-47f6-8e33-0487ce977260",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_gst13AZ62",
    "aws_user_pools_web_client_id": "7v19k7hfcpoqaori1qpjnaq37o",
    "oauth": {
        "domain": "prod-youngo-money.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://example.com/callback",
        "redirectSignOut": "https://example.com/signout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cloud_logic_custom": [
        {
            name: "YounGoMoneyAPI",
            endpoint: "https://npt55avtei.execute-api.eu-west-2.amazonaws.com/prod",
            service: "lambda",
            region: "eu-west-2"
        }
    ]
};


export default awsmobile;
