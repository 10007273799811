import React, { Component } from 'react'
import Modal from 'react-modal'
import PropTypes from "prop-types"
import cancel from '../../../svg/cancel.svg'
import calendar from '../../../svg/calendar.svg'
import transfer_green from '../../../svg/transfer_green.svg'
import plus_blue from '../../../svg/plus_blue.svg'
import plus_orange from '../../../svg/plus_orange.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { Chart } from 'react-charts'
import { connect } from 'react-redux'
import CommonActions from '../../../reducers/CommonRedux'
import ChildActions from '../../../reducers/ChildRedux'
import BankActions from '../../../reducers/BankRedux'
import { ToastContainer, toast } from 'react-toastify'
import GoalPaymentWindow from '../../helpers/goal-payment-window'
import moment from "moment"
import {
    AreaChart,
    GridlineSeries,
    Gridline,
    AreaSeries,
    PointSeries,
    LinearXAxis,
    LinearYAxis,
    LinearXAxisTickSeries,
    Line,
    Area,
    Gradient,
    GradientStop,
    LinearYAxisTickSeries,
    LinearYAxisTickLabel,
    LinearXAxisTickLabel
} from 'reaviz'


class Goals extends Component {

    dateFormat = "YYYY-MM-DD"

    constructor(props) {
        super(props)
        this.state = {
            savingAccountModal: false,
            changeGoalNameValue: false,
            goalBalanceUpdated: false,
            data_values: [],
            count: 0,
            transferModal: false,
            amount: "5",
            otherValue: "",
            moneyTransfered: false,
            goalPayment: false,
            url: "",
            goalWindow: false,
            shareEqually: false,
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.props.goalsListRequest({
            child_sub: this.props.user.userData.attributes.sub
        })
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props);
        console.log("prev:",prevProps)

        if (this.props.common.goalDistributeResponse?.data?.success && prevProps.common.fetchingApiButton && this.state.shareEqually){
            toast.success('Undistributed balance shared equally', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                shareEqually: false
            })
            this.props.goalsListRequest({
                child_sub: this.props.user.userData.attributes.sub
            })
        }

        if (prevProps.selectedOption !== this.props.selectedOption) {
            this.props.goalsListRequest({
                child_sub: this.props.user.userData.attributes.sub
            })
        }
        if (this.props.child?.startGoalPaymentResponse?.data?.data?.authorization_flow.actions.next.uri !== undefined && this.state.goalPayment){
            this.setState({
                url: this.props.child?.startGoalPaymentResponse?.data?.data?.authorization_flow.actions.next.uri,
                goalPayment: false,
                goalWindow: true
            })
        }
        if (this.props.child?.updateGoalBalanceResponse?.data?.success && this.state.goalBalanceUpdated) {
            toast.success('Goal balance updated!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                goalBalanceUpdated: false
            })
            setTimeout(() => {
                this.props.goalsListRequest({
                    child_sub: this.props.user.userData.attributes.sub
                })
            }, 1000)
        }
        if (this.props.bank?.getAccountTransactionsResponse?.data?.success && this.state.savingAccountModal && this.state.count === 0) {
            const updatedDataValues = (this.props.bank?.getAccountTransactionsResponse?.data?.data?.chart).map(({
                value: data,
                ...rest
            }) => ({
                data,
                ...rest
            }))
            this.setState({
                data_values: updatedDataValues.map((i) => ({ ...i, key: i.key.slice(-2), })),
                count: 1
            })
        }
        if (this.props.bank?.transferMoneyResponse?.data?.success && this.state.moneyTransfered) {
            toast.success('Money transfered!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                moneyTransfered: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        }
    }

    onWindowResponse = (err, res) => {
        if (err) {
            this.setState({ responseUrl: res })
        }
        this.setState({ responseUrl: res })
        this.setState({
            goalPayment: false,
            goalWindow: false,
        })
        this.props.goalsListRequest({
            child_sub: this.props.user.userData.attributes.sub
        })
    }

    onShowSavingAccount = (e) => {
        var curr = new Date()
        var current = moment(curr).format(this.dateFormat)

        var daysAgo = new Date(curr.getTime())
        daysAgo.setDate(curr.getDate() - 42)
        var sixWeeksAgo = moment(daysAgo).format(this.dateFormat)

        this.props.getAccountTransactionsRequest({
            userId: this.props.user.userData.attributes.sub,
            to: current,
            from: sixWeeksAgo,
            accountType: 2,
            chart: true
        })
        this.setState({
            savingAccountModal: true
        })
    }

    onTransfer = () => {
        this.setState({
            transferModal: true,
            savingAccountModal: false
        })
    }

    onAmountChange = (e) => {
        this.setState({ amount: e.target.id })
    }

    onOtherChange = (e) => {
        this.setState({ otherValue: e.target.value })
    }

    onUpdateGoalBalance = (goal) => {
        this.props.updateGoalBalanceRequest({
            goalId: goal.id,
            amount: (1).toString()
        })
        this.setState({
            goalBalanceUpdated: true
        })
    }

    onTransferToSavingAcc = () => {
        this.props.transferMoneyRequest({
            amount: this.state.amount === "other" ? this.state.otherValue : this.state.amount,
            childId: this.props.user?.userData?.attributes?.sub
        })
        this.setState({ moneyTransfered: true })
    }

    onShareEqually = () => {
        this.props.goalDistributeRequest({
            child_sub: this.props.user.userData.attributes.sub,
            distribute_type: "share_equally"
        })
        this.setState({
            shareEqually: true
        })
    }

    onGoalPayment = (goal) => {        
        this.props.startGoalPaymentRequest({
            goal_id: goal.id
        })
        this.setState({
            goalPayment: true
        })
    }

    render() {
        const { t } = this.props

        const data_values = [
            {
                key: 0,
                data: 5 // value
            },
            {
                key: 5,
                data: 10
            },
            {
                key: 10,
                data: 14
            },
            {
                key: 15,
                data: 5
            },
            {
                key: 20,
                data: 18
            }
        ]

        return (<>
            <Modal
                isOpen={this.props.showGoalsModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}    
                ariaHideApp={false}
            >
                {
                    this.props.common.fetchingApiButton ?
                        <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                            <button
                                type="button"
                                className="btn yg-btn yg-btn-black mt-5"
                            >
                                <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                            </button>
                        </div> :
                        <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                            <span className="yg-greeting-name">
                                {
                                    this.state.transferModal ? t('transfer_to_saving_account') :
                                        this.state.savingAccountModal ? t('saving_account') :
                                            t('goals')
                                }
                            </span>
                            { /* TRANSFER MODAL */
                                this.state.transferModal ?
                                    <>
                                        <div>
                                            <div className="yg-password-form-div pt-5">
                                                <label htmlFor="yg-gender" className="yg-headline-label-light-xs-black">{t('balance')}</label>
                                                <div>
                                                    <span className="yg-card-alwn-costs-q-xs">£</span>
                                                    <span className="yg-card-number-min">{this.props.bank?.getAccountBalanceResponse?.data?.data?.result?.current}</span>                                                    
                                                </div>
                                            </div>
                                            <div className="yg-password-form-div">
                                                <label htmlFor="yg-gender" className="yg-headline-label-light-xs-black">{t('amount')}</label>
                                                <div className="yg-gradient">
                                                    <input type="radio" id="5" name="amount" className={this.props.user.userData.attributes["custom:color"] === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "5"} onChange={this.onAmountChange} />
                                                    <label htmlFor="5" className="rb-label-dashboard"><span className="pound-gray">£</span>5</label>
                                                    <input type="radio" id="10" name="amount" className={this.props.user.userData.attributes["custom:color"] === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "10"} onChange={this.onAmountChange} />
                                                    <label htmlFor="10" className="rb-label-dashboard"><span className="pound-gray">£</span>10</label>
                                                    <input type="radio" id="15" name="amount" className={this.props.user.userData.attributes["custom:color"] === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "15"} onChange={this.onAmountChange} />
                                                    <label htmlFor="15" className="rb-label-dashboard"><span className="pound-gray">£</span>15</label>
                                                    <input type="radio" id="other" name="amount" className={this.props.user.userData.attributes["custom:color"] === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                                                    <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                                                </div>
                                            </div>
                                            {
                                                this.state.amount === "other" ?
                                                    <div className="yg-password-form-div">
                                                        <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                                                        <div className="yg-radio-form-sa pt-2">
                                                            <div className="yg-input-other w-100">
                                                                <span className="yg-card-number-small-time">£</span>
                                                                <input
                                                                    type="text"
                                                                    value={this.state.otherValue.replace(/[^0-9]/, '')}
                                                                    className="rb-input-text-black p-10-5"
                                                                    onChange={this.onOtherChange} />
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                            <div className="yg-btn-form" style={{ display: 'flex' }}>
                                                <button
                                                    type="button"
                                                    className="btn yg-btn yg-btn-black"
                                                    disabled={this.state.amount === "other" ? (this.state.otherValue <= 0 ? true : false) : false}
                                                    onClick={this.onTransferToSavingAcc}
                                                >
                                                    {t('save')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn yg-btn yg-btn-gray"
                                                    onClick={this.props.handleCloseModal}
                                                >
                                                    {t('cancel')}
                                                </button>
                                            </div>
                                        </div>
                                    </> :
                                    /* SAVING ACCOUNT MODAL */
                                    this.state.savingAccountModal ?
                                        <>
                                            <div className="pt-3">
                                                <button
                                                    type="button"
                                                    className={this.props.user.userData.attributes["custom:color"] === "1" ? "btn yg-btn-blue btn-dp" : "btn yg-btn-orange btn-dp"}
                                                    onClick={this.onTransfer}
                                                >
                                                    {t('transfer')}
                                                </button>
                                            </div>
                                            <div className="yg-expenses-form-div mt-4">
                                                <img src={calendar} alt="Calendar" className="expenses-svg" />
                                                <span className="yg-headline-label-light-xs">Last 6 weeks</span>
                                            </div>
                                            <div className="mt-4">
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '120px'
                                                    }}
                                                >
                                                    <AreaChart
                                                        gridlines={<GridlineSeries line={<Gridline direction="none" />} />}
                                                        data={this.state.data_values}
                                                        yAxis={
                                                            <LinearYAxis
                                                                axisLine={null}
                                                                tickSeries={
                                                                    <LinearYAxisTickSeries
                                                                        line={null}
                                                                        label={null}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        xAxis={
                                                            <LinearXAxis
                                                                axisLine={null}
                                                                tickSeries={
                                                                    <LinearXAxisTickSeries
                                                                        line={null}
                                                                        label={null}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        series={
                                                            <AreaSeries
                                                                colorScheme={'#03A6F7'}
                                                                symbols={<PointSeries show={true} />}
                                                                area={
                                                                    <Area
                                                                        gradient={
                                                                            <Gradient
                                                                                stops={[
                                                                                    <GradientStop offset="30%" stopOpacity={0} />,
                                                                                    <GradientStop offset="95%" stopOpacity={1} />
                                                                                ]}
                                                                            />
                                                                        }
                                                                    />
                                                                }
                                                                line={<Line strokeWidth={1} />}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="yg-carddtls-savingaccount mt-3">
                                                <div className="yg-carddtls-expdtls">
                                                    <span className="yg-headline-label-dark py-2">{t('total_savings')}</span>
                                                    <div className="yg-balance-header">
                                                        <div>
                                                            <span className="yg-card-alwn-costs-q-xs">£</span>
                                                            <span className="yg-card-number-min">{this.props.bank?.getAccountTransactionsResponse?.data?.data.total}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.props.bank?.getAccountTransactionsResponse?.data?.data?.data[0]?.items.map(i => {
                                                        return (
                                                            <div className="yg-carddtls-expdtls">
                                                                <div className="yg-carddtls-exprow">
                                                                    <div className="yg-balance-header-left">
                                                                        <span className="yg-cc-topname yg-balance-headername-min">
                                                                            {i.timestamp?.substring(8, 10)}&nbsp;
                                                                            {
                                                                                i.timestamp?.substring(5, 7) === "01" ?
                                                                                    "JANUARY" :
                                                                                    i.timestamp?.substring(5, 7) === "02" ?
                                                                                        "FEBRUARY" :
                                                                                        i.timestamp?.substring(5, 7) === "03" ?
                                                                                            "MARCH" :
                                                                                            i.timestamp?.substring(5, 7) === "04" ?
                                                                                                "APRIL" :
                                                                                                i.timestamp?.substring(5, 7) === "05" ?
                                                                                                    "MAY" :
                                                                                                    i.timestamp?.substring(5, 7) === "06" ?
                                                                                                        "JUNE" :
                                                                                                        i.timestamp?.substring(5, 7) === "07" ?
                                                                                                            "JULY" :
                                                                                                            i.timestamp?.substring(5, 7) === "08" ?
                                                                                                                "AUGUST" :
                                                                                                                i.timestamp?.substring(5, 7) === "09" ?
                                                                                                                    "SEPTEMBER" :
                                                                                                                    i.timestamp?.substring(5, 7) === "10" ?
                                                                                                                        "OCTOBER" :
                                                                                                                        i.timestamp?.substring(5, 7) === "11" ?
                                                                                                                            "NOVEMBER" :
                                                                                                                            i.timestamp?.substring(5, 7) === "12" ?
                                                                                                                                "DECEMBER" :
                                                                                                                                null
                                                                            }
                                                                        </span>
                                                                        <span className="yg-card-number-small-time">{i.timestamp?.substring(11, 16)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="yg-balance-header">
                                                                    <div>
                                                                        <span className="pound-gray">£</span>
                                                                        <span className="yg-headline-label-dark">{i.amount}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </> :
                                        /* GOALS MODAL */
                                        <>
                                            <button className="card-button-goals pt-3" onClick={this.onShowSavingAccount}>
                                                <div className="goals-chart mt-3">
                                                    <div className="goals-lowtitle-title">
                                                        <span className="yg-headline-label-light-xs-black">{t('saving_account')}</span>
                                                        <div className="yg-goals-right">
                                                            <span className="yg-headline-label-light-xxs-black-neutral">{t('balance')}</span>
                                                            <div>
                                                                <span className="yg-card-alwn-costs-q-xs">£</span>
                                                                <span className="yg-card-number-min">{this.props.common?.goalsListResponse?.data?.data?.savingAccount?.data?.available}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '120px'
                                                        }}
                                                    >
                                                        <AreaChart
                                                            gridlines={<GridlineSeries line={<Gridline direction="none" />} />}
                                                            data={data_values}
                                                            yAxis={
                                                                <LinearYAxis
                                                                    axisLine={null}
                                                                    tickSeries={
                                                                        <LinearYAxisTickSeries
                                                                            line={null}
                                                                            label={null}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            xAxis={
                                                                <LinearXAxis
                                                                    axisLine={null}
                                                                    tickSeries={
                                                                        <LinearXAxisTickSeries
                                                                            line={null}
                                                                            label={null}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            series={
                                                                <AreaSeries
                                                                    colorScheme={'#03A6F7'}
                                                                    symbols={<PointSeries show={true} />}
                                                                    area={
                                                                        <Area
                                                                            gradient={
                                                                                <Gradient
                                                                                    stops={[
                                                                                        <GradientStop offset="30%" stopOpacity={0} />,
                                                                                        <GradientStop offset="95%" stopOpacity={1} />
                                                                                    ]}
                                                                                />
                                                                            }
                                                                        />
                                                                    }
                                                                    line={<Line strokeWidth={1} />}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </button>
                                            <div className="goals-lowtitle">
                                                <button className='yg-btn-distribute' onClick={this.onShareEqually}><span className="yg-headline-label-light-xs-blue">{t('share_equally')}</span></button>
                                                <span className="pound-gray">{t('you_can_share_your_undistributed_savings')}</span>
                                            </div>
                                            <div className="yg-gradient untrg-savings">
                                                <span className="yg-headline-label-light-xs-black">{t('undistributed_savings')}</span>
                                                <div>
                                                    <span className="pound-gray">£</span>
                                                    <span className="yg-headline-label-dark">{this.props.common?.goalsListResponse?.data?.data?.undistributedAmount}</span>
                                                </div>
                                            </div>
                                            
                                            {
                                                this.props.common?.goalsListResponse?.data?.data?.goalList?.map((i, k) => {                                                    
                                                    if (i["completed"] === false){
                                                        return (
                                                            <button key={k} className="card-button-goals" onClick={() => this.onUpdateGoalBalance(i)}>
                                                                <div className={this.props.user.userData.attributes["custom:color"] === "1" ? "card yg-selfcard-blue" : "card yg-selfcard-orange"}>
                                                                    {/*<div className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-card-allowance-blue" : "yg-card-allowance-orange"}>*/}
                                                                    <div className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-carddtls-expdtls-blue" : "yg-carddtls-expdtls-orange"}>
                                                                        <span className="yg-headline-label-light-xs-black">{i.name}</span>
                                                                        {
                                                                            this.props.user.userData.attributes["custom:color"] === "1" ?
                                                                                <div>
                                                                                    <img src={plus_blue} alt="Transfer" className="expenses-svg-left" />
                                                                                    <span className="yg-card-number-blue-s">1</span>
                                                                                </div> :
                                                                                <div className="yg-radio-form-sa">
                                                                                    <img src={plus_orange} alt="Transfer" className="expenses-svg-left" />
                                                                                    <span className="yg-card-number-orange-s">1</span>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div className="yg-cc-pb-selfcard">
                                                                        <div className={this.props.user.userData.attributes["custom:color"] === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                                                            <div
                                                                                className={this.props.user.userData.attributes["custom:color"] === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                                                role="progressbar"
                                                                                style={{ width: ((i.balance) / (i.target) * 100).toString() + "%" }}
                                                                                aria-valuenow="25"
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100" />
                                                                        </div>
                                                                        <div className="yg-selfcard-costs">
                                                                            <span className="pound-gray">£</span>
                                                                            <span className="yg-headline-label-dark">{i.balance}/{i.target}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        )
                                                    }
                                                    else if (i["completed"] && i["paid"] === false){             
                                                            return (     
                                                                <GoalPaymentWindow
                                                                    goal={i}
                                                                    bridge={this.onWindowResponse}                                                                
                                                                >
                                                                {/* <button className="card-button-goals" onClick={() => this.onGoalPayment(i)}> */}
                                                                    <div className="card yg-selfcard-green">
                                                                        <div className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-carddtls-expdtls-blue" : "yg-carddtls-expdtls-orange"}>
                                                                            <span className="yg-headline-label-light-xs-black">{i.name}</span>
                                                                            <img src={transfer_green} alt="Transfer" className="expenses-svg-left" />
                                                                        </div>
                                                                        <div className="yg-cc-pb-selfcard">
                                                                            <div className="progress yg-pb-green-selfcard">
                                                                                <div
                                                                                    className="progress-bar yg-pbb-green"
                                                                                    role="progressbar"
                                                                                    style={{ width: "100%" }}
                                                                                    aria-valuenow="25"
                                                                                    aria-valuemin="0"
                                                                                    aria-valuemax="100" />
                                                                            </div>
                                                                            <div className="yg-selfcard-costs">
                                                                                <span className="pound-gray">£</span>
                                                                                <span className="yg-headline-label-dark">{i.balance}/{i.target}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </GoalPaymentWindow>                                                       
                                                        )
                                                    }                                                                                                            
                                                    else if (i.completed && i.paid){
                                                        return (
                                                            
                                                                <div className="card yg-selfcard-completed">
                                                                    <div className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-carddtls-expdtls-blue" : "yg-carddtls-expdtls-orange"}>
                                                                        <span className="yg-headline-label-light-xs-black">{i.name}</span>
                                                                        {/* <img src={transfer_green} alt="Transfer" className="expenses-svg-left" /> */}
                                                                    </div>
                                                                    <div className="yg-cc-pb-selfcard">
                                                                        <div className="progress yg-pb-green-selfcard">
                                                                            <div
                                                                                className="progress-bar yg-pbb-gray"
                                                                                role="progressbar"
                                                                                style={{ width: "100%" }}
                                                                                aria-valuenow="25"
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100" />
                                                                        </div>
                                                                        <div className="yg-selfcard-costs">
                                                                            <span className="pound-gray">£</span>
                                                                            <span className="yg-headline-label-dark">{i.balance}/{i.target}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        
                                                    )   
                                                    }
                                                   
                                                })
                                            }
                                        </>
                            }
                        </div>

                }
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
            </>)
            
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        goalsListRequest: (body) => dispatch(CommonActions.goalsListRequest(body)),
        goalDistributeRequest: (body) => dispatch(CommonActions.goalDistributeRequest(body)),
        updateGoalBalanceRequest: (body) => dispatch(ChildActions.updateGoalBalanceRequest(body)),
        getAccountTransactionsRequest: (body) => dispatch(BankActions.getAccountTransactionsRequest(body)),
        transferMoneyRequest: (body) => dispatch(BankActions.transferMoneyRequest(body)),
        startGoalPaymentRequest: (body) => dispatch(ChildActions.startGoalPaymentRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        common: state.common,
        child: state.child,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Goals)