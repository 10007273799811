import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getNotificationsRequest: ['body'],
    getNotificationsSuccess: ['data'],
    getNotificationsFailure: ['error'],

    getPermissionsRequest: ['body'],
    getPermissionsSuccess: ['data'],
    getPermissionsFailure: ['error'],

    changePermissionsRequest: ['body'],
    changePermissionsSuccess: ['data'],
    changePermissionsFailure: ['error'],
})

export const NotificationTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    getNotificationsResponse: null,
    getNotificationsError: false,
    getNotificationsErrorMessage: '',

    getPermissionsResponse: null,
    getPermissionsError: false,
    getPermissionsErrorMessage: '',

    changePermissionsResponse: null,
    changePermissionsError: false,
    changePermissionsErrorMessage: '',

    fetchingApi: false,
    fetchingApiButton: false
})

/* ------------- Reducers ------------- */

/* ------------- Get Notifications Request ------------- */
export const getNotificationsRequest = (state, action) => {
    return state.merge({
        getNotificationsResponse: null,
        getNotificationsError: false,
        getNotificationsErrorMessage: '',
        fetchingApiButton: true
    })
}
export const getNotificationsSuccess = (state, action) => {
    return state.merge({
        getNotificationsResponse: action.data,
        getNotificationsError: false,
        getNotificationsErrorMessage: '',
        fetchingApiButton: false
    })
}
export const getNotificationsFailure = (state, action) => {
    return state.merge({
        getNotificationsError: true,
        getNotificationsErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Get Permissions Request ------------- */
export const getPermissionsRequest = (state, action) => {
    return state.merge({
        getPermissionsResponse: null,
        getPermissionsError: false,
        getPermissionsErrorMessage: '',
        fetchingApiButton: true
    })
}
export const getPermissionsSuccess = (state, action) => {
    return state.merge({
        getPermissionsResponse: action.data,
        getPermissionsError: false,
        getPermissionsErrorMessage: '',
        fetchingApiButton: false
    })
}
export const getPermissionsFailure = (state, action) => {
    return state.merge({
        getPermissionsError: true,
        getPermissionsErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Change Permissions Request ------------- */
export const changePermissionsRequest = (state, action) => {
    return state.merge({
        changePermissionsResponse: null,
        changePermissionsError: false,
        changePermissionsErrorMessage: '',
        fetchingApiButton: true
    })
}
export const changePermissionsSuccess = (state, action) => {
    return state.merge({
        changePermissionsResponse: action.data,
        changePermissionsError: false,
        changePermissionsErrorMessage: '',
        fetchingApiButton: false
    })
}
export const changePermissionsFailure = (state, action) => {
    return state.merge({
        changePermissionsError: true,
        changePermissionsErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
    [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
    [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,

    [Types.GET_PERMISSIONS_REQUEST]: getPermissionsRequest,
    [Types.GET_PERMISSIONS_SUCCESS]: getPermissionsSuccess,
    [Types.GET_PERMISSIONS_FAILURE]: getPermissionsFailure,

    [Types.CHANGE_PERMISSIONS_REQUEST]: changePermissionsRequest,
    [Types.CHANGE_PERMISSIONS_SUCCESS]: changePermissionsSuccess,
    [Types.CHANGE_PERMISSIONS_FAILURE]: changePermissionsFailure
})