import React, { useEffect, useState } from "react"
import { withRouter, useLocation } from 'react-router-dom'

function SetAllowanceSuccess({ history }) {
    let location = useLocation()
    const [code, setCode] = useState({})
    

    useEffect(() => {
        let finalCode = {}
        const code = location.pathname.includes("set-allowance-redirect-page")                
        finalCode.status = code            
        let a = location.search;
        if (a.length >= 12 && a.substring(0,12) == "?mandate_id="){            
            const mandate_id = a.substring(12)            
            finalCode.mandate_id = mandate_id            
        }        
        console.log(finalCode)
        setCode(code)
    }, [location])

    const close = () => {
        window.opener.onSuccess(code)
        window.close()
    }

    return (
        <div>
            <h1>Set Allowance Success</h1>
            <button onClick={close}>Go Home</button>
        </div>
    )
}

export default withRouter(SetAllowanceSuccess)