import React, { Component } from 'react'
import { connect } from 'react-redux'
import ParentActions from '../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'
import profile_pic from '../../../svg/profile_pic.svg'
import { Auth } from 'aws-amplify'
import PureModal from 'react-pure-modal'
import copy from '../../../svg/copy.svg'
import share from '../../../svg/share.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import start from '../../../svg/illustrations/start.svg'
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share"

class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            avatar: {},
            avatarUrl: "",
            avatarUploaded: false,
            name: "",
            email: "",
            phone_number: "",
            birthday: "",
            gender: "male",
            color: "",
            childInfo: {},
            childDeleted: false,
            attributeChanged: false,
            passGenerated: false,
            showNewPass: false,
            deleteChildWindow: false
        }
    }

    componentDidMount() {
        this.setState({
            childInfo: this.props.childInfo,
            name: this.props.childInfo?.name,
            email: this.props.childInfo?.email,
            phone_number: this.props.childInfo?.phone_number,
            birthday: this.props.childInfo?.birthdate,
            gender: this.props.childInfo?.gender,
            color: this.props.childInfo?.color === "1" ? "blue" : "orange",
            avatarUrl: this.props.childInfo?.photo
        })
    }

    componentDidUpdate() {
        if (this.props.parent?.deleteChildResponse?.data?.success && this.state.childDeleted) {
            toast.success('Child successfully deleted!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            this.setState({
                childDeleted: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        }
        if (this.props.parent?.generateTempPassResponse?.data?.data?.temporaryPassword?.length > 0 && this.state.passGenerated) {
            toast.success('New password generated!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            this.setState({
                passGenerated: false,
                showNewPass: true
            })
        }
    }

    componentWillUnmount() {
        this.setState({ attributeChanged: false })
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value,
            attributeChanged: true
        })
    }

    onEmailChange = (event) => {
        this.setState({
            email: event.target.value,
            attributeChanged: true
        })
    }

    onPhoneChange = (event) => {
        this.setState({
            phone_number: event.target.value,
            attributeChanged: true
        })
    }

    onBirthdayChange = (event) => {
        this.setState({
            birthday: event.target.value,
            attributeChanged: true
        })
    }

    onGenderChange = (e) => {
        this.setState({
            gender: e.target.id,
            attributeChanged: true
        })
    }

    onColorChange = (e) => {
        this.setState({
            color: e.target.id,
            attributeChanged: true
        })
    }

    onDeleteChildProfileApproved = () => {
        this.props.deleteChildRequest({
            childId: this.state.childInfo.sub
        })
        this.setState({
            childDeleted: true,
            deleteChildWindow: false
        })
    }

    onGenerateNewPassword = () => {
        this.props.generateTempPassRequest({
            childId: this.state.childInfo.sub
        })
        this.setState({
            passGenerated: true
        })
    }

    onEditChildProfile = async () => {
        const user = await Auth.currentAuthenticatedUser()
        // var reader = new FileReader()

        if (this.state.avatarUploaded) {
            /*reader?.readAsDataURL(this.state.avatar)
            reader.onload = () => {
                Auth.updateUserAttributes(user, {
                    name: this.state.name,
                    birthdate: this.state.birthday,
                    email: this.state.email,
                    phone_number: this.state.phone_number,
                    gender: this.state.gender,
                    "custom:color": this.state.color === 'blue' ? "1" : "2",
                    photo: reader.result

                }).then((data) => {
                    toast.success('Profile attributes successfully changed!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                    setTimeout(() => {
                        this.props.handleCloseModal(true)
                    }, 1500)
                })
                this.setState({ avatarUploaded: false })
            }*/
        } else {
            await Auth.updateUserAttributes(user, {
                name: this.state.name,
                birthdate: this.state.birthday,
                email: this.state.email,
                phone_number: this.state.phone_number,
                gender: this.state.gender,
                "custom:color": this.state.color === 'blue' ? "1" : "2"
            }).then((data) => {
                toast.success('Profile attributes successfully changed!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                setTimeout(() => {
                    this.props.handleCloseModal(true)
                }, 1500)
            })
        }
    }

    onUploadAvatar = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0]
            this.setState({
                avatarUrl: URL.createObjectURL(img),
                avatar: img,
                attributeChanged: true,
                avatarUploaded: true
            })
        }
    }

    onCloseDeleteChild = () => {
        this.setState({
            deleteChildWindow: false
        })
    }

    onDeleteChildProfile = () => {
        this.setState({
            deleteChildWindow: true
        })
    }

    onCopyText = () => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText('Email is: ' + this.props.parent?.generateTempPassResponse?.data?.data?.child?.email + ', Password is: ' + this.props.parent?.generateTempPassResponse?.data?.data?.temporaryPassword)
            toast.success('Text copied!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            this.unsecuredCopyToClipboard('Email is: ' + this.props.parent?.generateTempPassResponse?.data?.data?.child?.email + ', Password is: ' + this.props.parent?.generateTempPassResponse?.data?.data?.temporaryPassword)
        }
    }

    render() {
        const { t } = this.props

        return (
            <div className="w-80">
                <span className="yg-greeting-parent-name">{this.state.childInfo.name}{t('s_profile')}</span>
                { 
                                            <PureModal
                                                header={t('delete_child')}
                                                footer={
                                                    <div className='yg-radio-form-sa'>
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn-min yg-btn-red"
                                                            onClick={this.onDeleteChildProfileApproved}
                                                        >
                                                            {t('yes_sure')}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn-white-g w-100"
                                                            onClick={this.onCloseDeleteChild}
                                                        >
                                                            {t('cancel')}
                                                        </button>
                                                    </div>
                                                }
                                                isOpen={this.state.deleteChildWindow}
                                                onClose={this.onCloseDeleteChild}
                                            >
                                                <p>{t('sure_cancel_allowance')}</p>
                                            </PureModal>
                }
                {
                    this.state.showNewPass ?
                        <div className="change-email-modal-form pt-5">
                            <img src={start} alt="Let's Start" className="yg-parentdb-nochildimg" />
                            <div className="form-group yg-form-100">
                                <span className="yg-codelogin-headline-main">{t('child_login_info')}</span>
                                <div className="child-info-block mt-3">
                                    <div>
                                        {t('email')}:&nbsp;<span className='yg-headline-label-light-s-blue'>
                                            {this.props.parent?.generateTempPassResponse?.data?.data?.child?.email}
                                        </span>
                                    </div>
                                    <div>
                                        {t('password')}:&nbsp;<span className='yg-headline-label-light-s-blue'>
                                            {this.props.parent?.generateTempPassResponse?.data?.data?.temporaryPassword}
                                        </span>
                                    </div>
                                    <div className="copy-share">
                                        <button className="goforward-button mr-2" onClick={this.onCopyText}>
                                            <img src={copy} alt="Copy" />
                                        </button>
                                    </div>
                                </div>
                                <div className="yg-btn-form-lets-start">
                                    <button
                                        type="button"
                                        onClick={this.props.handleCloseModal}
                                        className="btn yg-btn-thin yg-btn-black"
                                    >
                                        {t('lets_start')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="form-group yg-form-edit-profile pt-4">
                                <div className="edit-profile-form-group">
                                    <div>
                                        <span className="yg-headline-label-dark">{t('profile_photo')}</span>
                                        <div className="image-upload">
                                            <label htmlFor="yg-profile-photo" className="yg-headline-label-dark">
                                                <img src={this.state.avatarUrl === undefined || this.state.avatarUrl.includes("public/undefined") || this.state.avatarUrl === "" ? profile_pic : this.state.avatarUrl} alt="Profile" className="yg-profile-pic-uploaded" />
                                            </label>
                                            <input
                                                type="file"
                                                ref="file"
                                                className="form-control"
                                                id="yg-profile-photo"
                                                onChange={this.onUploadAvatar}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-name" className="yg-headline-label-dark">{t('childs_name')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('ellie_smith')}
                                                name="name"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-name"
                                                value={this.state.name}
                                                onChange={(e) => this.onNameChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-email" className="yg-headline-label-dark">{t('email')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('placeholder_email')}
                                                name="email"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-email"
                                                value={this.state.email}
                                                onChange={(e) => this.onEmailChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('gender')}</label>
                                        <div className="yg-radio-form-sa">
                                            <div className="yg-gradient">
                                                <input type="radio" id="male" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'male'} />
                                                <label htmlFor="male" className="rb-label" >{t('male')}</label>
                                                <input type="radio" id="female" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'female'} />
                                                <label htmlFor="female" className="rb-label" >{t('female')}</label>
                                                <input type="radio" id="non_binary" name="gender" className="rb-input" onChange={this.onGenderChange} checked={this.state.gender === 'non_binary'} />
                                                <label htmlFor="non_binary" className="rb-label" >{t('non_binary')}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-color" className="yg-headline-label-dark">{t('color')}</label>
                                        <div className="yg-radio-form-color">
                                            {
                                                this.state.childInfo.color === "1" ?
                                                    <input type="radio" id="blue" name="color" className="rb-input-color-blue" onChange={this.onColorChange} checked={this.state.color === 'blue'} /> :
                                                    <input type="radio" id="orange" name="color" className="rb-input-color-orange" onChange={this.onColorChange} checked={this.state.color === 'orange'} />
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-phone" className="yg-headline-label-dark">{t('phone_number')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('phone_template')}
                                                name="phone"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-phone"
                                                value={this.state.phone_number}
                                                onChange={(e) => this.onPhoneChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="yg-name" className="yg-headline-label-dark">{t('date_of_birth')}</label>
                                        <div className="yg-password-form">
                                            <input
                                                placeholder={t('20.12.2000')}
                                                name="name"
                                                type="text"
                                                className="form-control yg-form-input"
                                                id="yg-name"
                                                value={this.state.birthday}
                                                onChange={(e) => this.onBirthdayChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.props.childInfo["custom:bankConnection"] === "1" ? null :
                                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                                        <button
                                            type="button"
                                            className="btn yg-btn yg-btn-black"
                                            onClick={this.onGenerateNewPassword}
                                        >
                                            {t('generate_new_password')}
                                        </button>
                                    </div>
                            }
                            <div className="yg-btn-form" style={{ display: 'flex' }}>
                                <button
                                    type="button"
                                    className="btn yg-btn yg-btn-black"
                                    onClick={this.onEditChildProfile}
                                    disabled={this.state.attributeChanged ? false : true}
                                >
                                    {t('save')}
                                </button>
                                <button
                                    type="button"
                                    className="btn yg-btn yg-btn-gray"
                                    onClick={this.props.handleCloseModal}
                                >
                                    {t('cancel')}
                                </button>
                            </div>
                            <div className="yg-btn-form-delete-profile pt-5">
                                <button
                                    type="button"
                                    className="btn yg-btn-red btn-dp"
                                    onClick={this.onDeleteChildProfile}
                                >
                                    {t('delete')}
                                </button>
                            </div>
                        </>
                }
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteChildRequest: (body) => dispatch(ParentActions.deleteChildRequest(body)),
        generateTempPassRequest: (body) => dispatch(ParentActions.generateTempPassRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)