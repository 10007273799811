import React, { Component } from 'react'
import bank from '../../../svg/illustrations/bank.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BankActions from '../../../reducers/BankRedux'
import { BankWindow } from "../../../components/helpers/bank-window"
import BankAccounts from '../common/bank-accounts'
import { Hub } from 'aws-amplify'
import { Redirect } from 'react-router'

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in')
            break
        case 'signUp':
            console.log('user signed up')
            break
        case 'signOut':
            console.log('user signed out')
            break
        case 'signIn_failure':
            console.log('user sign in failed')
            break
        case 'tokenRefresh':
            console.log('token refresh succeeded')
            break
        case 'tokenRefresh_failure':
            console.log('token refresh failed')
            break
        case 'configured':
            console.log('the Auth module is configured')
    }
}

class ConnectBank extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectMainAccount: false,
            sandboxUrl: "",
            urlReceived: false,
            code: "",
            goToNextStep: false,
            redirectToDashboard: false
        }
    }

    componentDidMount() {
        this.props.connectUrlRequest()
        this.setState({ urlReceived: true })
    }

    componentDidUpdate() {
        if (this.props.bank.connectUrlResponse !== null && this.state.urlReceived) {
            Hub.listen('auth', listener)
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('Connect bank Parent | DEV')
                this.setState({
                    sandboxUrl: this.props.bank?.connectUrlResponse?.data?.data.sandbox_connect_bank_url,
                    urlReceived: false,
                    goToNextStep: true
                })
            } else {
                console.log('Connect bank Parent | PROD')
                this.setState({
                    sandboxUrl: this.props.bank?.connectUrlResponse?.data?.data.connect_bank_url,
                    urlReceived: false,
                    goToNextStep: true
                })
            }
        }
        if (this.state.code.length > 0 && this.state.goToNextStep) {
            this.setState({
                selectMainAccount: true,
                goToNextStep: false
            })
        }
    }

    onBankResponse = (err, res) => {
        if (err) {
            this.setState({ code: res })
        }
        this.setState({ code: res })
    }

    handleRedirectToDashboard = (redirectToDashboard) => {
        this.setState({
            redirectToDashboard: redirectToDashboard
        })
    }

    render() {
        const { t } = this.props;

        return (
            <div className="code-form">
                {
                    this.state.redirectToDashboard ?
                        <Redirect push to="/parent-dashboard" /> :
                        this.state.selectMainAccount ?
                            <BankAccounts
                                handleGoToNextStep={this.props.handleGoToNextStep}
                                code={this.state.code}
                                role={"parent"}
                                redirectToDashboard={this.handleRedirectToDashboard}
                            /> :
                            <>
                                <img src={bank} alt="Bank" className="yg-parentdb-nochildimg" />
                                <div className="form-group yg-form">
                                    <span className="yg-codelogin-headline-main">Lorem ipsum dolor sit amet, consetet</span>
                                    <div className="yg-codelogin-form">
                                        <label htmlFor="yg-password" className="yg-headline-label">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</label>
                                    </div>
                                    <div className="yg-btn-form">
                                        {
                                            this.props.bank.fetchingApiButton ?
                                                <button
                                                    type="button"
                                                    className="btn yg-btn yg-btn-black"
                                                >
                                                    <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                                </button> :
                                                <BankWindow
                                                    //url="https://auth.truelayer-sandbox.com/?response_type=code&client_id=sandbox-elephant-f0d8a3&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=http://localhost:3000/redirect-page&providers=uk-cs-mock%20uk-ob-all%20uk-oauth-all"
                                                    bridge={this.onBankResponse}
                                                    url={this.state.sandboxUrl}
                                                >
                                                    {t('connect_to_bank')}
                                                </BankWindow>
                                        }
                                    </div>
                                </div>
                            </>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        connectUrlRequest: () => dispatch(BankActions.connectUrlRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConnectBank))