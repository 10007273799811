import React, { Component } from 'react'

export default class Toggler extends Component {

    render() {
        const { name, checked, labels, handleChange } = this.props;
        const width = 101 / labels.length + "%";
        const style = {
            left:
                101 / labels.length * labels.findIndex(lbl => lbl === checked) + "%",
            width: width
        };
        const styleForParent = {
            left: "-2px",
            width: "50.5%"
        }

        return (
            <div className={"toggle " + name}>
                {labels.map((item, key) => {
                    return (
                        <label key={key} style={{ width: width }} htmlFor={item}>
                            <input
                                onChange={handleChange}
                                type="radio"
                                id={item}
                                name={name}
                                value={item}
                                checked={checked === item}
                            />
                            <div className={this.props.checked === "Parent" && item === "Parent" ? "toggleLabelChecked" :
                                this.props.checked === "Child" && item === "Child" ? "toggleLabelChecked" :
                                    "toggleLabel"}>
                                {item}
                            </div>
                        </label>
                    );
                })}
                <div className="toggler" style={this.props.checked === "Parent" ? styleForParent : style} />
            </div>
        );
    }
}