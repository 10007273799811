import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    choresListRequest: ['body'],
    choresListSuccess: ['data'],
    choresListFailure: ['error'],

    goalsListRequest: ['body'],
    goalsListSuccess: ['data'],
    goalsListFailure: ['error'],

    deleteOwnProfileRequest: ['body'],
    deleteOwnProfileSuccess: ['data'],
    deleteOwnProfileFailure: ['error'],

    goalDistributeRequest: ['body'],
    goalDistributeSuccess: ['data'],
    goalDistributeFailure: ['error']
});

export const CommonTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    choresListResponse: null,
    choresListError: false,
    choresListErrorMessage: '',

    goalsListResponse: null,
    goalsListError: false,
    goalsListErrorMessage: '',

    deleteOwnProfileResponse: null,
    deleteOwnProfileError: false,
    deleteOwnProfileErrorMessage: '',

    goalDistributeResposne: null,
    goalDistributeError: false,
    goalDistributeErrorMessage: '',

    fetchingApiDistribute: false,
    fetchingApiButton: false,
});

/* ------------- Chores List ------------- */
export const choresListRequest = (state, action) => {
    return state.merge({
        choresListResponse: null,
        choresListError: false,
        choresListErrorMessage: '',
        fetchingApiButton: true
    })
}
export const choresListSuccess = (state, action) => {
    return state.merge({
        choresListResponse: action.data,
        choresListError: false,
        choresListErrorMessage: '',
        fetchingApiButton: false
    })
}
export const choresListFailure = (state, action) => {
    return state.merge({
        choresListError: true,
        choresListErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Goals List ------------- */
export const goalsListRequest = (state, action) => {
    return state.merge({
        goalsListResponse: null,
        goalsListError: false,
        goalsListErrorMessage: '',
        fetchingApiButton: true
    })
}
export const goalsListSuccess = (state, action) => {
    return state.merge({
        goalsListResponse: action.data,
        goalsListError: false,
        goalsListErrorMessage: '',
        fetchingApiButton: false
    })
}
export const goalsListFailure = (state, action) => {
    return state.merge({
        goalssListError: true,
        goalsListErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Delete Own Profile ------------- */
export const deleteOwnProfileRequest = (state, action) => {
    return state.merge({
        deleteOwnProfileResponse: null,
        deleteOwnProfileError: false,
        deleteOwnProfileErrorMessage: '',
        fetchingApiButton: true
    })
}
export const deleteOwnProfileSuccess = (state, action) => {
    return state.merge({
        deleteOwnProfileResponse: action.data,
        deleteOwnProfileError: false,
        deleteOwnProfileErrorMessage: '',
        fetchingApiButton: false
    })
}
export const deleteOwnProfileFailure = (state, action) => {
    return state.merge({
        deleteOwnProfileError: true,
        deleteOwnProfileErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Goal Distrbute  -------------------------- */

export const goalDistributeRequest = (state,action) => {
    return state.merge({
        goalDistributeResponse: null,
        goalDistributeError: false,
        goalDistributeErrorMessage: '',
        fetchingApiButton: true,
    })
}

export const goalDistributeSuccess = (state,action) => {
    return state.merge({
        goalDistributeResponse: action.data,
        goalDistributeError: false,
        goalDistributeErrorMessage: '',
        fetchingApiButton: false,
    })
}

export const goalDistributeFailure = (state,action) => {
    return state.merge({
        goalDistributeResponse: null,
        goalDistributeError: true,
        goalDistributeErrorMessage: action.error,
        fetchingApiButton: false,
    })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.CHORES_LIST_REQUEST]: choresListRequest,
    [Types.CHORES_LIST_SUCCESS]: choresListSuccess,
    [Types.CHORES_LIST_FAILURE]: choresListFailure,

    [Types.GOALS_LIST_REQUEST]: goalsListRequest,
    [Types.GOALS_LIST_SUCCESS]: goalsListSuccess,
    [Types.GOALS_LIST_FAILURE]: goalsListFailure,

    [Types.DELETE_OWN_PROFILE_REQUEST]: deleteOwnProfileRequest,
    [Types.DELETE_OWN_PROFILE_SUCCESS]: deleteOwnProfileSuccess,
    [Types.DELETE_OWN_PROFILE_FAILURE]: deleteOwnProfileFailure,

    [Types.GOAL_DISTRIBUTE_REQUEST]: goalDistributeRequest,
    [Types.GOAL_DISTRIBUTE_SUCCESS]: goalDistributeSuccess,
    [Types.GOAL_DISTRIBUTE_FAILURE]: goalDistributeFailure
})