import { takeLatest, all } from 'redux-saga/effects'

import ParentService from '../services/parentService'
import ChildService from '../services/childService'
import CommonService from '../services/commonService'
import VerificationService from '../services/verificationService'
import BankService from '../services/bankService'
import NotificationService from '../services/notificationService'

/* ------------- Types ------------- */
import { ParentTypes } from '../reducers/ParentRedux'
import { ChildTypes } from '../reducers/ChildRedux'
import { CommonTypes } from '../reducers/CommonRedux'
import { VerificationTypes } from '../reducers/VerificationRedux'
import { BankTypes } from '../reducers/BankRedux'
import { NotificationTypes } from '../reducers/NotificationRedux'

/* ------------- Sagas ------------- */
import {
    addChild,
    setAllowance,
    setVrpAllowance,
    setVrpProvider,
    addChores,
    editChore,
    deleteChore,
    addGoal,
    editGoal,
    deleteGoal,
    getParentDashboard,
    getChildList,
    deleteChild,
    approveChore,
    generateTempPass,
    revokeMandate
} from '../sagas/parentSaga'
import { completeChores, setDailyBudget, getDailyBudget, getChildDashboard, updateGoalBalance, requestMoney, startGoalPayment } from '../sagas/childSaga'
import { choresList, goalsList, deleteOwnProfile, goalDistribute } from '../sagas/commonSaga'
import { getCode } from '../sagas/verificationSaga'
import { getNotifications, getPermissions, changePermissions } from '../sagas/notificationSaga'
import { connectUrl, setAccount, getAccounts, getAccountBalance, getAccountsDashboard, getAccountTransactions, transferMoney, getPaymentAll } from '../sagas/bankSaga'

/* ------------- API's ------------- */
const parentService = ParentService.create()
const childService = ChildService.create()
const commonService = CommonService.create()
const verificationService = VerificationService.create()
const bankService = BankService.create()
const notificationService = NotificationService.create()

export default function* root() {
    yield all([
        // Parent
        takeLatest(ParentTypes.GET_PARENT_DASHBOARD_REQUEST, getParentDashboard, parentService),
        takeLatest(ParentTypes.GET_CHILD_LIST_REQUEST, getChildList, parentService),
        takeLatest(ParentTypes.ADD_CHILD_REQUEST, addChild, parentService),
        takeLatest(ParentTypes.SET_ALLOWANCE_REQUEST, setAllowance, parentService),
        takeLatest(ParentTypes.SET_VRP_ALLOWANCE_REQUEST, setVrpAllowance, parentService),
        takeLatest(ParentTypes.SET_VRP_PROVIDER_REQUEST, setVrpProvider, parentService),
        takeLatest(ParentTypes.ADD_CHORES_REQUEST, addChores, parentService),
        takeLatest(ParentTypes.EDIT_CHORE_REQUEST, editChore, parentService),
        takeLatest(ParentTypes.DELETE_CHORE_REQUEST, deleteChore, parentService),
        takeLatest(ParentTypes.APPROVE_CHORE_REQUEST, approveChore, parentService),
        takeLatest(ParentTypes.ADD_GOAL_REQUEST, addGoal, parentService),
        takeLatest(ParentTypes.EDIT_GOAL_REQUEST, editGoal, parentService),
        takeLatest(ParentTypes.DELETE_GOAL_REQUEST, deleteGoal, parentService),
        takeLatest(ParentTypes.DELETE_CHILD_REQUEST, deleteChild, parentService),
        takeLatest(ParentTypes.GENERATE_TEMP_PASS_REQUEST, generateTempPass, parentService),
        takeLatest(ParentTypes.REVOKE_MANDATE_REQUEST, revokeMandate, parentService),

        // Child
        takeLatest(ChildTypes.COMPLETE_CHORES_REQUEST, completeChores, childService),
        takeLatest(ChildTypes.SET_DAILY_BUDGET_REQUEST, setDailyBudget, childService),
        takeLatest(ChildTypes.GET_DAILY_BUDGET_REQUEST, getDailyBudget, childService),
        takeLatest(ChildTypes.GET_CHILD_DASHBOARD_REQUEST, getChildDashboard, childService),
        takeLatest(ChildTypes.UPDATE_GOAL_BALANCE_REQUEST, updateGoalBalance, childService),
        takeLatest(ChildTypes.REQUEST_MONEY_REQUEST, requestMoney, childService),
        takeLatest(ChildTypes.START_GOAL_PAYMENT_REQUEST, startGoalPayment, childService),

        // Common
        takeLatest(CommonTypes.CHORES_LIST_REQUEST, choresList, commonService),
        takeLatest(CommonTypes.GOALS_LIST_REQUEST, goalsList, commonService),
        takeLatest(CommonTypes.DELETE_OWN_PROFILE_REQUEST, deleteOwnProfile, commonService),
        takeLatest(CommonTypes.GOAL_DISTRIBUTE_REQUEST, goalDistribute, commonService),

        // Verification
        takeLatest(VerificationTypes.GET_CODE_REQUEST, getCode, verificationService),

        // Bank
        takeLatest(BankTypes.CONNECT_URL_REQUEST, connectUrl, bankService),
        takeLatest(BankTypes.SET_ACCOUNT_REQUEST, setAccount, bankService),
        takeLatest(BankTypes.GET_ACCOUNTS_REQUEST, getAccounts, bankService),
        takeLatest(BankTypes.GET_ACCOUNT_BALANCE_REQUEST, getAccountBalance, bankService),
        takeLatest(BankTypes.GET_ACCOUNTS_DASHBOARD_REQUEST, getAccountsDashboard, bankService),
        takeLatest(BankTypes.GET_ACCOUNT_TRANSACTIONS_REQUEST, getAccountTransactions, bankService),
        takeLatest(BankTypes.TRANSFER_MONEY_REQUEST, transferMoney, bankService),
        takeLatest(BankTypes.GET_PAYMENT_ALL_REQUEST, getPaymentAll, bankService),

        // Notification
        takeLatest(NotificationTypes.GET_NOTIFICATIONS_REQUEST, getNotifications, notificationService),
        takeLatest(NotificationTypes.GET_PERMISSIONS_REQUEST, getPermissions, notificationService),
        takeLatest(NotificationTypes.CHANGE_PERMISSIONS_REQUEST, changePermissions, notificationService)
    ])
}