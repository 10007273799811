import React, { Component } from 'react'
import DatePicker from 'react-date-picker'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { connect } from 'react-redux'
import ParentActions from '../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'

class SetAllowance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allowance_day: '',
            period: 'weekly',
            amount: '5',
            otherValue: "",
            setAllowanceSet: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.parent.setAllowanceResponse !== null && this.state.setAllowanceSet) {
            if (this.props.parent.setAllowanceResponse.success !== false || this.props.parent.setAllowanceResponse.success !== undefined || this.props.parent.setAllowanceResponse.success !== null) {
                toast.success('Allowance is set!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                setTimeout(() => {
                    this.setState({
                        setAllowanceSet: false
                    })
                    this.props.handleGoToNextStep(true)
                }, 2000);
            }
        }
    }

    onChangeAllowance = (value) => {
        this.setState({
            allowance_day: value
        })
    }

    onPeriodChange = (e) => {
        this.setState({
            period: e.target.id,
            allowance_day: ""
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onOtherChange = (e) => {
        this.setState({
            otherValue: e.target.value.toString()
        })
    }

    onSaveAllowance = () => {
        this.props.setAllowanceRequest({
            child_sub: this.props.childData.sub,
            period: this.state.period === "weekly" ? 1 : 2,
            amount: this.state.amount === "other" ? this.state.otherValue : this.state.amount,
            day: this.state.allowance_day.value,            
        })
        
        this.setState({
            setAllowanceSet: true
        })
    }

    render() {
        const { t } = this.props;

        const customStyles = {
            menu: () => ({
                backgroundColor: this.props.childData.color === "1" ? '#effaff' : '#fff1ea',
                marginTop: '5px',
                borderRadius: '12px',
                padding: '7px 0'
            }),
            control: () => ({
                padding: '5px 15px',
                borderRadius: '12px',
                border: 'none',
                display: 'flex',
                backgroundColor: this.props.childData.color === "1" ? '#effaff' : '#fff1ea'
            }),
            option: (styles, { isFocused, isSelected }) => ({
                cursor: 'pointer',
                padding: '10px 15px',
                color: this.props.childData.color === "1" ? (isSelected ? '#03A6F7' : isFocused ? '#03A6F7' : undefined) : (isSelected ? '#ff5b02' : isFocused ? '#ff5b02' : undefined)
            })
        }

        let weeklyOptions = [
            { value: 1, label: 'Monday' },
            { value: 2, label: 'Tuesday' },
            { value: 3, label: 'Wednesday' },
            { value: 4, label: 'Thursday' },
            { value: 5, label: 'Friday' },
            { value: 6, label: 'Saturday' },
            { value: 7, label: 'Sunday' }
        ]

        let monthlyOptions = [
            { value: 1, label: 'First Week' },
            { value: 2, label: 'Second Week' },
            { value: 3, label: 'Third Week' },
            { value: 4, label: 'Fourth Week' }
        ]

        return (
            <div className="create-accout-form pt-5">
                <div className="form-group yg-form-100">
                    <div className="setallowance-form-group pt-4">
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('period')}</label>
                            <div className="yg-radio-form-sa pt-2">
                                <div className="yg-gradient">
                                    <input type="radio" id="weekly" name="period" className="rb-input" onChange={this.onPeriodChange} checked={this.state.period === 'weekly'} />
                                    <label htmlFor="weekly" className="rb-label" >{t('weekly')}</label>
                                    <input type="radio" id="monthly" name="period" className="rb-input" onChange={this.onPeriodChange} checked={this.state.period === 'monthly'} />
                                    <label htmlFor="monthly" className="rb-label" >{t('monthly')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('amount')}</label>
                            <div className="yg-radio-form-sa pt-2">
                                {
                                    this.state.period === "weekly" ?
                                        <div className="yg-gradient">
                                            <input type="radio" id="5" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === '5'} />
                                            <label htmlFor="5" className="rb-label" >£5</label>
                                            <input type="radio" id="10" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === '10'} />
                                            <label htmlFor="10" className="rb-label" >£10</label>
                                            <input type="radio" id="15" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === '15'} />
                                            <label htmlFor="15" className="rb-label" >£15</label>
                                            <input type="radio" id="other" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === 'other'} />
                                            <label htmlFor="other" className="rb-label" >{t('other')}</label>
                                        </div> :
                                        <div className="yg-gradient">
                                            <input type="radio" id="20" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === '20'} />
                                            <label htmlFor="20" className="rb-label" >£20</label>
                                            <input type="radio" id="30" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === '30'} />
                                            <label htmlFor="30" className="rb-label" >£30</label>
                                            <input type="radio" id="40" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === '40'} />
                                            <label htmlFor="40" className="rb-label" >£40</label>
                                            <input type="radio" id="other" name="amount" className="rb-input" onChange={this.onAmountChange} checked={this.state.amount === 'other'} />
                                            <label htmlFor="other" className="rb-label" >{t('other')}</label>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            this.state.amount === "other" ?
                                <div className="yg-password-form-div">
                                    <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                                    <div className="yg-radio-form-sa pt-2">
                                        <div className="yg-input-other w-100">
                                            <span className="yg-card-number-small-time">£</span>
                                            <input
                                                type="text"
                                                className="rb-input-text-black p-10-5"
                                                value={this.state.otherValue.replace(/[^0-9]/, '')}
                                                onChange={this.onOtherChange} />
                                        </div>
                                    </div>
                                </div> : null
                        }
                        
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-allowance-day" className="yg-headline-label-dark">{t('allowance_day')}</label>
                            <Select
                                className="allowance-select"
                                onChange={this.onChangeAllowance}
                                options={this.state.period === "weekly" ? weeklyOptions : monthlyOptions}
                                isSearchable={false}
                                styles={customStyles}
                            />
                        </div>
                        <div>
                        </div>
                        <div className="yg-btn-form">
                            {
                                this.props.parent.fetchingApiButton ?
                                    <button
                                        type="button"
                                        className="btn yg-btn yg-btn-black"
                                    >
                                        <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                    </button> :
                                    <button
                                        type="button"
                                        onClick={this.onSaveAllowance}
                                        className="btn yg-btn yg-btn-black"
                                        disabled={(this.state.allowance_day.length === 0 ? true : false) || (this.state.amount === "other" && this.state.otherValue === "")}
                                    >
                                        {t('save')}
                                    </button>
                            }
                        </div>
                        <ToastContainer
                            position="bottom-left"
                            className="toast-container-mobile"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAllowanceRequest: (body) => dispatch(ParentActions.setAllowanceRequest(body)),
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SetAllowance)