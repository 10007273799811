import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    connectUrlRequest: [],
    connectUrlSuccess: ['data'],
    connectUrlFailure: ['error'],

    setAccountRequest: ['body'],
    setAccountSuccess: ['data'],
    setAccountFailure: ['error'],

    getAccountsRequest: ['body'],
    getAccountsSuccess: ['data'],
    getAccountsFailure: ['error'],

    getAccountBalanceRequest: [],
    getAccountBalanceSuccess: ['data'],
    getAccountBalanceFailure: ['error'],

    getAccountsDashboardRequest: [],
    getAccountsDashboardSuccess: ['data'],
    getAccountsDashboardFailure: ['error'],

    getAccountTransactionsRequest: ['body'],
    getAccountTransactionsSuccess: ['data'],
    getAccountTransactionsFailure: ['error'],

    transferMoneyRequest: ['body'],
    transferMoneySuccess: ['data'],
    transferMoneyFailure: ['error'],

    getPaymentAllRequest: ['body'],
    getPaymentAllSuccess: ['data'],
    getPaymentAllFailure: ['error'],
});

export const BankTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    connectUrlResponse: null,
    connectUrlError: false,
    connectUrlErrorMessage: '',

    setAccountResponse: null,
    setAccountError: false,
    setAccountErrorMessage: '',

    getAccountsResponse: null,
    getAccountsError: false,
    getAccountsErrorMessage: '',

    getAccountBalanceResponse: null,
    getAccountBalanceError: false,
    getAccountBalanceErrorMessage: '',

    getAccountsDashboardResponse: null,
    getAccountsDashboardError: false,
    getAccountsDashboardErrorMessage: '',

    getAccountTransactionsResponse: null,
    getAccountTransactionsError: false,
    getAccountTransactionsErrorMessage: '',

    transferMoneyResponse: null,
    transferMoneyError: false,
    transferMoneyErrorMessage: '',

    getPaymentAllResponse: null,
    getPaymentAllError: false,
    getPaymentAllErrorMessage: '',

    fetchingApiButton: false
});

/* ------------- Transfer Money ------------- */
export const transferMoneyRequest = (state, action) => {
    return state.merge({
        transferMoneyResponse: null,
        transferMoneyError: false,
        transferMoneyErrorMessage: '',
        fetchingApi: true
    })
}
export const transferMoneySuccess = (state, action) => {
    return state.merge({
        transferMoneyResponse: action.data,
        transferMoneyError: false,
        transferMoneyErrorMessage: '',
        fetchingApi: false
    })
}
export const transferMoneyFailure = (state, action) => {
    return state.merge({
        transferMoneyError: true,
        transferMoneyErrorMessage: action.error,
        fetchingApi: false
    })
}

/* ------------- Connect URL ------------- */
export const connectUrlRequest = (state, action) => {
    return state.merge({
        connectUrlResponse: null,
        connectUrlError: false,
        connectUrlErrorMessage: '',
        fetchingApiButton: true
    })
}

export const connectUrlSuccess = (state, action) => {
    return state.merge({
        connectUrlResponse: action.data,
        connectUrlError: false,
        connectUrlErrorMessage: '',
        fetchingApiButton: false
    })
}

export const connectUrlFailure = (state, action) => {
    return state.merge({
        connectUrlError: true,
        connectUrlErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Set Account ------------- */
export const setAccountRequest = (state, action) => {
    return state.merge({
        setAccountResponse: null,
        setAccountError: false,
        setAccountErrorMessage: '',
        fetchingApiButton: true
    })
}

export const setAccountSuccess = (state, action) => {
    return state.merge({
        setAccountResponse: action.data,
        setAccountError: false,
        setAccountErrorMessage: '',
        fetchingApiButton: false
    })
}

export const setAccountFailure = (state, action) => {
    return state.merge({
        setAccountError: true,
        setAccountErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Get Accounts ------------- */
export const getAccountsRequest = (state, action) => {
    return state.merge({
        getAccountsResponse: null,
        getAccountsError: false,
        getAccountsErrorMessage: '',
    })
}

export const getAccountsSuccess = (state, action) => {
    return state.merge({
        getAccountsResponse: action.data,
        getAccountsError: false,
        getAccountsErrorMessage: ''
    })
}

export const getAccountsFailure = (state, action) => {
    return state.merge({
        getAccountsError: true,
        getAccountsErrorMessage: action.error
    })
}

/* ------------- Get Account Balance ------------- */
export const getAccountBalanceRequest = (state, action) => {
    return state.merge({
        getAccountBalanceResponse: null,
        getAccountBalanceError: false,
        getAccountBalanceErrorMessage: ''
    })
}

export const getAccountBalanceSuccess = (state, action) => {
    return state.merge({
        getAccountBalanceResponse: action.data,
        getAccountBalanceError: false,
        getAccountBalanceErrorMessage: ''
    })
}

export const getAccountBalanceFailure = (state, action) => {
    return state.merge({
        getAccountBalanceError: true,
        getAccountBalanceErrorMessage: action.error
    })
}

/* ------------- Get Account Transactions ------------- */
export const getAccountTransactionsRequest = (state, action) => {
    return state.merge({
        getAccountTransactionsResponse: null,
        getAccountTransactionsError: false,
        getAccountTransactionsErrorMessage: '',
        fetchingApiButton: true
    })
}

export const getAccountTransactionsSuccess = (state, action) => {
    return state.merge({
        getAccountTransactionsResponse: action.data,
        getAccountTransactionsError: false,
        getAccountTransactionsErrorMessage: '',
        fetchingApiButton: false
    })
}

export const getAccountTransactionsFailure = (state, action) => {
    return state.merge({
        getAccountTransactionsError: true,
        getAccountTransactionsErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Get Accounts Dashboard ------------- */
export const getAccountsDashboardRequest = (state, action) => {
    return state.merge({
        getAccountsDashboardResponse: null,
        getAccountsDashboardError: false,
        getAccountsDashboardErrorMessage: '',
        fetchingApiButton: true
    })
}

export const getAccountsDashboardSuccess = (state, action) => {
    return state.merge({
        getAccountsDashboardResponse: action.data,
        getAccountsDashboardError: false,
        getAccountsDashboardErrorMessage: '',
        fetchingApiButton: false
    })
}

export const getAccountsDashboardFailure = (state, action) => {
    return state.merge({
        getAccountsDashboardError: true,
        getAccountsDashboardErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Get Accounts Dashboard ------------- */
export const getPaymentAllRequest = (state, action) => {
    return state.merge({
        getPaymentAllResponse: null,
        getPaymentAllError: false,
        getPaymentAllErrorMessage: '',
        fetchingApiButton: true
    })
}

export const getPaymentAllSuccess = (state, action) => {
    return state.merge({
        getPaymentAllResponse: action.data,
        getPaymentAllError: false,
        getPaymentAllErrorMessage: '',
        fetchingApiButton: false
    })
}

export const getPaymentAllFailure = (state, action) => {
    return state.merge({
        getPaymentAllError: true,
        getPaymentAllErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    // Connect URL
    [Types.CONNECT_URL_REQUEST]: connectUrlRequest,
    [Types.CONNECT_URL_SUCCESS]: connectUrlSuccess,
    [Types.CONNECT_URL_FAILURE]: connectUrlFailure,
    // Set Account
    [Types.SET_ACCOUNT_REQUEST]: setAccountRequest,
    [Types.SET_ACCOUNT_SUCCESS]: setAccountSuccess,
    [Types.SET_ACCOUNT_FAILURE]: setAccountFailure,
    // Get Accounts
    [Types.GET_ACCOUNTS_REQUEST]: getAccountsRequest,
    [Types.GET_ACCOUNTS_SUCCESS]: getAccountsSuccess,
    [Types.GET_ACCOUNTS_FAILURE]: getAccountsFailure,
    // Get Account Balance
    [Types.GET_ACCOUNT_BALANCE_REQUEST]: getAccountBalanceRequest,
    [Types.GET_ACCOUNT_BALANCE_SUCCESS]: getAccountBalanceSuccess,
    [Types.GET_ACCOUNT_BALANCE_FAILURE]: getAccountBalanceFailure,
    // Get Accounts Dashboard
    [Types.GET_ACCOUNTS_DASHBOARD_REQUEST]: getAccountsDashboardRequest,
    [Types.GET_ACCOUNTS_DASHBOARD_SUCCESS]: getAccountsDashboardSuccess,
    [Types.GET_ACCOUNTS_DASHBOARD_FAILURE]: getAccountsDashboardFailure,
    // Get Account Transactions
    [Types.GET_ACCOUNT_TRANSACTIONS_REQUEST]: getAccountTransactionsRequest,
    [Types.GET_ACCOUNT_TRANSACTIONS_SUCCESS]: getAccountTransactionsSuccess,
    [Types.GET_ACCOUNT_TRANSACTIONS_FAILURE]: getAccountTransactionsFailure,
    // Transfer Money
    [Types.TRANSFER_MONEY_REQUEST]: transferMoneyRequest,
    [Types.TRANSFER_MONEY_SUCCESS]: transferMoneySuccess,
    [Types.TRANSFER_MONEY_FAILURE]: transferMoneyFailure,
    // Get Payment List
    [Types.GET_PAYMENT_ALL_REQUEST]: getPaymentAllRequest,
    [Types.GET_PAYMENT_ALL_SUCCESS]: getPaymentAllSuccess,
    [Types.GET_PAYMENT_ALL_FAILURE]: getPaymentAllFailure
})