import React, { Component } from 'react'
import forward from '../../svg/forward.png'
import select_back_black from '../../svg/select_back_black.svg'
import profile_pic from '../../svg/profile_pic.svg'
import ParentDashboard from '../dashboard/parent-dashboard'
import { connect } from 'react-redux'
import ParentActions from '../../reducers/ParentRedux'

class ChildrenDashboardNav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            returnBack: false,
            childrenList: []
        }
    }

    onReturnBack = () => {
        this.setState({
            returnBack: true
        })
    }

    componentDidMount() {
        this.props.getParentDashboardRequest()
        this.setState({
            childrenList: this.props.parent?.getParentDashboardResponse?.data?.data?.childList
        })
    }

    render() {
        const { t } = this.props

        return (
            this.state.returnBack ? <ParentDashboard children={this.state.childrenList} t={t} /> :
                <div className="yg-row-cards">
                    <div className="button-back-div">
                        <button className="yg-select-back-cards" onClick={() => this.onReturnBack()}>
                            <img src={select_back_black} alt="Back" />
                        </button>
                    </div>
                    <div className="w-50">
                        <span className="yg-greeting-parent-name">{t('children')}</span>
                        <div className="pt-5">
                            {
                                this.state.childrenList?.map((i, k) => {
                                    return (
                                        <button key={k} className="card-button" onClick={this.onShowChildProfile}>
                                            <div className={i.color === "1" ? "yg-card-head yg-gradient-blue yg-modal-child-max mb-4" : "yg-card-head yg-gradient-orange yg-modal-child-max mb-4"}>
                                                <div className="yg-radio-form-sa">
                                                    <img src={i.picture === undefined ? profile_pic : i.picture} alt="Avatar" className="yg-card-avatar-max" />
                                                    <span className="yg-cc-selfcard-topname pl-4">{i.name}</span>
                                                </div>
                                                <div className="yg-balance-header">
                                                    <img src={forward} alt="Forward" className="expenses-svg" />
                                                </div>
                                            </div>
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getParentDashboardRequest: () => dispatch(ParentActions.getParentDashboardRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChildrenDashboardNav)