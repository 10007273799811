import { API } from 'aws-amplify'

const create = () => {

    // GET METHODS

    const getParentDashboard = (token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        }
        return API.get("YounGoMoneyAPI", "/parent/dashboard", requestOptions)
            .then(response => {                
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return error;
            })
    }

    const getChildList = (token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        }
        return API.get("YounGoMoneyAPI", "/parent/childList", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return error;
            })
    }

    const revokeMandate = (body, token) => {    
        console.log(body)
        const mandate_id = body["mandate_id"]      
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        }
        return API.get("YounGoMoneyAPI", "/bank/mandate/revoke/" + mandate_id, requestOptions)
            .then(response => {        
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return error.response.data;
            })
    }

    // POST METHODS

    const addChild = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/addchild", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return { 'error': true }
            })
    }

    // Allowance

    const setAllowance = (body, token) => {    
                    
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/setAllowance", requestOptions)
            .then(response => {        
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const setVrpAllowance = (body, token) => {    
                    
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/setVrpAllowance", requestOptions)
            .then(response => {        
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const setVrpProvider = (body, token) => {    
        console.log(body)      
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/setVrpProvider", requestOptions)
            .then(response => {        
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    
    // CHORES

    const editChore = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/editChores", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const addChores = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/addChores", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const deleteChore = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/deleteChore", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const approveChore = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/approveChore", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    // GOALS

    const addGoal = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/addGoal", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const editGoal = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/editGoal", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const deleteGoal = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/deleteGoal", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const generateTempPass = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/parent/child/temporaryPassword", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    // DELETE METHODS

    const deleteChild = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            //body: body,
            response: true
        }
        return API.del("YounGoMoneyAPI", "/parent/child/" + body.childId, requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    return {
        getParentDashboard,
        getChildList,
        addChild,
        setAllowance,
        addChores,
        editChore,
        deleteChore,
        approveChore,
        addGoal,
        editGoal,
        deleteGoal,
        deleteChild,
        generateTempPass,
        setVrpAllowance,
        setVrpProvider,
        revokeMandate
    }
}

export default {
    create
}