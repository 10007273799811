import React, { Component } from 'react'
import bank_parent from '../../svg/illustrations/bank_parent.svg'
import addchildboard from '../../svg/illustrations/addchildboard.svg'
import ChildrenCards from '../dashboard/children-cards'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ParentActions from '../../reducers/ParentRedux'
import BankActions from '../../reducers/BankRedux'

class ParentDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addChild: false,
            childrenList: [],
            bankInfo: {}
        }
    }

    componentDidMount() {
        this.props.getAccountBalanceRequest()
        this.props.getParentDashboardRequest()
        this.setState({
            childrenList: this.props.parent.getParentDashboardResponse?.data?.data?.childList,
            bankInfo: this.props.bank.getAccountBalanceResponse?.data?.data?.result,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.parent.fetchingApi && prevProps.parent.fetchingApi){
            // console.log("fetching test")
            this.setState({
                childrenList: this.props.parent.getParentDashboardResponse?.data?.data?.childList
            })
            // console.log(this.props.parent.getParentDashboardResponse?.data?.data?.childList)
        }
        if (this.state.childrenList !== this.props.parent.getParentDashboardResponse?.data?.data?.childList) {
            // console.log("test inside parent dashboard")
            this.setState({
                childrenList: this.props.parent.getParentDashboardResponse?.data?.data?.childList
            })
            // console.log(this.props.parent.getParentDashboardResponse?.data?.data?.childList)
        }
        if (prevProps.parent.addChildResponse?.data?.data?.temporaryPassword !== this.props.parent?.addChildResponse?.data?.data?.temporaryPassword) {
            this.props.getParentDashboardRequest()
            setTimeout(() => {
                this.setState({
                    childrenList: this.props.parent.getParentDashboardResponse?.data?.data?.childList
                })
            }, 500)
        }
    }

    waitChildsBank = () => {
        this.setState({
            addChild: true
        })
        this.props.openAddChildModal(true)
    }

    render() {
        const { t } = this.props
        const now = new Date()
        const hrs = now.getHours()
        const currentParent = this.props.user?.userData?.attributes

        return (
            <div className="yg-row-cards">
                {
                    !this.props.parent.fetchingApi &&
                    <>
                        {
                            this.state.childrenList?.length > 0 ?
                                <ChildrenCards childrenList={this.state.childrenList} t={t} bankInfo={this.state.bankInfo} /> :
                                <>
                                    {
                                        this.state.addChild ?
                                            <>
                                                <div className="yg-greeting-parent">
                                                    <div className="yg-greeting-parent-name">
                                                        {
                                                            hrs >= 6 && hrs < 12 ? <span>{t('greeting_morning')}</span> :
                                                                hrs >= 12 && hrs < 17 ? <span>{t('greeting_afternoon')}</span> :
                                                                    hrs >= 17 && hrs < 22 ? <span>{t('greeting_evening')}</span> :
                                                                        hrs >= 22 ? <span>{t('greeting_night')}</span> :
                                                                            <span>{t('greeting_welcome')}</span>
                                                        }
                                                        <br />{this.props.user.userData.attributes.name}!
                                                    </div>
                                                </div>
                                                <div className="yg-parentdb-nochild">
                                                    <img src={bank_parent} alt="Bank" className="yg-parentdb-nochildimg" />
                                                    <div className="form-group yg-form">
                                                        <span className="yg-codelogin-headline-main">
                                                            {t('final_step_to_unlock_all_features')}
                                                        </span>
                                                        <div className="yg-codelogin-form">
                                                            <label htmlFor="yg-password" className="yg-headline-label">
                                                                {t('to_activate_the_application_features')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            <>
                                                <div className="yg-greeting-parent">
                                                    <div className="yg-greeting-parent-name">
                                                        {
                                                            hrs >= 6 && hrs < 12 ? <span>{t('greeting_morning')}</span> :
                                                                hrs >= 12 && hrs < 17 ? <span>{t('greeting_afternoon')}</span> :
                                                                    hrs >= 17 && hrs < 22 ? <span>{t('greeting_evening')}</span> :
                                                                        hrs >= 22 ? <span>{t('greeting_night')}</span> :
                                                                            <span>{t('greeting_welcome')}</span>
                                                        }
                                                        <br />{currentParent?.name}!
                                                    </div>
                                                    <div className="yg-greeting-parent-balance">
                                                        <div>
                                                            <span className="yg-card-alwn-costs-q">£</span>
                                                            <span className="yg-greeting-parent-balance-number">{this.state.bankInfo?.current}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="yg-parentdb-nochild">
                                                    <img src={addchildboard} alt="No Child" className="yg-parentdb-nochildimg" />
                                                    <div className="form-group yg-form">
                                                        <span className="yg-codelogin-headline-main">{t("no_child_added")}</span>
                                                        <div className="yg-codelogin-form">
                                                            <label htmlFor="yg-password" className="yg-headline-label">{t("no_child_desc")}</label>
                                                        </div>
                                                        <div className="yg-btn-form">
                                                            <button
                                                                type="button"
                                                                className="btn  yg-btn yg-btn-black"
                                                                onClick={this.waitChildsBank}
                                                            >
                                                                {t('add_child')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getParentDashboardRequest: () => dispatch(ParentActions.getParentDashboardRequest()),
        getAccountBalanceRequest: () => dispatch(BankActions.getAccountBalanceRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        parent: state.parent,
        bank: state.bank,
        main: state.main
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ParentDashboard))