import React, { Component } from 'react'
import cancel from '../../../../svg/cancel.svg'
import chore_white from '../../../../svg/chore_white.svg'
import EditGoal from './edit-goal'
import AddGoal from './add-goal'
import GoalsSavings from './goals-savings'
import { connect } from 'react-redux'
import CommonActions from '../../../../reducers/CommonRedux'
import {
    AreaChart,
    GridlineSeries,
    Gridline,
    AreaSeries,
    PointSeries,
    LinearXAxis,
    LinearYAxis,
    LinearXAxisTickSeries,
    Line,
    Area,
    Gradient,
    GradientStop,
    LinearYAxisTickSeries
} from 'reaviz'

class GoalsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedGoal: null,
            addGoalModal: false,
            editGoalModal: false,
            savingAccountModal: false,
            changeNameValue: false,
            goalAdded: false,
            goals_info: {}
        }
    }

    componentDidMount() {
        let selectedChild = this.props.child !== undefined ? this.props.child : this.props.childrenList[0]        
        
        this.props.goalsListRequest({
            child_sub: selectedChild.sub
        })
        //setTimeout(() => {
        this.setState({
            goals_info: this.props.common?.goalsListResponse?.data
        })
        //}, 500)
    }

    componentDidUpdate(prevProps, prevState) {                
        if (prevProps.selectedOption !== this.props.selectedOption) {        
            let selectedChild = this.props.child !== undefined ? this.props.child :
                this.props.selectedOption === undefined ? this.props.childrenList[0] :
                    this.props.childrenList.find((e) => {
                        return e.name === this.props.selectedOption
                    })
            this.props.goalsListRequest({
                child_sub: selectedChild.sub
            })
            setTimeout(() => {
                this.setState({
                    goals_info: this.props.common?.goalsListResponse?.data
                })
            }, 500)
        }
    }

    onEditGoal = (selectedGoal) => {
        this.setState({
            editGoalModal: true,
            selectedGoal: selectedGoal
        })
        this.props.handleCheckEditGoalModal(true)
    }

    onAddGoal = () => {
        this.setState({
            addGoalModal: true
        })
        this.props.handleCheckAddGoalModal(true)
    }

    onShowSavingAccount = () => {
        this.setState({
            savingAccountModal: true
        })
    }

    render() {
        const { t } = this.props
        let selectedChild = this.props.child !== undefined ? this.props.child :
            this.props.selectedOption === undefined ? this.props.childrenList[0] :
                this.props.childrenList.find((e) => {
                    return e.name === this.props.selectedOption
                })

        const data_values = [
            {
                key: 0,
                data: 5
            },
            {
                key: 5,
                data: 10
            },
            {
                key: 10,
                data: 14
            },
            {
                key: 15,
                data: 5
            },
            {
                key: 20,
                data: 18
            }
        ]

        return (
            <>
                {
                    this.state.editGoalModal ?
                        // Edit Goal
                        <EditGoal
                            t={t}
                            selectedGoal={this.state.selectedGoal}
                            selectedChild={selectedChild}
                            handleCloseModal={this.props.handleCloseModal}
                        /> :
                        // Add Goal
                        this.state.addGoalModal ?
                            <AddGoal
                                t={t}
                                selectedChild={selectedChild}
                                handleCloseModal={this.props.handleCloseModal}
                            /> :
                            // Saving Account
                            this.state.savingAccountModal ?
                                <GoalsSavings
                                    t={t}
                                    selectedChild={selectedChild}
                                    handleCloseModal={this.props.handleCloseModal}
                                    childInfo={this.props.child}
                                /> :
                                // Goals Details Table
                                <div className="pt-2">
                                    <span className="yg-greeting-name">
                                        {selectedChild?.name + t('s_goals')}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={this.onAddGoal}
                                        className="btn yg-parentdb-btn-dark mt-3 yg-headline-label-light-xs-white">
                                        <img src={chore_white} alt="Chore" className="expenses-svg" />&nbsp;{t('add_goals')}
                                    </button>
                                    <button className="card-button-goals" onClick={this.onShowSavingAccount}>
                                        <div className={selectedChild.color === "1" ? "goals-chart mt-5" : "goals-chart-orange mt-5"}>
                                            <div className="goals-lowtitle-title">
                                                <span className="yg-headline-label-light-xs-black">{t('saving_account')}</span>
                                                <div className="yg-goals-right">
                                                    <span className="yg-headline-label-light-xxs-black-neutral">{t('balance')}</span>
                                                    <div>
                                                        <span className="yg-card-alwn-costs-q-xs">£</span>
                                                        <span className="yg-card-number-min">{this.state.goals_info?.data?.savingAccount?.data?.current}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '120px'
                                                }}
                                            >
                                                <AreaChart
                                                    gridlines={<GridlineSeries line={<Gridline direction="none" />} />}
                                                    data={data_values}
                                                    yAxis={
                                                        <LinearYAxis
                                                            axisLine={null}
                                                            tickSeries={
                                                                <LinearYAxisTickSeries
                                                                    line={null}
                                                                    label={null}
                                                                />
                                                            }
                                                        />
                                                    }
                                                    xAxis={
                                                        <LinearXAxis
                                                            axisLine={null}
                                                            tickSeries={
                                                                <LinearXAxisTickSeries
                                                                    line={null}
                                                                    label={null}
                                                                />
                                                            }
                                                        />
                                                    }
                                                    series={
                                                        <AreaSeries
                                                            colorScheme={selectedChild.color === "1" ? '#03A6F7' : '#ff5b02'}
                                                            symbols={<PointSeries show={true} />}
                                                            area={
                                                                <Area
                                                                    gradient={
                                                                        <Gradient
                                                                            stops={[
                                                                                <GradientStop offset="30%" stopOpacity={0} />,
                                                                                <GradientStop offset="95%" stopOpacity={1} />
                                                                            ]}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            line={<Line strokeWidth={1} />}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </button>
                                    <div className="goals-lowtitle">
                                        <span className={selectedChild.color === "1" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                            {t('reset_all_savings')}
                                        </span>
                                        <span className="pound-gray">{t('you_can_reset_the_savings')}</span>
                                    </div>
                                    <div className="yg-gradient untrg-savings">
                                        <span className="yg-headline-label-light-xs-black">{t('undistributed_savings')}</span>
                                        <div>
                                            <span className="pound-gray">£</span>
                                            <span className="yg-headline-label-dark">{this.state.goals_info?.data?.undistributedAmount}</span>
                                        </div>
                                    </div>
                                    {/*<div className="card-button-goals">
                                        <div className="card yg-selfcard-green">
                                            <div className="yg-selfcard-head">
                                                <span className="yg-headline-label-light-xs-black">test</span>
                                            </div>
                                            <div className="yg-cc-pb-selfcard">
                                                <div className="progress yg-pb-green-selfcard">
                                                    <div
                                                        className="progress-bar yg-pbb-green"
                                                        role="progressbar"
                                                        style={{ width: "50%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100" />
                                                </div>
                                                <div className="yg-selfcard-costs">
                                                    <span className="pound-gray">£</span>
                                                    <span className="yg-headline-label-dark">5/10</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                    {
                                        this.props.common?.goalsListResponse?.data?.data?.goalList?.map((i, k) => {
                                            return (
                                                <button key={k} className="card-button-goals" onClick={() => this.onEditGoal(i)}>
                                                    <div className={selectedChild.color === "1" ? "card yg-selfcard-blue" : "card yg-selfcard-orange"}>
                                                        <div className="yg-selfcard-head">
                                                            <span className="yg-headline-label-light-xs-black">{i.name}</span>
                                                        </div>
                                                        <div className="yg-cc-pb-selfcard">
                                                            <div className={selectedChild.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                                                <div
                                                                    className={selectedChild.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                                    role="progressbar"
                                                                    style={{ width: ((i.balance) / (i.target) * 100).toString() + "%" }}
                                                                    aria-valuenow="25"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100" />
                                                            </div>
                                                            <div className="yg-selfcard-costs">
                                                                <span className="pound-gray">£</span>
                                                                <span className="yg-headline-label-dark">{i.balance}/{i.target}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        goalsListRequest: (body) => dispatch(CommonActions.goalsListRequest(body)),
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent,
        common: state.common
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoalsDetails)