import React, { Component } from 'react'
import ConnectBank from '../components/forms/child/connect-bank'
import Toggler from '../components/helpers/toggler'
import ResetPassword from '../components/forms/common/reset-password'
import ForceResetPassword from '../components/forms/child/force-reset-password'
import logo_single_big from '../svg/logos/logo_single_big.svg'
import logo_single_writing from '../svg/logos/logo_single_writing.svg'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import UserActions, { clearRedux, setUserData } from '../reducers/UserRedux'
import VerificationActions from '../reducers/VerificationRedux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { withTranslation } from 'react-i18next'
import CheckPhone from '../components/forms/common/check-phone'
import CheckEmail from '../components/forms/common/check-email'
// import ConnectBankParent from '../components/forms/parent/connect-bank'
const eye = <FontAwesomeIcon icon={faEye} />
const closed_eye = <FontAwesomeIcon icon={faEyeSlash} />

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            childChecked: false,
            email: '',
            passwordShown: false,
            password: '',
            redirectToSignUp: false,
            childSigned: false,
            childSignedComplete: false,
            forgotPassword: false,
            redirectToDashboard: false,
            emailChild: '',
            passwordChild: '',
            switchValue: "Parent",
            loggedIn: false,
            forceChangePassword: false,
            currentUser: undefined,
            type: 1,
            signInSmsSent: false,
            emailVerifyCodeSent: false,
            phoneVerifyCodeSent: false,
            emailVerificationNeeded: false,
            phoneVerificationNeeded: false,
            connectBankNeeded: false,
            regularPhoneCheck: false,
            connectBankRender: false,
            user: null,
        }
    }

    componentDidUpdate(prevProps, prevState){

        if (this.state.user !== null && this.state.phoneVerifyCodeSent === false && this.state.emailVerifyCodeSent === false && this.state.signInSmsSent === false && this.state.connectBankRender === false && this.state.forceChangePassword === false){
            if (this.state.emailVerificationNeeded){
                console.log("Email verification is needed!")
                toast.error('Verify your email!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                this.props.getCodeRequest({
                    access_token: this.state.user.signInUserSession.accessToken.jwtToken,
                    type: "email"
                })
                this.setState({
                    emailVerifyCodeSent: true,
                    emailVerificationNeeded: false
                })
            }
            
            else if (this.state.phoneVerificationNeeded){
                console.log("Phone verification is needed!")
                toast.error('Verify your phone!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
                this.props.getCodeRequest({
                    access_token: this.state.user.signInUserSession.accessToken.jwtToken,
                    type: "phone_number"
                })
                this.setState({
                    phoneVerifyCodeSent: true,
                    phoneVerificationNeeded: false
                })
            }
            else if (this.state.regularPhoneCheck){
                console.log("Regular OTP check!")
                this.props.getCodeRequest({
                    access_token: this.state.user.signInUserSession.accessToken.jwtToken,
                    type: "phone_number"
                })                      
                this.setState({
                    signInSmsSent: true,
                    redirectToDashboard: true,
                    regularPhoneCheck: false
                })
            }
            else if (this.state.connectBankNeeded){
                console.log("Connect Bank Screen is Rendered!")
                this.setState({
                    connectBankRender:true,
                    connectBankNeeded: false
                })
            }
            else if (!this.state.connectBankNeeded && !this.state.emailVerificationNeeded && !this.state.regularPhoneCheck && !this.state.forceChangePassword && !this.state.forgotPassword){
                this.props.setSuccessfulSignin({"success":true})
                this.setState({
                    childSignedComplete: true,
                    redirectToDashboard: true
                })
            }
        }

    }

    // Password methods

    togglePasswordVisiblity = () => {
        if (this.state.passwordShown) {
            this.setState({
                passwordShown: false
            })
        } else if (!this.state.passwordShown) {
            this.setState({
                passwordShown: true
            })
        }
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value })
    }

    handlePasswordChangeChild = (event) => {
        this.setState({ passwordChild: event.target.value })
    }

    onForgotPassword = () => {
        this.setState({ forgotPassword: true })
    }

    // Email methods

    onEmailChange = (event) => {
        this.setState({ email: event.target.value })
    }

    onEmailChangeChild = (event) => {
        this.setState({ emailChild: event.target.value })
    }

    // Redirect methods

    handleRedirectToDashboard = (redirectToDashboard) => {
            this.setState({
                signInSmsSent: false,
                // redirectToDashboard: redirectToDashboard,
                emailVerifyCodeSent: false,
                phoneVerifyCodeSent: false,
            })
    }

    onNavigateToSignUp = () => {
        this.setState({ redirectToSignUp: true })
    }

    // Switch

    onSwitchChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.value === "Child") {
            this.setState({
                childChecked: true
            })
        } else {
            this.setState({
                childChecked: false
            })
        }
    }

    onSignParent = async () => {
        try {
            await Auth.signIn(this.state.email, this.state.password).then((user) => {
                console.log(user);
                
                this.props.setUserData(user)
                let flag = true;
                if (!user.attributes["email_verified"]) {
                    this.setState({
                        emailVerificationNeeded: true
                    })
                    flag = false;
                }
                if (!user.attributes["phone_number_verified"]) {
                    this.setState({
                        phoneVerificationNeeded: true
                    })           
                    flag = false;                             
                } 
                if (user.attributes["custom:type"] === "1" && user.attributes["phone_number_verified"] && user.attributes["email_verified"]) {                                            
                    this.setState({
                        regularPhoneCheck: true
                    })      
                    flag = false;                  
                } 
                if(flag){
                    toast.error('Please sign with parent credentials!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                }
                this.setState({user:user})
            })
        } catch (error) {
            toast.error('Incorrect username or password!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    onSignChild = async () => {
        try {
            const user = await Auth.signIn(this.state.emailChild, this.state.passwordChild)
            this.props.setUserData(user)
            if (this.props.user.userData.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.setState({
                    forceChangePassword: true,
                    currentUser: user,                    
                })
            } 
            if (user.attributes["custom:type"] === "2") {
                console.log(user)
                if (!user.attributes["email_verified"]) {
                    this.setState({
                        emailVerificationNeeded: true
                    })
                }
                if (!user.attributes["phone_number_verified"]) {
                    this.setState({
                        phoneVerificationNeeded: true
                    })                                 
                }
                if (user.attributes["custom:bankConnection"] !== "1"){
                    console.log("connect bank is needed chcek!!")
                    this.setState({
                        connectBankNeeded: true
                    })
                } 
                if (user.attributes["custom:type"] === "2" && user.attributes["phone_number_verified"] && user.attributes["email_verified"]) {                                            
                    this.setState({
                        regularPhoneCheck: true
                    })                     
                }
                this.setState({user:user})
            } 
            else {
                toast.error('Please sign with child credentials!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        } catch (error) {
            toast.error('Incorrect username or password!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    onSignChildProps = async (mail,password) => {
        try {
            const user = await Auth.signIn(mail, password)
            this.props.setUserData(user)
            this.setState({forceChangePassword: false})
            if (user.attributes["custom:type"] === "2") {
                console.log(user)
                if (!user.attributes["email_verified"]) {
                    this.setState({
                        emailVerificationNeeded: true
                    })
                }
                if (!user.attributes["phone_number_verified"]) {
                    this.setState({
                        phoneVerificationNeeded: true
                    })                                 
                }
                if (user.attributes["custom:bankConnection"] !== "1"){
                    console.log("connect bank is needed chcek!!")
                    this.setState({
                        connectBankNeeded: true
                    })
                } 
                if (user.attributes["custom:type"] === "2" && user.attributes["phone_number_verified"] && user.attributes["email_verified"]) {                                            
                    this.setState({
                        regularPhoneCheck: true
                    })                     
                }
                this.setState({user:user})
            } 
            else {
                toast.error('Please sign with child credentials!', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        } catch (error) {
            toast.error('Incorrect username or password!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.redirectToSignUp ?
                        <Redirect push to="/signup" /> :
                        <div className="yg-loginpage-row">
                            <div className="col yg-login-left">
                                <img src={logo_single_big} alt="Logo" className="logo-single-big" />
                                <img src={logo_single_writing} alt="Logo" />
                            </div>
                            <div className="col yg-signin-form">
                                {
                                    this.state.childSigned || this.state.forgotPassword ||
                                        this.state.forceChangePassword || this.state.emailVerifyCodeSent ||
                                        this.state.signInSmsSent || this.state.phoneVerifyCodeSent  ? null :
                                        <Toggler
                                            name="switchValue"
                                            checked={this.state.switchValue}
                                            labels={[
                                                "Parent",
                                                "Child"
                                            ]}
                                            handleChange={this.onSwitchChange}
                                        />
                                }
                                {
                                    this.state.signInSmsSent ?
                                        <CheckPhone
                                            t={this.props.t}
                                            username={this.state.email}
                                            password={this.state.password}
                                            redirectToDashboard={this.handleRedirectToDashboard}
                                        /> :
                                        this.state.emailVerifyCodeSent ?
                                            <CheckEmail
                                                type="sign-in-mail-verification"
                                                t={this.props.t}
                                                redirectToDashboard={this.handleRedirectToDashboard}
                                            /> :
                                            this.state.phoneVerifyCodeSent ?
                                                <CheckPhone
                                                    type="sign-in-phone-verification"
                                                    t={this.props.t}
                                                    redirectToDashboard={this.handleRedirectToDashboard}
                                                /> :
                                                // this.state.bankVerifySent ?
                                                //     <ConnectBankParent
                                                //         t={this.props.t}
                                                //     /> :
                                                    this.state.redirectToDashboard ?
                                                        <Redirect push to="/parent-dashboard" /> :
                                                        this.state.childChecked ?
                                                            (
                                                                this.state.connectBankRender ?
                                                                    <ConnectBank t={this.props.t} /> :
                                                                    this.state.childSignedComplete ?
                                                                        <Redirect push to="/child-dashboard" /> :
                                                                        this.state.forgotPassword ?
                                                                            <ResetPassword t={this.props.t} /> :
                                                                            this.state.forceChangePassword ?
                                                                                <ForceResetPassword t={this.props.t} userInfo={this.state.currentUser} toSign={this.onSignChildProps} /> :
                                                                                <div className="signin-form" style={{ width: "100%" }}>
                                                                                    <div className="create-account-form-header">
                                                                                        <span className="create-account-form-h1" style={{ 'paddingBottom': '1.8rem' }}>{this.props.t('sign_in')}</span>
                                                                                    </div>
                                                                                    <div className="form-group yg-form">
                                                                                        <div className="create-account-form-group">
                                                                                            <div className="yg-password-form-div">
                                                                                                <label htmlFor="yg-email" className="yg-headline-label">{this.props.t('email')}</label>
                                                                                                <div className="yg-password-form">
                                                                                                    <input
                                                                                                        placeholder={this.props.t('placeholder_email')}
                                                                                                        name="email"
                                                                                                        type="text"
                                                                                                        className="form-control yg-form-input"
                                                                                                        id="yg-email"
                                                                                                        value={this.state.emailChild}
                                                                                                        onChange={(e) => this.onEmailChangeChild(e)}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="yg-password-form-div">
                                                                                                <label htmlFor="yg-password" className="yg-headline-label">{this.props.t('password')}</label>
                                                                                                <div className="yg-password-form">
                                                                                                    <input
                                                                                                        placeholder={this.props.t('type_your_pass')}
                                                                                                        name="password"
                                                                                                        type={this.state.passwordShown ? "text" : "password"}
                                                                                                        className="form-control yg-form-input"
                                                                                                        id="yg-password"
                                                                                                        value={this.state.passwordChild}
                                                                                                        onChange={(e) => this.handlePasswordChangeChild(e)}
                                                                                                    />
                                                                                                    {
                                                                                                        this.state.passwordShown ?
                                                                                                            <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                                                                                            : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="yg-btn-form">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() => this.onSignChild()}
                                                                                                    className="btn  yg-btn yg-btn-black mb-3"
                                                                                                    disabled={this.state.emailChild.length === 0 || this.state.passwordChild.length === 0 ? true : false}
                                                                                                >
                                                                                                    {this.props.t('sign_in')}
                                                                                                    <ToastContainer
                                                                                                        position="bottom-left"
                                                                                                        className="toast-container-mobile"
                                                                                                        autoClose={1000}
                                                                                                        hideProgressBar
                                                                                                        newestOnTop={false}
                                                                                                        closeOnClick
                                                                                                        rtl={false}
                                                                                                        pauseOnFocusLoss
                                                                                                        draggable
                                                                                                        pauseOnHover
                                                                                                    />
                                                                                                </button>
                                                                                                <button className="yg-link-dark btn-as-link forgot_password-span" onClick={this.onForgotPassword}>
                                                                                                    {this.props.t('forgot_password')}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                            )
                                                            :
                                                            <>
                                                                {
                                                                    this.state.forgotPassword ?
                                                                        <ResetPassword t={this.props.t} /> :
                                                                        <div className="signin-form" style={{ width: "100%" }}>
                                                                            <div className="create-account-form-header">
                                                                                <span className="create-account-form-h1">{this.props.t('sign_in')}</span>
                                                                                <button className="yg-link btn-as-link" onClick={() => this.onNavigateToSignUp()}>{this.props.t('dont_have_an_account')}</button>
                                                                            </div>
                                                                            <div className="form-group yg-form">
                                                                                <div className="create-account-form-group">
                                                                                    <div className="yg-password-form-div">
                                                                                        <label htmlFor="yg-email" className="yg-headline-label">{this.props.t('email')}</label>
                                                                                        <div className="yg-password-form">
                                                                                            <input
                                                                                                placeholder={this.props.t('placeholder_email')}
                                                                                                name="email"
                                                                                                type="text"
                                                                                                className="form-control yg-form-input"
                                                                                                id="yg-email"
                                                                                                value={this.state.email}
                                                                                                onChange={(e) => this.onEmailChange(e)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="yg-password-form-div">
                                                                                        <label htmlFor="yg-password" className="yg-headline-label">{this.props.t('password')}</label>
                                                                                        <div className="yg-password-form">
                                                                                            <input
                                                                                                placeholder={this.props.t('type_your_pass')}
                                                                                                name="password"
                                                                                                type={this.state.passwordShown ? "text" : "password"}
                                                                                                className="form-control yg-form-input"
                                                                                                id="yg-password"
                                                                                                value={this.state.password}
                                                                                                onChange={(e) => this.handlePasswordChange(e)}
                                                                                            />
                                                                                            {
                                                                                                this.state.passwordShown ?
                                                                                                    <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                                                                                                    : <i onClick={this.togglePasswordVisiblity}>{closed_eye}</i>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="yg-btn-form">
                                                                                        {
                                                                                            !this.state.loggedIn ?
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn  yg-btn yg-btn-black mb-3"
                                                                                                    onClick={this.onSignParent}
                                                                                                    disabled={this.state.email.length === 0 || this.state.password.length === 0 ? true : false}
                                                                                                >
                                                                                                    {this.props.t('sign_in')}
                                                                                                    <ToastContainer
                                                                                                        position="bottom-left"
                                                                                                        className="toast-container-mobile"
                                                                                                        autoClose={1000}
                                                                                                        hideProgressBar
                                                                                                        newestOnTop={false}
                                                                                                        closeOnClick
                                                                                                        rtl={false}
                                                                                                        pauseOnFocusLoss
                                                                                                        draggable
                                                                                                        pauseOnHover
                                                                                                    />
                                                                                                </button> : null
                                                                                        }
                                                                                        <button className="yg-link-dark btn-as-link forgot_password-span" onClick={this.onForgotPassword}>
                                                                                            {this.props.t('forgot_password')}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </>
                                }
                            </div>
                        </div>
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (userData) => dispatch(UserActions.setUserData(userData)),
        clearRedux: () => dispatch(UserActions.clearRedux()),
        setSuccessfulSignin: (signData) => dispatch(UserActions.setSuccessfulSignin(signData)),
        getCodeRequest: (body) => dispatch(VerificationActions.getCodeRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignIn))