import React, { Component } from 'react'
import Countdown from 'react-countdown'
import email from '../../../svg/illustrations/email.svg'
import NewPassword from './new-password'
import { AutoTabProvider } from 'react-auto-tab'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'

const countRenderer = ({ minutes, seconds }) => {
    return (
        <span className="yg-card-number-small-time">{minutes}:{seconds}</span>
    )
}
const CountdownWrapper = () => <Countdown date={Date.now() + 60000} zeroPadTime={2} renderer={countRenderer} />
const MemoCountdown = React.memo(CountdownWrapper)

export default class CheckEmail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            code: '',
            code_1: '',
            code_2: '',
            code_3: '',
            code_4: '',
            code_5: '',
            code_6: '',
            enterNewPass: false
        }
    }

    updateCode1Value = (e) => {
        this.setState({
            code_1: e.target.value
        })
    }
    updateCode2Value = (e) => {
        this.setState({
            code_2: e.target.value
        })
    }
    updateCode3Value = (e) => {
        this.setState({
            code_3: e.target.value
        })
    }
    updateCode4Value = (e) => {
        this.setState({
            code_4: e.target.value
        })
    }
    updateCode5Value = (e) => {
        this.setState({
            code_5: e.target.value
        })
    }
    updateCode6Value = (e) => {
        this.setState({
            code_6: e.target.value
        })
    }
    onEnterNewPass = () => {
        let finalCode = this.state.code_1 + this.state.code_2 + this.state.code_3 + this.state.code_4 + this.state.code_5 + this.state.code_6
        this.setState({
            code: finalCode,
            enterNewPass: true
        })
    }
    onConfirmEmail = async () => {
        let finalCode = this.state.code_1 + this.state.code_2 + this.state.code_3 + this.state.code_4 + this.state.code_5 + this.state.code_6

        try {
            await this.setState({}, () => {
                Auth.verifyCurrentUserAttributeSubmit('email', finalCode).then(result => {
                    toast.success('Email successfully confirmed!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                    setTimeout(() => {
                        this.props.redirectToDashboard(true)
                    }, 1500)
                }).catch((error) => {
                    console.log('Error confirming email => ', error)
                })
            })
        } catch (error) {
            toast.error('Provided code is wrong!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    render() {
        const { t } = this.props
        return (
            <>
                {
                    this.state.enterNewPass ? <NewPassword t={t} getUser={this.props.getUser} code={this.state.code} /> :
                        <div className="code-form">
                            <img src={email} alt="Email" className="yg-parentdb-nochildimg" />
                            <div className="form-group yg-form">
                                <span className="yg-codelogin-headline-main">{t('check_your_email')}</span>
                                <div className="yg-codelogin-form">
                                    <label htmlFor="yg-password" className="yg-headline-label">{t('enter_six_digit_code')}</label>
                                    <AutoTabProvider className="yg-code-partitioned-div-cem">
                                        <input
                                            className={this.state.code_1.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                            name="code"
                                            type="text"
                                            maxLength={1}
                                            value={this.state.code_1}
                                            tabbable="true"
                                            onChange={this.updateCode1Value} />
                                        <input
                                            className={this.state.code_2.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                            name="code"
                                            type="text"
                                            maxLength={1}
                                            value={this.state.code_2}
                                            tabbable="true"
                                            onChange={this.updateCode2Value} />
                                        <input
                                            className={this.state.code_3.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                            name="code"
                                            type="text"
                                            maxLength={1}
                                            value={this.state.code_3}
                                            tabbable="true"
                                            onChange={this.updateCode3Value} />
                                        <input
                                            className={this.state.code_4.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                            name="code"
                                            type="text"
                                            maxLength={1}
                                            value={this.state.code_4}
                                            tabbable="true"
                                            onChange={this.updateCode4Value} />
                                        <input
                                            className={this.state.code_5.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                            name="code"
                                            type="text"
                                            maxLength={1}
                                            value={this.state.code_5}
                                            tabbable="true"
                                            onChange={this.updateCode5Value} />
                                        <input
                                            className={this.state.code_6.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                            name="code"
                                            type="text"
                                            maxLength={1}
                                            value={this.state.code_6}
                                            tabbable="true"
                                            onChange={this.updateCode6Value} />
                                    </AutoTabProvider>
                                    <MemoCountdown />
                                </div>
                                <div className="yg-btn-form">
                                    <button
                                        type="button"
                                        onClick={this.props.type === "sign-in-mail-verification" ? (() => this.onConfirmEmail()) : (() => this.onEnterNewPass())}
                                        className="btn  yg-btn yg-btn-black"
                                    >
                                        {t('send')}
                                    </button>
                                </div>
                                <div className="yg-notreceivedform">
                                    <span>{t('didnt_receive_the_code')}</span>
                                    <a href="/#" className="yg-codeform-link">{t('receive')}</a>
                                </div>
                            </div>
                            <ToastContainer
                                position="bottom-left"
                                className="toast-container-mobile"
                                autoClose={1000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </div>
                }
            </>
        )
    }
}