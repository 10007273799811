import React, { Component } from 'react'
import start from '../../../svg/illustrations/start.svg'
import select_back from '../../../svg/select_back.svg'
import ConnectBank from './connect-bank'
import { Redirect } from 'react-router'

export default class LetStart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            returnBack: false,
            connectToBank: false,
            childChecked: false
        }
    }

    onReturnBack = () => {
        this.setState({
            returnBack: true
        })
    }

    onLetsStartChild = () => {
        this.setState({
            childChecked: true
        })
    }

    render() {
        const { t } = this.props;

        return (
            <>
                {
                    this.state.childChecked ? <Redirect push to="/child-dashboard" /> :
                        this.state.returnBack ? <ConnectBank t={t} /> :
                            <>
                                <button className="yg-select-back-lp" onClick={() => this.onReturnBack()}>
                                    <img src={select_back} alt="Back" />
                                </button>
                                <div className="connect-bank-from-child">
                                    <img src={start} alt="Let's Start" />
                                    <div className="form-group yg-form">
                                        <span className="yg-codelogin-headline-main">Lorem ipsum dolor sit amet, consetet</span>
                                        <div className="yg-codelogin-form">
                                            <label htmlFor="yg-password" className="yg-headline-label">
                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                            </label>
                                        </div>
                                        <div className="yg-btn-form">
                                            <button
                                                type="button"
                                                onClick={this.onLetsStartChild}
                                                className="btn  yg-btn yg-btn-black"
                                            >
                                                {t('lets_start')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                }
            </>
        );
    }
}