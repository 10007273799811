import { API } from 'aws-amplify';

const create = () => {

    // CHORES
    const choresList = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("YounGoMoneyAPI", "/common/choresList", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    // GOALS
    const goalsList = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("YounGoMoneyAPI", "/common/goalsList", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    // DELETE OWN PROFILE
    const deleteOwnProfile = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("YounGoMoneyAPI", "/account/delete", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    // GOAL DISTRIBUTE
    const goalDistribute = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("YounGoMoneyAPI", "/common/goal/distribute", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return error;
            });
    }

    return {
        choresList,
        goalsList,
        deleteOwnProfile,
        goalDistribute
    }
}

export default {
    create
}