import React, { Component } from 'react'
import { connect } from 'react-redux'
import ParentActions from '../../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'

class EditChore extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            amount: "5",
            period: "onetime",
            otherValue: "",
            choreEdited: false,
            onSaveButtonClicked: false
        }
    }

    componentDidMount() {
        this.setState({
            name: this.props.selectedChore.name
        })
    }

    componentDidUpdate() {        
        if (this.props.parent?.editChoreResponse?.data?.messages?.message === "Chores edit" && this.state.choreEdited) {
            toast.success('Chore successfully edited!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                choreEdited: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        } else if (this.props.parent?.deleteChoreResponse?.data?.messages?.message === "Chore Deleted" && this.state.choreDeleted) {
            toast.success('Chore successfully deleted!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                choreDeleted: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        }
    }

    onEditChore = () => {
        this.props.editChoreRequest({
            id: this.props.selectedChore.id, // Chore ID
            userId: this.props.selectedChild.sub,
            name: this.state.name,
            period: this.state.period === "onetime" ? 1 : this.state.period === "daily" ? 2 : 3,
            amount: this.state.amount === "other" ? this.state.otherValue : this.state.amount
        })
        this.setState({
            choreEdited: true,
            onSaveButtonClicked: true
        })
    }

    onDeleteChore = () => {
        this.props.deleteChoreRequest({
            id: this.props.selectedChore.id, // Chore ID
            userId: this.props.selectedChild.sub
        })
        this.setState({
            choreDeleted: true,
            onSaveButtonClicked: true
        })
    }

    onNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onPeriodChange = (e) => {
        this.setState({
            period: e.target.id
        })
    }

    onOtherChange = (e) => {
        this.setState({
            otherValue: e.target.value
        })
    }

    render() {
        const { t } = this.props
        let child = this.props.selectedChild

        return (
            <div>
                <span className="yg-greeting-name">
                    {t('edit_chore')}
                </span>
                <div className="yg-password-form-div mt-5">
                    <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('chore_name')}</label>
                    <div className="yg-radio-form-sa pt-2">
                        <div className={child.color === "1" ? "yg-gradient-blue w-100" : "yg-gradient-orange w-100"}>
                            <input
                                type="text"
                                className={child.color === "1" ? "rb-input-text-blue" : "rb-input-text-orange"}
                                value={this.state.name}
                                onChange={this.onNameChange} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="yg-password-form-div">
                        <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('period')}</label>
                        <div className="yg-radio-form-sa pt-2">
                            <div className={child.color === "1" ? "yg-gradient-blue w-100" : "yg-gradient-orange w-100"}>
                                <input type="radio" id="onetime" name="period" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} onChange={this.onPeriodChange} checked={this.state.period === 'onetime'} />
                                <label htmlFor="onetime" className="rb-label w-33" >{t('one_time')}</label>
                                <input type="radio" id="daily" name="period" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} onChange={this.onPeriodChange} checked={this.state.period === 'daily'} />
                                <label htmlFor="daily" className="rb-label w-33" >{t('daily')}</label>
                                <input type="radio" id="weekly" name="period" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} onChange={this.onPeriodChange} checked={this.state.period === 'weekly'} />
                                <label htmlFor="weekly" className="rb-label w-33" >{t('weekly')}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="yg-password-form-div">
                    <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('amount')}</label>
                    <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                        <input type="radio" id="5" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "5"} onChange={this.onAmountChange} />
                        <label htmlFor="5" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>5</label>
                        <input type="radio" id="10" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "10"} onChange={this.onAmountChange} />
                        <label htmlFor="10" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>10</label>
                        <input type="radio" id="15" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "15"} onChange={this.onAmountChange} />
                        <label htmlFor="15" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>15</label>
                        <input type="radio" id="other" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                        <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                    </div>
                </div>
                {
                    this.state.amount === "other" ?
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                            <div className="yg-radio-form-sa pt-2">
                                <div className="yg-input-other w-100">
                                    <span className="yg-card-number-small-time">£</span>
                                    <input
                                        type="text"
                                        value={this.state.otherValue.replace(/[^0-9]/, '')}
                                        className={child.color === "1" ? "rb-input-text-blue p-10-5" : "rb-input-text-orange p-10-5"}
                                        onChange={this.onOtherChange} />
                                </div>
                            </div>
                        </div> : null
                }
                <div className="yg-btn-form" style={{ display: 'flex' }}>
                    <button
                        type="button"
                        className="btn  yg-btn yg-btn-black"
                        onClick={this.onEditChore}
                        disabled={(this.state.name.length === 0 || (this.state.amount === "other" ? this.state.otherValue <= 0 : this.state.amount.length === 0)) ? true : false}
                    >
                        {t('save')}
                    </button>
                    <button
                        type="button"
                        className="btn yg-btn yg-btn-gray"
                        onClick={() => this.props.handleCloseModal(true)}
                    >
                        {t('cancel')}
                    </button>
                </div>
                <div className="yg-btn-form-delete">
                    <button
                        type="button"
                        className="btn yg-btn-red"
                        onClick={this.onDeleteChore}
                    >
                        {t('delete')}
                    </button>
                </div>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editChoreRequest: (body) => dispatch(ParentActions.editChoreRequest(body)),
        deleteChoreRequest: (body) => dispatch(ParentActions.deleteChoreRequest(body)),
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditChore)