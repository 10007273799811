import { call, put } from 'redux-saga/effects'
import ParentTypes from '../reducers/ParentRedux'
import MainTypes from '../reducers/MainRedux'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

const getToken = async () => {
    const session = await Auth.currentSession()
    // return session.getIdToken().getJwtToken()
    return session.getIdToken().getJwtToken()
}

export function* addChild(api, action) {
    try {
        yield put(MainTypes.setLoader(true))
        let token = yield call(getToken)
        const response = yield call(api.addChild, action.body, token);
        yield put(MainTypes.setLoader(false))
        if (response.hasOwnProperty('error')) {
            toast.error('Check all the fields!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            yield put(ParentTypes.addChildFailure(response))
        } else {
            yield put(ParentTypes.addChildSuccess(response))
        }
    } catch (error) {
        yield put(MainTypes.setLoader(false))
        yield put(ParentTypes.addChildFailure(error))
    }
}

// Allowance

export function* setAllowance(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.setAllowance, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.setAllowanceFailure(response))
        } else {
            yield put(ParentTypes.setAllowanceSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.setAllowanceFailure(error))
    }
}

export function* setVrpAllowance(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.setVrpAllowance, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.setVrpAllowanceFailure(response))
        } else {
            yield put(ParentTypes.setVrpAllowanceSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.setVrpAllowanceFailure(error))
    }
}

export function* setVrpProvider(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.setVrpProvider, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.setVrpProviderFailure(response))
        } else {
            yield put(ParentTypes.setVrpProviderSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.setVrpProviderFailure(error))
    }
}

export function* revokeMandate(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.revokeMandate, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.revokeMandateFailure(response))
        } else {
            yield put(ParentTypes.revokeMandateSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.revokeMandateFailure(error))
    }
}

export function* getParentDashboard(api) {
    try {
        yield put(MainTypes.setLoader(true))
        let token = yield call(getToken)
        const response = yield call(api.getParentDashboard, token);
        yield put(MainTypes.setLoader(false))
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.getParentDashboardFailure(response))
        } else {
            yield put(ParentTypes.getParentDashboardSuccess(response))
        }
    } catch (error) {
        yield put(MainTypes.setLoader(false))
        yield put(ParentTypes.getParentDashboardFailure(error))
    }
}

export function* getChildList(api) {
    try {
        yield put(MainTypes.setLoader(true))
        let token = yield call(getToken)
        const response = yield call(api.getChildList, token);
        yield put(MainTypes.setLoader(false))
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.getChildListFailure(response))
        } else {
            yield put(ParentTypes.getChildListSuccess(response))
        }
    } catch (error) {
        yield put(MainTypes.setLoader(false))
        yield put(ParentTypes.getChildListFailure(error))
    }
}

// CHORES

export function* addChores(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.addChores, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.addChoresFailure(response))
        } else {
            yield put(ParentTypes.addChoresSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.addChoresFailure(error))
    }
}

export function* editChore(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.editChore, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.editChoreFailure(response))
        } else {
            yield put(ParentTypes.editChoreSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.editChoreFailure(error))
    }
}

export function* deleteChore(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.deleteChore, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.deleteChoreFailure(response))
        } else {
            yield put(ParentTypes.deleteChoreSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.editChoreFailure(error))
    }
}

export function* approveChore(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.approveChore, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.approveChoreFailure(response))
        } else {
            yield put(ParentTypes.approveChoreSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.approveChoreFailure(error))
    }
}

// GOALS

export function* addGoal(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.addGoal, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.addGoalFailure(response))
        } else {
            yield put(ParentTypes.addGoalSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.addGoalFailure(error))
    }
}

export function* editGoal(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.editGoal, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.editGoalFailure(response))
        } else {
            yield put(ParentTypes.editGoalSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.editGoalFailure(error))
    }
}

export function* deleteGoal(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.deleteGoal, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.deleteGoalFailure(response))
        } else {
            yield put(ParentTypes.deleteGoalSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.deleteGoalFailure(error))
    }
}

export function* deleteChild(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.deleteChild, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.deleteChildFailure(response))
        } else {
            yield put(ParentTypes.deleteChildSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.deleteChildFailure(error))
    }
}

export function* generateTempPass(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.generateTempPass, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(ParentTypes.generateTempPassFailure(response))
        } else {
            yield put(ParentTypes.generateTempPassSuccess(response))
        }
    } catch (error) {
        yield put(ParentTypes.generateTempPassFailure(error))
    }
}