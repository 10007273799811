import PropTypes from "prop-types"
import React from "react"
import BankActions from '../../reducers/BankRedux'
import { connect } from 'react-redux'
import logo_single_big from '../../svg/logos/logo_single_big.svg'

let browser = window
let popup = null
let timer = null

function watcher() {
    if (popup === null) {
        clearInterval(timer)
        timer = null
    } else if (popup !== null && !popup.closed) {
        popup.focus()
    } else if (popup !== null && popup.closed) {
        clearInterval(timer)
        browser.focus()
        browser.onClose("child was closed")
        timer = null
        popup = null
    }
}

class BankConnectWindow extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            url: "",
            openBankWindow: false,
            fetching: false,
        }

        this.onClickHandler = this.onClickHandler.bind(this)

        browser = window.self
        browser.onSuccess = (res) => {
            // console.log("onSuccess:",res)
            console.log(props);
            props.bridge(null, res)
        }

        browser.onError = (error) => {
            // console.log("error: ",error)
            props.bridge(error)
        }

        browser.onOpen = (message) => {
            // console.log("onOpen:",message)
            props.bridge(null, message)
        }

        browser.onClose = (message) => {
            // props.bridge(null, message);
        }
    }

    componentDidMount(){

    }

    componentDidUpdate(prevProps, prevState){
        if (this.props.bank?.connectUrlResponse?.data?.data?.sandbox_connect_bank_url !== undefined && this.state.openBankWindow && prevProps.bank?.fetchingApiButton){            
            this.setState({
                url: this.props.bank?.connectUrlResponse?.data?.data?.sandbox_connect_bank_url,
                openBankWindow: false,
                fetching: false,
            })                            
        }
        if (prevState.url === "" && this.state.url !== ""){
            if (this.props.bank?.connectUrlResponse?.data?.data?.sandbox_connect_bank_url !== this.state.url){
                this.setState({
                    url: this.props.bank?.connectUrlResponse?.data?.data?.sandbox_connect_bank_url,
                    fetching: false,
                })  
            }
            else{
                if (popup && !popup.closed) {
                    popup.focus();
                    return;
                }    
                popup = browser.open(this.state.url, this.props.name, this.props.opts);
        
                if (timer === null) {
                    timer = setInterval(watcher, 2000);
                }
            }            
        }  
    }

    onClickHandler(evt) {
        console.log("clicked")
        if (!this.state.openBankWindow && this.state.url === "")
        this.props.connectUrlRequest()
        this.setState({
            openBankWindow: true,
            fetching: true,
        })
        // const { url, name, opts } = this.props;
        // if (popup && !popup.closed) {
        //     popup.focus();
        //     return;
        // }

        // popup = browser.open(url, name, opts);

        // if (timer === null) {
        //     timer = setInterval(watcher, 2000);
        // }

        // return;
    }

    render() {
        const { children } = this.props;
        return (
            
            <button 
                type="button"
                onClick={this.onClickHandler}
                className="btn yg-btn-thin yg-btn-black mr-5"
                disabled={this.state.fetching}
            >
                 {this.state.fetching ? <div className="card yg-selfcard-green"><img id="animlogo-signup" src={logo_single_big} alt="Logo" /></div>: children}
                
            </button>
        );
    }
}

BankConnectWindow.propTypes = {
    url: PropTypes.string.isRequired,
    bridge: PropTypes.func.isRequired,
    name: PropTypes.string,
    opts: PropTypes.string,
    disabled: PropTypes.bool
}

BankConnectWindow.defaultProps = {
    name: "Teengle Connect Bank",
    opts: `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${600}, height=${600}`
}

const mapDispatchToProps = (dispatch) => {
    return {
        connectUrlRequest: () => dispatch(BankActions.connectUrlRequest()),
    }
}
const mapStateToProps = (state) => {
    return {
        bank: state.bank,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BankConnectWindow)