import { call, put } from 'redux-saga/effects';
import VerificationTypes from '../reducers/VerificationRedux';
import { Auth } from 'aws-amplify';

const getToken = async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
};

export function* getCode(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.getCode, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(VerificationTypes.getCodeFailure(response))
        } else {
            yield put(VerificationTypes.getCodeSuccess(response))
        }
    } catch (error) {
        yield put(VerificationTypes.getCodeFailure(error))
    }
}