import React from 'react'
import CheckEmail from '../components/forms/parent/check-email'
import CheckPhone from '../components/forms/parent/check-phone'
import ConnectBank from '../components/forms/parent/connect-bank'
import AddChildBefore from '../components/forms/parent/add-child-before'
import SetAllowanceBefore from '../components/forms/parent/set-allowance-before'
import LetsStart from '../components/forms/parent/lets-start'
import CreateAccount from '../components/forms/parent/create-account'
import logo_single_big from '../svg/logos/logo_single_big.svg'
import logo_single_writing from '../svg/logos/logo_single_writing.svg'
import { useTranslation } from "react-i18next"
import 'react-step-progress/dist/index.css'
import { Steps } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
const left = <FontAwesomeIcon icon={faChevronLeft} />

function SignUp() {
  const { t } = useTranslation()

  const [showNextStep, setShowNextStep] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [childData, setChildData] = useState({})
  const [showFifthStep, setShowFifthStep] = useState(false)
  const [showFourthStep, setShowFourthStep] = useState(false)

  var [step, setStep] = React.useState(0)
  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 5 ? 5 : nextStep)
  }
  if (showNextStep) {
    onChange(step + 1)
    setShowNextStep(false)
  }
  if (showFourthStep) {
    onChange(step - 2)
    setShowFourthStep(false)
  }
  if (showFifthStep) {
    onChange(step + 1)
    setShowFifthStep(false)
  }

  const onNext = () => onChange(step + 1)
  const onPrevious = () => onChange(step - 1)

  return (
    <div className="yg-loginpage-row">
      <div className="col yg-login-left">
        <img src={logo_single_big} alt="Logo" className="logo-single-big" />
        <img src={logo_single_writing} alt="Logo" />
      </div>
      <div className="col yg-login-form">
        <Steps current={step}>
          <Steps.Item title="Personal Info" />
          <Steps.Item title="Check Your Phone" />
          <Steps.Item title="Check Your Email" />
          {/* <Steps.Item title="Connect To Bank" /> */}
          <Steps.Item title="Add Child" />
          <Steps.Item title="Let's Start" />
        </Steps>
        <div className="steps-line"></div>
        <div>
          {
            step === 0 ? <CreateAccount t={t} handleGoToNextStep={setShowNextStep} handleSendUsername={setUsername} handleSendPassword={setPassword} /> :
              step === 1 ? <CheckPhone t={t} handleGoToNextStep={setShowNextStep} username={username} password={password} /> :
                step === 2 ? <CheckEmail t={t} handleGoToNextStep={setShowNextStep} username={username} /> :
                  // step === 3 ? <ConnectBank t={t} handleGoToNextStep={setShowNextStep} /> :
                    step === 3 ? <AddChildBefore t={t} handleGoToNextStep={setShowNextStep} goToLetsStartStep={setShowFifthStep} handleSendData={setChildData} /> :
                      step === 4 ? <LetsStart t={t} goToAddOtherChildStep={setShowFifthStep} childData={childData} /> :
                        <CreateAccount t={t} />
          }
          {
            step === 0 ? null :
              <button onClick={onPrevious} disabled={step === 0} className="back-button-signup">
                <i>{left}</i>
              </button>
          }
          {/*<button onClick={onNext}>
            Next
        </button>*/}
        </div>
      </div>
    </div>
  )
}

export default SignUp