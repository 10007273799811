import { API } from 'aws-amplify'

const create = () => {

    // GET METHODS

    const getNotifications = (body, token) => {
        
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.get("YounGoMoneyAPI", "/notification/all", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error)
                return { 'error': true }
            })
    }

    const getPermissions = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.get("YounGoMoneyAPI", "/notification/permissions", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error)
                return { 'error': true }
            })
    }

    const changePermissions = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/notification/permissions", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error)
                return { 'error': true }
            })
    }

    return {
        getNotifications,
        getPermissions,
        changePermissions
    }
}

export default {
    create
}