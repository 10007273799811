import React from 'react'
import ParentDashboard from '../components/dashboard/parent-dashboard'
import ParentDashboardNav from '../components/dashboard/parent-dashboard-nav'
import ChildrenDashboardNav from '../components/dashboard/children-dashboard-nav'
import ChildDashboard from '../components/dashboard/child-dashboard'
import Navmenu from '../components/helpers/nav-menu'
import "../styles/style.scss"
import { useState, useRef, useEffect } from 'react'
import notification from '../svg/notification_black.svg'
import logo_mobile from '../svg/logos/logo_mobile.svg'
import transfer_mobile from '../svg/transfer_mobile.svg'
import request_mobile from '../svg/request_mobile.svg'
import menu from '../svg/menu.svg'
import { useTranslation } from "react-i18next"
import TransferChild from '../components/modals/parent/transfer-child'
import RequestMoney from '../components/modals/child/request-money'
import { Auth } from 'aws-amplify'
import UserActions from '../reducers/UserRedux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

function Dashboard(props) {
    let role = window.location.pathname.includes("child") ? "child" : "parent"

    const { t } = useTranslation()

    // Show menu on click
    const [menue, setMenu] = useState(false)
    const showMenu = () => {
        setMenu(true)
    }

    // Show transfer modal on click
    const [transferModal, setTransferModal] = useState(false)
    const showTransferModal = () => {
        setTransferModal(true)
    }
    const handleCloseModal = () => {
        setTransferModal(false)
        setRequestModal(false)
    }

    // Show request modal on click
    const [requestModal, setRequestModal] = useState(false)
    const showRequestModal = () => {
        setRequestModal(true)
    }

    // Change nav menu size on mobile
    const [isMobile, setMobile] = useState(false)
    window.addEventListener('resize', () => {
        setMobile(window.innerWidth < 769)
    }, false)
    useEffect(() => {
        window.innerWidth < 769 ? setMobile(true) : setMobile(false)
    }, [])

    const classNameMobile = isMobile ? (menue ? 'col-10 yg-dashboard-left' : 'col-10 yg-dashboard-left-none') : 'col-2 yg-dashboard-left'

    const [showChildProfile, setShowChildProfile] = useState(false)
    const [showParentProfile, setShowParentProfile] = useState(false)
    const [userSignedIn, setUserSignedIn] = useState(false)
    const [openAddChildModal, setOpenAddChildModal] = useState(false)

    // Close menu on click outsive div
    const wrapperRef = useRef(null)
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [wrapperRef])

    useEffect(() => {
        const ionViewCanEnter = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser()
                if (props.user?.successfulSignin?.success === true){    
                    if (user.attributes["custom:type"] === "1" && window.location.href.includes("child-dashboard")) {
                        window.location.href = '/parent-dashboard'
                    } else if (user.attributes["custom:type"] === "2" && window.location.href.includes("parent-dashboard")) {
                        window.location.href = '/child-dashboard'
                    } else {
                        props.setUserData(user)
                        console.log('User signed in!')
                        setUserSignedIn(true)
                    }
                }
                else{
                    console.log('User is not signed in correctly!')
                    setUserSignedIn(false)
                    window.location.href = '/signin'    
                }
            } catch (error) {
                console.log('User is not signed in!', error)
                setUserSignedIn(false)
                window.location.href = '/signin'
            }
        }
        console.log(props)
        ionViewCanEnter()
    }, [])

    return userSignedIn && (
        <div className="yg-login-row">
            <div className="yg-topnav-mobile">
                <div>
                    <button onClick={() => showMenu()} className="yg-topnav-mm-btn" >
                        <img src={menu} alt="Menu" className="yg-topnav-mobile-menu" />
                    </button>
                    <img src={notification} alt="Notification" />
                </div>
                <div className="yg-topnav-mb-logo"><img src={logo_mobile} alt="Logo" /></div>
                {
                    role === "parent" ?
                        <div className="yg-topnav-mb-send">
                            <button onClick={() => showTransferModal()} className="yg-topnav-mm-btn" >
                                <img src={transfer_mobile} alt="Transfer" />
                            </button>
                        </div> :
                        <div className="yg-topnav-mb-send">
                            <button onClick={() => showRequestModal()} className="yg-topnav-mm-btn" >
                                <img src={request_mobile} alt="Request" />
                            </button>
                        </div>
                }
            </div>
            {
                transferModal ?
                    <TransferChild
                        t={t}
                        handleCloseModal={handleCloseModal}
                        transferToChildModal={transferModal}
                    /> : null
            }
            {
                requestModal ?
                    <RequestMoney
                        t={t}
                        handleCloseModal={handleCloseModal}
                        requestMoneyModal={requestModal}
                    /> : null
            }
            <div className={classNameMobile} ref={wrapperRef}>
                <Navmenu
                    role={role}
                    t={t}
                    handleShowChildProfile={setShowChildProfile}
                    handleShowParentProfile={setShowParentProfile}
                    openAddChildModal={openAddChildModal}
                />
            </div>
            <div className="col-10 yg-dashboard-form">
                {
                    role === "parent" ?
                        <>
                            {
                                showChildProfile ? <ChildrenDashboardNav t={t} /> :
                                    showParentProfile ? <ParentDashboardNav t={t} /> :
                                        <ParentDashboard t={t} openAddChildModal={setOpenAddChildModal} />
                            }
                        </>
                        :
                        role === "child" ? <ChildDashboard t={t} /> :
                            null
                }
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (userData) => dispatch(UserActions.setUserData(userData)),
        
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard))