import React from "react";

export class BankChildElement extends React.Component {

    componentDidMount() {
        if (!window.opener) {
            window.close();
        }
    }

    /**
     * Changes the value of the input
     *
     * @param {import("react").ChangeEvent<HTMLInputElement>} evt
     * @returns {void}
     */


    render() {
        return (
            <div>
            </div>
        )
    }
}
