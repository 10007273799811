import React, { useEffect, useState } from "react"
import { withRouter, useLocation } from 'react-router-dom'

function GoalPaymentSuccess({ history }) {
    let location = useLocation()
    const [code, setCode] = useState({})
    
    useEffect(() => {        
        const code = location.pathname.includes("transfer-redirect-page")                
        setCode(code)
    }, [location])

    const close = () => {
        window.opener.onSuccess(code)
        window.close()
    }

    return (
        <div>
            <h1>Goal Payment Success</h1>
            <button onClick={close}>Go Home</button>
        </div>
    )
}

export default withRouter(GoalPaymentSuccess)