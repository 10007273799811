import { call, put } from 'redux-saga/effects';
import CommonTypes from '../reducers/CommonRedux';
import { Auth } from 'aws-amplify';

const getToken = async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
};

// CHORES

export function* choresList(api, action) {
    try {
        // console.log("api call triggered")
        let token = yield call(getToken)
        const response = yield call(api.choresList, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(CommonTypes.choresListFailure(response))
        } else {
            yield put(CommonTypes.choresListSuccess(response))
        }
        // console.log(response)
    } catch (error) {
        yield put(CommonTypes.choresListFailure(error))
    }
}

// GOALS

export function* goalsList(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.goalsList, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(CommonTypes.goalsListFailure(response))
        } else {
            yield put(CommonTypes.goalsListSuccess(response))
        }
    } catch (error) {
        yield put(CommonTypes.goalsListFailure(error))
    }
}

// DELETE OWN PROFILE

export function* deleteOwnProfile(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.deleteOwnProfile, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(CommonTypes.deleteOwnProfileFailure(response))
        } else {
            yield put(CommonTypes.deleteOwnProfileSuccess(response))
        }
    } catch (error) {
        yield put(CommonTypes.deleteOwnProfileFailure(error))
    }
}

// GOAL DISTRIBUTE

export function* goalDistribute(api, action) {
    try {
        let token = yield call(getToken)
        const response = yield call(api.goalDistribute, action.body, token);
        if (response.hasOwnProperty('error')) {
            yield put(CommonTypes.goalDistributeFailure(response))
        } else {
            yield put(CommonTypes.goalDistributeSuccess(response))
        }
    } catch (error) {
        yield put(CommonTypes.goalDistributeFailure(error))
    }
}
