import React, { Component } from 'react'
import bank_child from '../../../svg/illustrations/bank_child.svg'
import select_back from '../../../svg/select_back.svg'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BankActions from '../../../reducers/BankRedux'
import { BankWindow } from '../../../components/helpers/bank-window'
import BankAccounts from '../common/bank-accounts'
import { Hub } from 'aws-amplify'
import { Redirect } from 'react-router'

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in')
            break
        case 'signUp':
            console.log('user signed up')
            break
        case 'signOut':
            console.log('user signed out')
            break
        case 'signIn_failure':
            console.log('user sign in failed')
            break
        case 'tokenRefresh':
            console.log('token refresh succeeded')
            break
        case 'tokenRefresh_failure':
            console.log('token refresh failed')
            break
        case 'configured':
            console.log('the Auth module is configured')
    }
}

class ConnectBank extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectMainAccount: false,
            returnBack: false,
            sandboxUrl: "",
            urlReceived: false,
            code: "",
            goToNextStep: false,
            redirectToDashboard: false
        }
    }

    componentDidMount() {
        this.props.connectUrlRequest()
        this.setState({ urlReceived: true })
    }

    componentDidUpdate() {
        if (this.props.bank.connectUrlResponse !== null && this.state.urlReceived) {
            Hub.listen('auth', listener)
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('Connect bank Child | DEV')
                this.setState({
                    sandboxUrl: this.props.bank?.connectUrlResponse?.data?.data.sandbox_connect_bank_url,
                    urlReceived: false,
                    goToNextStep: true
                })
            } else {
                console.log('Connect bank Child | PROD')
                this.setState({
                    sandboxUrl: this.props.bank?.connectUrlResponse?.data?.data.connect_bank_url,
                    urlReceived: false,
                    goToNextStep: true
                })
            }
        }
        if (this.state.code.length > 0 && this.state.goToNextStep) {
            this.setState({
                selectMainAccount: true,
                goToNextStep: false
            })
        }
    }

    onBankResponse = (err, res) => {
        if (err) {
            this.setState({ code: res })
        }
        this.setState({ code: res })
    }

    onReturnBack = () => {
        this.setState({
            returnBack: true
        })
        window.location.href = '/signin'
    }

    handleRedirectToDashboard = (redirectToDashboard) => {
        this.setState({
            redirectToDashboard: redirectToDashboard
        })
    }

    render() {
        const { t } = this.props

        return (
            <>
                {this.state.redirectToDashboard ?
                    <Redirect push to="/child-dashboard" /> :
                    this.state.selectMainAccount ?
                        <BankAccounts
                            code={this.state.code}
                            role={"child"}
                            redirectToDashboard={this.handleRedirectToDashboard}
                        /> :
                        <>
                            <button className="yg-select-back-lp" onClick={this.onReturnBack}>
                                <img src={select_back} alt="Back" />
                            </button>
                            <div className="connect-bank-from-child">
                                <img src={bank_child} alt="Bank" />
                                <div className="form-group yg-form">
                                    <span className="yg-codelogin-headline-main">{t("not_connected_bank_title")}</span>
                                    <div className="yg-codelogin-form">
                                        <label htmlFor="yg-password" className="yg-headline-label">
                                            {t('not_connected_bank_desc')}
                                        </label>
                                    </div>
                                    <div className="yg-btn-form">
                                        <BankWindow
                                            bridge={this.onBankResponse}
                                            url={this.state.sandboxUrl}
                                        >
                                            {t('connect_to_bank')}
                                        </BankWindow>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        connectUrlRequest: () => dispatch(BankActions.connectUrlRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConnectBank))