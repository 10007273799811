import PropTypes from "prop-types"
import React from "react"

let browser = window
let popup = null
let timer = null

function watcher() {
    if (popup === null) {
        clearInterval(timer)
        timer = null
    } else if (popup !== null && !popup.closed) {
        popup.focus()
    } else if (popup !== null && popup.closed) {
        clearInterval(timer)
        browser.focus()
        browser.onClose("child was closed")
        timer = null
        popup = null
    }
}

export class SetAllowanceWindow extends React.Component {

    constructor(props) {
        super(props)

        this.onClickHandler = this.onClickHandler.bind(this)

        browser = window.self
        browser.onSuccess = (res) => {
            console.log(props);
            props.bridge(null, res)
        }

        browser.onError = (error) => {
            props.bridge(error)
        }

        browser.onOpen = (message) => {
            props.bridge(null, message)
        }

        browser.onClose = (message) => {
            // props.bridge(null, message);
        }
    }

    onClickHandler(evt) {
        const { url, name, opts } = this.props;
        if (popup && !popup.closed) {
            popup.focus();
            return;
        }

        popup = browser.open(url, name, opts);

        if (timer === null) {
            timer = setInterval(watcher, 2000);
        }

        return;
    }

    render() {
        const { children } = this.props;

        return (
            <button
                type="button"
                onClick={this.onClickHandler}
                className="btn yg-btn yg-btn-black"                
            >
                {children}
            </button>
        );
    }
}

SetAllowanceWindow.propTypes = {
    url: PropTypes.string.isRequired,
    bridge: PropTypes.func.isRequired,
    name: PropTypes.string,
    opts: PropTypes.string,
    disabled: PropTypes.bool
}

SetAllowanceWindow.defaultProps = {
    name: "Set Allowance Pop-up",
    opts: `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${600}, height=${600}`
}