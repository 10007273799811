import PropTypes from "prop-types"
import React from "react"
import { connect } from 'react-redux'
import ParentActions from '../../reducers/ParentRedux'
import logo_single_big from '../../svg/logos/logo_single_big.svg'
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons"

let browser = window
let popup = null
let timer = null

function watcher() {
    if (popup === null) {
        clearInterval(timer)
        timer = null
    } else if (popup !== null && !popup.closed) {
        popup.focus()
    } else if (popup !== null && popup.closed) {
        clearInterval(timer)
        browser.focus()
        browser.onClose("child was closed")
        timer = null
        popup = null
    }
}

class SetVrpAllowanceWindow extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            url: "",
            openWindow: false
        }

        this.onClickHandler = this.onClickHandler.bind(this)

        browser = window.self
        browser.onSuccess = (res) => {
            console.log(props);
            props.bridge(null, res)
        }

        browser.onError = (error) => {
            props.bridge(error)
        }

        browser.onOpen = (message) => {
            props.bridge(null, message)
        }

        browser.onClose = (message) => {
            // props.bridge(null, message);
        }
    }

    componentDidMount(){
        // console.log("mount check set vrp allowance windows");
        // if (this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri !== undefined && this.props.){
        //     this.setState({
        //         url: this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri
        //     })
        // }
    }   

    componentDidUpdate(prevProps, prevState){        
        if (this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri !== undefined && this.state.openWindow && prevProps.parent?.fetchingApiAllowance){
            
            this.setState({
                url: this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri,
                openWindow: false,
            })                            
        }
        if (prevState.url === "" && this.state.url !== ""){
            if (this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri != this.state.url){
                this.setState({
                    url: this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri,                    
                })  
            }
            else{
                
                if (popup && !popup.closed) {
                    popup.focus();
                    return;
                }    
                popup = browser.open(this.state.url, this.props.name, this.props.opts);
        
                if (timer === null) {
                    timer = setInterval(watcher, 2000);
                }
            }            
        }  
    }

    onClickHandler(evt) {     
        // console.log("clicked",this.state.url) 
        if (!this.state.openWindow  && this.state.url === ""){            
            const {child, provider} = this.props;            
            this.props.setVrpProviderRequest({
                child_sub: child.sub,
                provider_id: provider.value
            })            
            this.setState({
                openWindow: true
            })                       
            
        } 
    }

    render() {
        const { children } = this.props;        
        if (this.props.parent?.fetchingApiAllowance){
            return (
                <button
                type="button"
                className="btn yg-btn yg-btn-black"
                >
                <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
            </button> 
            )
        }
        else{
            return (      
            
                <button
                    type="button"
                    onClick={this.onClickHandler}
                    className="btn yg-btn yg-btn-black"
                    disabled={this.props.disableCondition}
                >
                    {children}
                </button>
            );
        }
        
    }
}

SetVrpAllowanceWindow.propTypes = {
    url: PropTypes.string.isRequired,
    bridge: PropTypes.func.isRequired,
    name: PropTypes.string,
    opts: PropTypes.string,
    disabled: PropTypes.bool
}

SetVrpAllowanceWindow.defaultProps = {
    name: "Set Allowance Pop-up",
    opts: `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${600}, height=${600}`
}

const mapDispatchToProps = (dispatch) => {    
    return {                
        setVrpProviderRequest: (body) => dispatch(ParentActions.setVrpProviderRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SetVrpAllowanceWindow)