import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import BankActions from '../../../reducers/BankRedux'
import { BankWindow } from '../../../components/helpers/bank-window'
import BankAccountsDetails from '../common/bank-accounts-details'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { Hub } from 'aws-amplify'

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in')
            break
        case 'signUp':
            console.log('user signed up')
            break
        case 'signOut':
            console.log('user signed out')
            break
        case 'signIn_failure':
            console.log('user sign in failed')
            break
        case 'tokenRefresh':
            console.log('token refresh succeeded')
            break
        case 'tokenRefresh_failure':
            console.log('token refresh failed')
            break
        case 'configured':
            console.log('the Auth module is configured')
    }
}

class BankAccounts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sandboxUrl: "",
            urlReceived: false,
            goToNextStep: false,
            code: "",
            selectMainAccount: false
        }
    }

    componentDidMount() {
        this.props.getAccountsDashboardRequest()
        this.props.connectUrlRequest()
        this.setState({ urlReceived: true })
    }

    componentDidUpdate() {
        if (this.props.bank.connectUrlResponse !== null && this.state.urlReceived) {
            Hub.listen('auth', listener)
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('Bank Accouunts Parent | DEV')
                this.setState({
                    sandboxUrl: this.props.bank?.connectUrlResponse?.data?.data.sandbox_connect_bank_url,
                    urlReceived: false,
                    goToNextStep: true
                })
            } else {
                console.log('Bank Accouunts Parent | PROD')
                this.setState({
                    sandboxUrl: this.props.bank?.connectUrlResponse?.data?.data.connect_bank_url,
                    urlReceived: false,
                    goToNextStep: true
                })
            }
        }
        if (this.state.code.length > 0 && this.state.goToNextStep) {
            this.setState({
                selectMainAccount: true,
                goToNextStep: false
            })
        }
    }

    onBankResponse = (err, res) => {
        if (err) {
            this.setState({ code: res })
        }
        this.setState({ code: res })
    }

    render() {
        const { t } = this.props

        return (
            <Modal
                isOpen={this.props.showBankAccounts}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className="w-80">
                    {
                        this.state.selectMainAccount ?
                            <BankAccountsDetails
                                code={this.state.code}
                                role={"parent"}
                                handleCloseModal={this.props.handleCloseModal}
                            /> :
                            <>
                                <span className="yg-greeting-name">{t('bank_account')}</span>
                                {
                                    this.props.bank.fetchingApiButton ?
                                        <button
                                            type="button"
                                            className="btn yg-btn yg-btn-black mt-5"
                                        >
                                            <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                        </button> :
                                        <>
                                            <div className="yg-btn-form-lets-start pb-4 pt-5">
                                                <BankWindow
                                                    bridge={this.onBankResponse}
                                                    url={this.state.sandboxUrl}
                                                >
                                                    <span className="yg-headline-label-light-xs-white">{t('change_account')}</span>
                                                </BankWindow>
                                            </div>
                                            {
                                                this.props.bank?.getAccountsDashboardResponse?.data?.data?.accounts.map(i => {
                                                    return (
                                                        <div className="yg-gradient bank-accounts">
                                                            <span className="yg-headline-label-light-s-black pt-3 pb-5">{i.accountName}</span>
                                                            <span className="yg-card-alwn-days">{i.providerName}</span>
                                                            <span className="yg-headline-label-light-xs-black pt-2 pb-3">{i.accountIban}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </>
                    }
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountsDashboardRequest: () => dispatch(BankActions.getAccountsDashboardRequest()),
        connectUrlRequest: () => dispatch(BankActions.connectUrlRequest())
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts)