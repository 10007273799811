import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import { connect } from 'react-redux'
import ParentActions from '../../../reducers/ParentRedux'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'
import { SetAllowanceWindow } from "../../helpers/set-allowance-window"
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import SetVrpAllowanceWindow from '../../helpers/set-vrp-allowance'
import { withTranslation } from 'react-i18next'
import { setAllowance } from '../../../sagas/parentSaga'

class SetAllowance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amount: this.props.defaultAllowance.toString(),
            period: "weekly",
            allowance_day: "",
            otherValue: '',
            vrp_limit: '',
            setAllowanceSet: false,
            url: "",
            responseUrl: false,
            count: 0,
            showVrpProviders: false,
            provider: {},
            provider_options: [],            
        }
    }

    componentDidMount() {
        console.log(this.props)
        if (this.props.defaultAllowance !== 5 ||
            this.props.defaultAllowance !== 10 ||
            this.props.defaultAllowance !== 15 ||
            this.props.defaultAllowance !== 20 ||
            this.props.defaultAllowance !== 30 ||
            this.props.defaultAllowance !== 40) {
            this.setState({
                amount: "other",
                otherValue: this.props.defaultAllowance.toString()
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.responseUrl && this.state.count === 0) {
            toast.success('Allowance is set!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            setTimeout(() => {
                this.setState({
                    setAllowanceSet: false
                })
                this.props.handleCloseModal(true)
            }, 2000)
            this.setState({
                count: 1
            })
        }
    }

    onPeriodChange = (e) => {
        this.setState({
            period: e.target.id,
            allowance_day: ""
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onOtherChange = (e) => {
        this.setState({
            otherValue: e.target.value
        })
    }

    onVrpChange = (e) => {
        this.setState({
            vrp_limit: e.target.value
        })
    }

    onSetAllowance = () => {
        
        this.props.setVrpAllowanceRequest({
            child_sub: this.props.child.sub,
            period: this.state.period === "weekly" ? 1 : 2,
            amount: this.state.amount === "other" ? this.state.otherValue : this.state.amount,
            day: this.state.allowance_day.value,
            vrp: this.state.vrp_limit
        })
        if (this.props.parent.setVrpAllowanceResponse?.data?.data?.authorization_flow?.actions?.next.providers !== undefined) {
            let providers = this.props.parent.setVrpAllowanceResponse?.data?.data?.authorization_flow?.actions?.next.providers
            let options = []
            for (let i = 0; i  < providers.length; i++){
                options.push({"value":providers[i].id,"label":providers[i].display_name});                
            }
            
            this.setState({
                url: this.props.parent.setVrpAllowanceResponse?.data?.data?.authorization_flow?.actions?.next.uri,
                showVrpProviders: true,
                provider_options: options,
            })                                 
        }        
    }

    onSetVrpProvider = () => {
        // this.props.setVrpProviderRequest({
        //     child_sub: this.props.child.sub,
        //     provider_id: this.state.provider.value
        // })
        // console.log(this.props.parent)
        // console.log(this.props.parent.setVrpProviderResponse)
        // if (this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri !== undefined){
        //     this.setState({
        //         url: this.props.parent.setVrpProviderResponse?.data?.data?.authorization_flow?.actions?.next.uri,
        //         setAllowanceSet: true,
        //     })
        // }
        // console.log(this.state.url)
        // console.log(this.state.setAllowanceSet)
        this.setState({setAllowanceSet:true})
    }

    onChangeAllowance = (value) => {
        this.setState({
            allowance_day: value
        })
    }

    onChangeProvider = (value) => {        
        this.setState({
            provider: value
        })        
    }

    onWindowResponse = (err, res) => {
        if (err) {
            this.setState({ responseUrl: res })
        }
        this.setState({ responseUrl: res })
        console.log("responseURL: ",this.state.responseUrl);
        this.props.getParentDashboardRequest();
    }

    render() {
        const { t } = this.props
        let child = this.props.child

        const customStyles = {
            menu: () => ({
                backgroundColor: child.color === "1" ? '#effaff' : '#fff1ea',
                marginTop: '5px',
                borderRadius: '12px',
                padding: '7px 0'
            }),
            control: () => ({
                padding: '5px 15px',
                borderRadius: '12px',
                border: 'none',
                display: 'flex',
                backgroundColor: child.color === "1" ? '#effaff' : '#fff1ea'
            }),
            option: (styles, { isFocused, isSelected }) => ({
                cursor: 'pointer',
                padding: '10px 15px',
                color: child.color === "1" ? (isSelected ? '#03A6F7' : isFocused ? '#03A6F7' : undefined) : (isSelected ? '#ff5b02' : isFocused ? '#ff5b02' : undefined)
            })
        }

        let weeklyOptions = [
            { value: 1, label: 'Monday' },
            { value: 2, label: 'Tuesday' },
            { value: 3, label: 'Wednesday' },
            { value: 4, label: 'Thursday' },
            { value: 5, label: 'Friday' },
            { value: 6, label: 'Saturday' },
            { value: 7, label: 'Sunday' }
        ]

        let monthlyOptions = [
            { value: 1, label: 'First Week' },
            { value: 2, label: 'Second Week' }
        ]

        return (<>
            { this.state.showVrpProviders ? <Modal
                isOpen={this.state.showVrpProviders}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className="w-80">
                    <span className="yg-greeting-name">{t('provider_list')}</span>
                    <div>
                        <div className="yg-password-form-div pt-5">
                           
                        </div>
                    </div>

                    <div className="yg-password-form-div">
                        <label htmlFor="yg-allowance-day" className="yg-headline-label-dark">{t('provider')}</label>
                        <Select
                            className="allowance-select"
                            onChange={this.onChangeProvider}
                            options={this.state.provider_options}
                            isSearchable={false}
                            styles={customStyles}
                        />
                    </div>                    
                </div>
                <div className="yg-btn-form" style={{ display: 'flex' }}>

                 
            <SetVrpAllowanceWindow                                                
                                    bridge={this.onWindowResponse}
                                    url={this.state.url}
                                    child={this.props.child}
                                    provider={this.state.provider}
                                    disableCondition={this.state.provider.value === undefined}                                    
                                >
                                    {t('set_allowance')}
                                </SetVrpAllowanceWindow>                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                        <button
                            type="button"
                            className="btn yg-btn yg-btn-gray"
                            onClick={this.props.handleCloseModal}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                
            </Modal> : 
            <Modal
                isOpen={this.props.showAllowanceModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className="w-80">
                    <span className="yg-greeting-name">{child.name}{t('s_allowance')}</span>
                    <div>
                        <div className="yg-password-form-div pt-5">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('period')}</label>
                            <div className="yg-radio-form-sa pt-2">
                                <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                                    <input type="radio" id="weekly" name="period" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} onChange={this.onPeriodChange} checked={this.state.period === 'weekly'} />
                                    <label htmlFor="weekly" className="rb-label" >{t('weekly')}</label>
                                    <input type="radio" id="monthly" name="period" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} onChange={this.onPeriodChange} checked={this.state.period === 'monthly'} />
                                    <label htmlFor="monthly" className="rb-label" >{t('monthly')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('amount')}</label>
                            {
                                this.state.period === "weekly" ?
                                    <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                                        <input type="radio" id="5" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "5"} onChange={this.onAmountChange} />
                                        <label htmlFor="5" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>5</label>
                                        <input type="radio" id="10" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "10"} onChange={this.onAmountChange} />
                                        <label htmlFor="10" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>10</label>
                                        <input type="radio" id="15" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "15"} onChange={this.onAmountChange} />
                                        <label htmlFor="15" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>15</label>
                                        <input type="radio" id="other" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                                        <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                                    </div> :
                                    <div className={child.color === "1" ? "yg-gradient-blue" : "yg-gradient-orange"}>
                                        <input type="radio" id="20" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "20"} onChange={this.onAmountChange} />
                                        <label htmlFor="20" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>20</label>
                                        <input type="radio" id="30" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "30"} onChange={this.onAmountChange} />
                                        <label htmlFor="30" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>30</label>
                                        <input type="radio" id="40" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "40"} onChange={this.onAmountChange} />
                                        <label htmlFor="40" className="rb-label-dashboard"><span className="yg-balance-headername-min-xs">£</span>40</label>
                                        <input type="radio" id="other" name="amount" className={child.color === "1" ? "rb-input-blue" : "rb-input-orange"} checked={this.state.amount === "other"} onChange={this.onAmountChange} />
                                        <label htmlFor="other" className="rb-label-dashboard">{t('other')}</label>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        this.state.amount === "other" ?
                            <div className="yg-password-form-div">
                                <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('other_amount')}</label>
                                <div className="yg-radio-form-sa pt-2">
                                    <div className="yg-input-other w-100">
                                        <span className="yg-card-number-small-time">£</span>
                                        <input
                                            type="text"
                                            className={child.color === "1" ? "rb-input-text-blue p-10-5" : "rb-input-text-orange p-10-5"}
                                            value={this.state.otherValue.replace(/[^0-9]/, '')}
                                            onChange={this.onOtherChange}
                                        />
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="yg-password-form-div">
                            <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('vrp_limit')}</label>
                                <div className="yg-radio-form-sa pt-2">
                                    <div className="yg-input-other w-100">
                                        <span className="yg-card-number-small-time">£</span>
                                        <input
                                            type="text"
                                            className={child.color === "1" ? "rb-input-text-blue p-10-5" : "rb-input-text-orange p-10-5"}
                                            value={this.state.vrp_limit.replace(/[^0-9]/, '')}
                                            onChange={this.onVrpChange}
                                        />
                                    </div>
                                </div>
                    </div>
                    <div className="yg-password-form-div">
                        <label htmlFor="yg-allowance-day" className="yg-headline-label-dark">{t('allowance_day')}</label>
                        <Select
                            className="allowance-select"
                            onChange={this.onChangeAllowance}
                            options={this.state.period === "weekly" ? weeklyOptions : monthlyOptions}
                            isSearchable={false}
                            styles={customStyles}
                        />
                    </div>
                    <div className="yg-btn-form" style={{ display: 'flex' }}>
                        {
                            this.props.parent.fetchingApiButton ?
                                <button
                                    type="button"
                                    className="btn yg-btn yg-btn-black"
                                >
                                    <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                </button> :
                                (
                                    this.state.setAllowanceSet ?
                                        <SetAllowanceWindow
                                            bridge={this.onWindowResponse}
                                            url={this.state.url}
                                        >
                                            {t('set_allowance')}
                                        </SetAllowanceWindow> :
                                        <button
                                            type="button"
                                            className="btn yg-btn yg-btn-black"
                                            onClick={this.onSetAllowance}
                                            disabled={(this.state.allowance_day.length === 0 ? true : false) || (this.state.amount === "other" && this.state.otherValue === "")}
                                        >
                                            {t('save')}
                                        </button>
                                )
                        }
                        <button
                            type="button"
                            className="btn yg-btn yg-btn-gray"
                            onClick={this.props.handleCloseModal}
                        >
                            {t('cancel')}
                        </button>
                    </div>
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Modal>
                    }
                    </>)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAllowanceRequest: (body) => dispatch(ParentActions.setAllowanceRequest(body)),
        setVrpAllowanceRequest: (body) => dispatch(ParentActions.setVrpAllowanceRequest(body)),
        setVrpProviderRequest: (body) => dispatch(ParentActions.setVrpProviderRequest(body)),
        getParentDashboardRequest: () => dispatch(ParentActions.getParentDashboardRequest()),
    }
}
const mapStateToProps = (state) => {    
    return {
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SetAllowance))