import React, { Component } from 'react'
import start from '../../../svg/illustrations/start.svg'
import { Redirect } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import copy from '../../../svg/copy.svg'
import share from '../../../svg/share.svg'
import PureModal from 'react-pure-modal'
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share"

export default class LetsStart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            redirectToDashboard: false,
            shareModal: false
        }
    }

    onAddOtherChild = () => {
        this.props.goToAddOtherChildStep(true)
    }

    onGoToDashboard = () => {
        this.setState({
            redirectToDashboard: true
        })
    }

    unsecuredCopyToClipboard(text) {
        const textArea = document.createElement("textarea")
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
            document.execCommand('copy')
        } catch (err) {
            console.error('Unable to copy to clipboard', err)
        }
        document.body.removeChild(textArea)
    }

    onCopyText = () => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText('Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword)
            toast.success('Text copied!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            this.unsecuredCopyToClipboard('Email is: ' + this.state.childEmail + ', Password is: ' + this.state.childTemporaryPassword)
        }
    }

    onShareTo = () => {
        this.setState({ shareModal: true })
    }

    onCloseShareModal = () => {
        this.setState({ shareModal: false })
    }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    this.state.redirectToDashboard ?
                        <Redirect push to="/parent-dashboard" /> :
                        <div className="code-form">
                            <img src={start} alt="Let's Start" className="yg-parentdb-nochildimg" />
                            <div className="form-group yg-form">
                                {this.props.childData?.child?.email?.length === 0 && this.props.childData?.temporaryPassword?.length === 0 ?
                                    <>
                                        <span className="yg-codelogin-headline-main">{t('child_login_info')}</span>
                                        <div className="child-info-block mt-3">
                                            <div>
                                                {t('email')}:&nbsp;<span className='yg-headline-label-light-s-blue'>{this.props.childData?.child?.email}</span>
                                            </div>
                                            <div>
                                                {t('password')}:&nbsp;<span className='yg-headline-label-light-s-blue'>{this.props.childData?.temporaryPassword}</span>
                                            </div>
                                            <div className="copy-share">
                                                <button className="goforward-button mr-3" onClick={this.onShareTo}>
                                                    <img src={share} alt="Share" />
                                                </button>
                                                <button className="goforward-button mr-2" onClick={this.onCopyText}>
                                                    <img src={copy} alt="Copy" />
                                                </button>
                                            </div>
                                            <PureModal
                                                footer={
                                                    <div className='yg-share-icons'>
                                                        <TwitterShareButton
                                                            title={'Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword}
                                                            url={"https://youngomoney.com/"}
                                                        >
                                                            <TwitterIcon size={32} round={true} />
                                                        </TwitterShareButton>
                                                        <EmailShareButton
                                                            title={'Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword}
                                                            url={"https://youngomoney.com/"}
                                                        >
                                                            <EmailIcon size={32} round={true} />
                                                        </EmailShareButton>
                                                        <FacebookShareButton
                                                            title={'Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword}
                                                            url={"https://youngomoney.com/"}
                                                        >
                                                            <FacebookIcon size={32} round={true} />
                                                        </FacebookShareButton>
                                                        <FacebookMessengerShareButton
                                                            title={'Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword}
                                                            url={"https://youngomoney.com/"}
                                                        >
                                                            <FacebookMessengerIcon size={32} round={true} />
                                                        </FacebookMessengerShareButton>
                                                        <TelegramShareButton
                                                            title={'Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword}
                                                            url={"https://youngomoney.com/"}
                                                        >
                                                            <TelegramIcon size={32} round={true} />
                                                        </TelegramShareButton>
                                                        <TwitterShareButton
                                                            title={'Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword}
                                                            url={"https://youngomoney.com/"}
                                                        >
                                                            <TwitterIcon size={32} round={true} />
                                                        </TwitterShareButton>
                                                        <WhatsappShareButton
                                                            title={'Email is: ' + this.props.childData?.child?.email + ', Password is: ' + this.props.childData?.temporaryPassword}
                                                            url={"https://youngomoney.com/"}
                                                        >
                                                            <WhatsappIcon size={32} round={true} />
                                                        </WhatsappShareButton>
                                                    </div>
                                                }
                                                isOpen={this.state.shareModal}
                                                onClose={this.onCloseShareModal}
                                            >
                                                <p>{t('your_can_share')}</p>
                                            </PureModal>
                                        </div>
                                    </> : null
                                }
                                <div className="yg-btn-form-lets-start">
                                    <button
                                        type="button"
                                        onClick={this.onGoToDashboard}
                                        className="btn yg-btn-thin yg-btn-black"
                                    >
                                        {t('lets_start')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.onAddOtherChild}
                                        className="btn yg-btn-thin yg-btn-white"
                                    >
                                        {t('add_other_child')}
                                    </button>
                                </div>
                            </div>
                            <ToastContainer
                                position="bottom-left"
                                className="toast-container-mobile"
                                autoClose={1000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </div>
                }
            </>
        )
    }
}