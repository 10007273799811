import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import NotificationActions from '../../../reducers/NotificationRedux'
import cancel from '../../../svg/cancel.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import profile_pic from '../../../svg/profile_pic.svg'
import chore from '../../../svg/chore_gray.svg'
import goal from '../../../svg/goal_gray.svg'
import transfer from '../../../svg/transfer_gray.svg'
import request from '../../../svg/request_gray.svg'
import problem from '../../../svg/problem.svg'

class Notifications extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showSettingsModal: false,
            checked: true,
            notificationsList: true,
            notifications: [],
            groupNotifications: [],
            settings: {},
            allowance_reminder: false,
            low_balance: false,
            expenses: false,
            completed_chores: false,
            completed_goals: false,
            money_request: false,
            not: null,
        }
    }

    isNumeric(str) {
        return !isNaN(str);
    }

    comparableTime(element){
        let newString = "";
        for (let i = 0; i < element.length; i++){
            if (this.isNumeric(element[i])){
                newString += element[i]
            }
        }
        return parseInt(newString,10)
    }

    // This function sort the notifications of a specific date
    insertIntoSortedArray(arr, element) {        
        const insertIndex = arr.findIndex((el) =>
            this.comparableTime(el.date.split("T")[1]) < this.comparableTime(element.date.split("T")[1])
        );
        
        const index = insertIndex == -1 ? arr.length : insertIndex;                
        arr.splice(index, 0, element);
    }
      
    componentDidMount() {           
        this.props.getNotificationsRequest()
    }

    componentDidUpdate(prevProps,prevState){
        if (this.props.notification?.getNotificationsResponse?.data?.data !== undefined && prevProps.notification?.fetchingApiButton){
            this.setState({
                not: this.props.notification?.getNotificationsResponse?.data?.data
            })
        }
        if (prevState.not != this.state.not){
           
                // Dividing the object into smaller ones by date
            let groups = this.state.not?.reduce((groups, i) => {
                const date = i.date.split('T')[0]
                if (!groups[date]) {
                    groups[date] = []
                }            
                this.insertIntoSortedArray(groups[date],i);            
                    return groups
            }, {})
            
            // Sorting Date keys to show the latest notifications first
            groups = Object.fromEntries(
                Object.entries(groups).sort((a, b) => new Date(b[0]) - new Date(a[0]))
            );

            const groupNotifications = Object.keys(groups).map((date) => {
                return {
                    date,
                    notificationGroup: groups[date]
                }
            })

            this.setState({
                notifications: this.props.notification?.getNotificationsResponse?.data?.data,
                groupNotifications: groupNotifications
            })                
        }
    }

    
    onShowSettings = () => {
        this.props.getPermissionsRequest()
        let settings = this.props.notification?.getPermissionsResponse?.data?.data
        this.setState({
            showSettingsModal: true,
            settings: settings,
            allowance_reminder: settings?.allowance_reminder,
            low_balance: settings?.low_balance,
            expenses: settings?.expenses,
            completed_chores: settings?.completed_chores,
            completed_goals: settings?.completed_goals,
            money_request: settings?.money_request
        })
    }

    onAllowanceChange = () => {
        this.props.changePermissionsRequest({
            permission: !this.state.allowance_reminder,
            type: "allowance_reminder"
        })
        this.setState({
            allowance_reminder: !this.state.allowance_reminder
        })
    }

    onLowBalanceChange = () => {
        this.props.changePermissionsRequest({
            type: "low_balance",
            permission: !this.state.low_balance
        })
        this.setState({
            low_balance: !this.state.low_balance
        })
    }

    onExpensesChange = () => {
        this.props.changePermissionsRequest({
            type: "expenses",
            permission: !this.state.expenses
        })
        this.setState({
            expenses: !this.state.expenses
        })
    }

    onChoresChange = () => {
        this.props.changePermissionsRequest({
            type: "completed_chores",
            permission: !this.state.completed_chores
        })
        this.setState({
            completed_chores: !this.state.completed_chores
        })
    }

    onGoalsChange = () => {
        this.props.changePermissionsRequest({
            type: "completed_goals",
            permission: !this.state.completed_goals
        })
        this.setState({
            completed_goals: !this.state.completed_goals
        })
    }

    onMoneyRequestChange = () => {
        this.props.changePermissionsRequest({
            type: "money_request",
            permission: !this.state.money_request
        })
        this.setState({
            money_request: !this.state.money_request
        })
    }

    onClearAll = () => {
        this.setState({
            notificationsList: false
        })
    }

    render() {
        const { t } = this.props

        return (
            <Modal
                isOpen={this.props.showNotifications}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                {
                    this.state.showSettingsModal ?
                        <div className="w-80">
                            <span className="yg-greeting-name">{t('notification_settings')}</span>
                            {
                                this.props.notification.fetchingApiButton ?
                                    <button
                                        type="button"
                                        className="btn yg-btn yg-btn-black mt-5"
                                    >
                                        <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                    </button> :
                                    <>
                                        <div className="yg-topnav-mb-send mt-5 mb-2"><span className="yg-headline-label-dark-xs pr-4">{t('email')}</span></div>
                                        {/*<div className="yg-gradient untrg-savings mb-4 w-100">
                                            <div className="yg-balance-header-left">
                                                <span className="yg-headline-label-dark-xs py-3">{t('all_notifications')}</span>
                                                <span className="yg-card-alwn-days pb-3">{t('bank_name')}</span>
                                            </div>
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>*/}
                                        <span className="yg-headline-label-dark-xs">{t('parents_notification')}</span>
                                        <div className="yg-gradient untrg-savings mt-2 mb-4">
                                            <div className="yg-balance-header-left">
                                                <span className="yg-headline-label-dark-xs py-3">{t('allowance_reminder')}</span>
                                            </div>
                                            <label className="switch">
                                                <input type="checkbox" onChange={this.onAllowanceChange} value={this.state.allowance_reminder} checked={this.state.allowance_reminder} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <span className="yg-headline-label-dark-xs">{t('childs_reminder')}</span>
                                        <div className="yg-gradient">
                                            <div className="untrg-savings mt-2">
                                                <div className="yg-balance-header-left">
                                                    <span className="yg-headline-label-dark-xs py-3">{t('low_balance')}</span>
                                                </div>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={this.onLowBalanceChange} value={this.state.low_balance} checked={this.state.low_balance} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="untrg-savings w-100">
                                                <div className="yg-balance-header-left w-70">
                                                    <span className="yg-headline-label-dark-xs py-3">{t('spendings')}</span>
                                                </div>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={this.onExpensesChange} value={this.state.expenses} checked={this.state.expenses} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="untrg-savings w-100">
                                                <div className="yg-balance-header-left w-70">
                                                    <span className="yg-headline-label-dark-xs py-3">{t('completed_chores')}</span>
                                                </div>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={this.onChoresChange} value={this.state.completed_chores} checked={this.state.completed_chores} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="untrg-savings w-100">
                                                <div className="yg-balance-header-left w-70">
                                                    <span className="yg-headline-label-dark-xs py-3">{t('completed_goals')}</span>
                                                </div>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={this.onGoalsChange} value={this.state.completed_goals} checked={this.state.completed_goals} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="untrg-savings mb-3 w-100">
                                                <div className="yg-balance-header-left w-70">
                                                    <span className="yg-headline-label-dark-xs py-3">{t('money_request')}</span>
                                                </div>
                                                <label className="switch">
                                                    <input type="checkbox" onChange={this.onMoneyRequestChange} value={this.state.money_request} checked={this.state.money_request} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div> :
                        <div className="w-80">
                            <span className="yg-greeting-name">{t('notifications')}</span>
                            {
                                this.props.notification.fetchingApiButton ?
                                    <button
                                        type="button"
                                        className="btn yg-btn yg-btn-black mt-5"
                                    >
                                        <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                    </button> :
                                    <>
                                        <div className="yg-btn-form-lets-start pb-4 pt-5">
                                            <button
                                                type="button"
                                                className="btn yg-btn yg-btn-black"
                                                onClick={this.onShowSettings}
                                            >
                                                <span className="yg-headline-label-light-xs-white">{t('settings')}</span>
                                            </button>
                                        </div>
                                        {!this.state.notificationsList ? null :
                                            <>
                                                <div className="pt-5">
                                                    {
                                                        this.state.groupNotifications.map((i, key) => {
                                                            return (
                                                                <>
                                                                    <span key={key} className="yg-card-alwn-costs-q-xs py-2">
                                                                        {i.date.substring(8, 10)}&nbsp;
                                                                        {i.date.substring(5, 7) === "01" ? "JANUARY" :
                                                                            i.date.substring(5, 7) === "02" ? "FEBRUARY" :
                                                                                i.date.substring(5, 7) === "03" ? "MARCH" :
                                                                                    i.date.substring(5, 7) === "04" ? "APRIL" :
                                                                                        i.date.substring(5, 7) === "05" ? "MAY" :
                                                                                            i.date.substring(5, 7) === "06" ? "JUNE" :
                                                                                                i.date.substring(5, 7) === "07" ? "JULY" :
                                                                                                    i.date.substring(5, 7) === "08" ? "AUGUST" :
                                                                                                        i.date.substring(5, 7) === "09" ? "SEPTEMBER" :
                                                                                                            i.date.substring(5, 7) === "10" ? "OCTOBER" :
                                                                                                                i.date.substring(5, 7) === "11" ? "NOVEMBER" :
                                                                                                                    i.date.substring(5, 7) === "12" ? "DECEMBER" :
                                                                                                                        null
                                                                        }
                                                                    </span>
                                                                    <div className="my-4">
                                                                        {
                                                                            i.notificationGroup.map(p => {
                                                                                
                                                                                return (
                                                                                    <div className="yg-card-head-payments mt-2 py-2">
                                                                                        <div>
                                                                                            {
                                                                                                p.locArgs.length !== 0 ? (
                                                                                                    p.type === "chores" ? <img src={chore} alt="Chore" className="yg-card-avatar-min" /> :
                                                                                                        p.type === "goals" ? <img src={goal} alt="Goal" className="yg-card-avatar-min" /> :
                                                                                                            p.type === "money_request" ? <img src={request} alt="Avatar" className="yg-card-avatar-min" /> :
                                                                                                                p.type === "payments" ? <img src={transfer} alt="Avatar" className="yg-card-avatar-min" /> :
                                                                                                                p.type === "payments_failed" ? <img src={problem} alt="Avatar" className="yg-card-avatar-min" /> :
                                                                                                                    <img src={profile_pic} alt="Avatar" className="yg-card-avatar-min" />) :
                                                                                                    <img src={profile_pic} alt="Avatar" className="yg-card-avatar-min" />
                                                                                            }
                                                                                            <span className="card-header-notifications yg-notifications-span">
                                                                                                {
                                                                                                    p.locTitleKey === "CHILD_COMPLETED_BANK_CONNECTION_TITLE" && p.locBodyKey == "CHILD_COMPLETED_BANK_CONNECTION_BODY" ? "Your child has connected to the bank. The application features now activated. Please set allowance for " + p.locArgs[1] + " now!" :
                                                                                                        p.locTitleKey === "CHORES_ADDED_TITLE" && p.locBodyKey == "CHORES_ADDED_BODY" ? "You have a new chore! Let's start" + p.locArgs[1] + "!" :
                                                                                                            p.locTitleKey === "CHORES_COMPLETED_TITLE" && p.locBodyKey == "CHORES_COMPLETED_BODY" ? (p.locArgs[1] + " has completed " + p.locArgs[2] + ". Let's check it out.") :
                                                                                                                p.locTitleKey === "CHORES_APPROVED_TITLE" && p.locBodyKey == "CHORES_APPROVED_BODY" ? "Congratulations! " + p.locArgs[1] + " completed 👏" :
                                                                                                                    p.locTitleKey === "GOAL_COMPLETED_CHILD_TITLE" && p.locBodyKey == "GOAL_COMPLETED_CHILD_BODY" ? "Congratulations! You completed your goal!" :
                                                                                                                        p.locTitleKey === "GOAL_ADDED_TITLE" && p.locBodyKey == "GOAL_ADDED_BODY" ? "You have a new goal! Let’s check " + p.locArgs[1] + "!" :
                                                                                                                            p.locTitleKey === "GOAL_COMPLETED_PARENT_TITLE" && p.locBodyKey == "GOAL_COMPLETED_PARENT_BODY" ? (p.locArgs[1] + " has completed " + p.locArgs[2] + ". Let's check it out.") :
                                                                                                                                p.locTitleKey === "REQUEST_MONEY_TITLE" && p.locBodyKey == "REQUEST_MONEY_BODY" ? (p.locArgs[1] + " requests for " + p.locArgs[2] + " " +  p.locArgs[3]) :
                                                                                                                                    p.locTitleKey === "PARENT_PAYMENT_EXECUTED_TITLE" && p.locBodyKey === "PARENT_PAYMENT_EXECUTED_BODY" ? (p.locArgs[1] + " " + p.locArgs[2] + " has successfully transferred to " + p.locArgs[3]) :
                                                                                                                                        p.locTitleKey === "CHILD_PAYMENT_EXECUTED_TITLE" && p.locBodyKey === "CHILD_PAYMENT_EXECUTED_BODY" ? ("You've received a " + p.locArgs[1] + " " + p.locArgs[2] + " transfer!") :
                                                                                                                                            p.locTitleKey === "TRANSFER_PAYMENT_EXECUTED_TITLE" && p.locBodyKey == "TRANSFER_PAYMENT_EXECUTED_BODY" ? ("Money transfer was completed successfully.") :
                                                                                                                                                p.locTitleKey === "TRANSFER_PAYMENT_FAILED_TITLE" && p.locBodyKey == "TRANSFER_PAYMENT_FAILED_BODY" ? ("Your transfer attempt was failed.") :
                                                                                                                                                    p.locTitleKey === "PAYMENT_FAILED_TITLE" && p.locBodyKey == "PAYMENT_FAILED_BODY" ? ("The transaction could not be completed. Please try again.") :
                                                                                                                                                        p.locTitleKey === "VRP_LIMIT_EXCEEDED_TITLE" && p.locBodyKey == "VRP_LIMIT_EXCEEDED_BODY" ? ("Your transfer attempt was unsuccessful as it exceeded the allowance limit.") :
                                                                                                                                                            p.locBodyKey == "CHORES_PAYMENT_FAILED_FOR_REMITTER" ? ("Failed transfer for " + p.locArgs[1] + "’s completed chores") :
                                                                                                                                                                p.locBodyKey == "CHORES_PAYMENT_EXECUTED_FOR_REMITTER" ? ("Successful transfer for " + p.locArgs[1] + "’s completed chores") :
                                                                                                                                                                    p.locBodyKey == "EXTRA_ALLOWANCE_PAYMENT_FAILED_FOR_REMITTER" ? ("Failed extra allowance to " + p.locArgs[1]) :
                                                                                                                                                                        p.locBodyKey == "EXTRA_ALLOWANCE_PAYMENT_EXEUTED_FOR_REMITTER" ? ("Extra allowance to " + p.locArgs[1]) :
                                                                                                                                                                            p.locBodyKey == "ALLOWANCE_PAYMENT_FAILED_FOR_REMITTER" ? ("Failed " + p.locArgs[1] + "’s " + p.locArgs[2] + " allowance") :
                                                                                                                                                                                p.locBodyKey == "ALLOWANCE_PAYMENT_EXECUTED_FOR_REMITTER" ? (p.locArgs[1] + "’s " + p.locArgs[2] + " allowance") :
                                                                                                                                                                                    p.locBodyKey == "GOAL_PAYMENT_FAILED_FOR_REMITTER" ? ("Failed transfer from savings to main account") :
                                                                                                                                                                                        p.locBodyKey == "GOAL_PAYMENT_EXECUTED_FOR_REMITTER" ? ("Successful transfer from savings to main account") :
                                                                                                                                                                                            p.locBodyKey == "CHORES_PAYMENT_EXECUTED_FOR_BENEFICIARY" ? ("Payment received for completed chores") :
                                                                                                                                                                                                p.locBodyKey == "EXTRA_ALLOWANCE_PAYMENT_EXECUTED_FOR_BENEFICIARY" ? ("Payment received") :
                                                                                                                                                                                                    p.locBodyKey == "EXTRA_ALLOWANCE_PAYMENT_EXECUTED_FOR_BENEFICIARY" ? ("Payment received") :
                                                                                                                                                                                                        p.locBodyKey == "ALLOWANCE_PAYMENT_EXECUTED_FOR_BENEFICIARY" ? (p.locArgs[1] + " payment received") :
                                                                                                                                                                                                            p.locBodyKey == "MONEY_TRANSFER_BETWEEN_ACCOUNTS_FAILED_FOR_REMITTER" ? ("Transfer failed between bank accounts") :
                                                                                                                                                                                                                p.locBodyKey == "MONEY_TRANSFER_BETWEEN_ACCOUNTS_EXECUTED_FOR_REMITTER" ? ("Successful transfer between bank accounts") :
                                                                                                                                                                                                                    t('unknown_notification')
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <span className="yg-card-number-small-time">
                                                                                                {p.date?.substring(11, 16)}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                        }
                                    </>
                            }
                        </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNotificationsRequest: () => dispatch(NotificationActions.getNotificationsRequest()),
        getPermissionsRequest: () => dispatch(NotificationActions.getPermissionsRequest()),
        changePermissionsRequest: (body) => dispatch(NotificationActions.changePermissionsRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        notification: state.notification
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)