import React, { Component } from 'react'
import { connect } from 'react-redux'
import BankActions from '../../../reducers/BankRedux'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import calendar from '../../../svg/calendar.svg'
import 'react-circular-progressbar/dist/styles.css'
import moment from "moment"
import { Popover } from 'react-tiny-popover'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import profile_pic from '../../../svg/profile_pic.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'

class PaymentsChild extends Component {

    dateFormat = "YYYY-MM-DD"

    constructor(props) {
        super(props)
        this.state = {
            changeGoalNameValue: false,
            showChildProfile: false,
            childInfo: "",
            displayCalendar: false,
            anchorEl: null,
            calendarIsClosed: true,
            getTransictionsCalled: false,
            fromDate: undefined,
            toDate: undefined,
            payments: [],
            inputValue: "",
            childrenList: []
        }
    }

    componentDidMount() {
        // Get first and last day of current week
        var curr = new Date
        var first = curr.getDate() - curr.getDay() - 10
        var last = first + 6
        var firstday = new Date(curr.setDate(first))
        var lastday = new Date(curr.setDate(last))

        this.props.getPaymentAllRequest({
            from: moment(firstday).format(this.dateFormat),
            to: moment(lastday).format(this.dateFormat)
        })

        this.setState({
            payments: this.props.bank.getPaymentAllResponse?.data?.data,
            inputValue: moment(firstday).format(this.dateFormat) + " - " + moment(lastday).format(this.dateFormat),
            childrenList: this.props.parent.getParentDashboardResponse?.data?.data?.childList
        })
    }

    componentDidUpdate() {
        if (this.state.getTransictionsCalled) {
            !this.props.bank.fetchingApiButton &&
                this.setState({
                    payments: this.props.bank.getPaymentAllResponse?.data?.data,
                    getTransictionsCalled: false
                })
        }
    }

    onEditGoal = (e) => {
        this.setState({
            editGoalModal: e.target.innerText
        })
    }

    onShowChildProfile = (e) => {
        this.setState({
            showChildProfile: true,
            childInfo: e.target.innerText
        })
        this.props.handleShowChildProfile(true)
    }

    onSelectDateRanges = ({ selection }) => {
        let { startDate, endDate } = selection

        startDate = moment(startDate)
        startDate = startDate.isValid() ? startDate.toDate() : undefined

        endDate = moment(endDate)
        endDate = endDate.isValid() ? endDate.toDate() : undefined

        let inputValue = ""
        if (startDate) inputValue += moment(startDate).format(this.dateFormat)
        if (endDate) inputValue += " - " + moment(endDate).format(this.dateFormat)

        this.setState({
            fromDate: startDate,
            toDate: endDate,
            inputValue
        })
    }

    processInputValue(value) {
        let [fromDate, toDate] = value.split("-").map(elm => elm.trim())

        fromDate = moment(fromDate, this.dateFormat)
        fromDate = fromDate.isValid() ? fromDate.toDate() : undefined

        toDate = moment(toDate, this.dateFormat)
        toDate = toDate.isValid() ? toDate.toDate() : undefined

        return { fromDate, toDate }
    }

    onAdornmentClick = (e) => {
        this.setState({
            displayCalendar: true,
            anchorEl: e.currentTarget
        })
    }

    onPopoverClose = () => {
        this.setState({
            displayCalendar: false,
            anchorEl: null,
            calendarIsClosed: false
        })
        if (!this.state.calendarIsClosed) {
            this.props.getPaymentAllRequest({
                to: moment(this.state.toDate).format(this.dateFormat),
                from: moment(this.state.fromDate).format(this.dateFormat)
            })
            this.setState({
                calendarIsClosed: true,
                getTransictionsCalled: true
            })
        }
    }

    render() {
        const { t } = this.props

        return (
            <Modal
                isOpen={this.props.showPaymentsChild}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className="w-80">
                    <span className="yg-greeting-name">{t('payments')}</span>
                    {
                        this.props.bank.fetchingApiButton ?
                            <button
                                type="button"
                                className="btn yg-btn yg-btn-black mt-4"
                            >
                                <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                            </button> :
                            <>
                                <div className="pt-4 expenses-with-chart">
                                    <Popover
                                        isOpen={this.state.displayCalendar}
                                        positions={['top', 'bottom', 'left', 'right']}
                                        content={() => (
                                            <DateRangePicker
                                                ranges={[
                                                    {
                                                        startDate: this.state.fromDate,
                                                        endDate: this.state.toDate,
                                                        key: "selection"
                                                    }
                                                ]}
                                                editableDateInputs={true}
                                                moveRangeOnFirstSelection={false}
                                                onChange={this.onSelectDateRanges}
                                                showMonthAndYearPickers={true}
                                                showDateDisplay={false}
                                                scroll={{ enabled: true }}
                                            />
                                        )}
                                        onClickOutside={this.onPopoverClose}
                                    >
                                        <div className="yg-expenses-form-main" onClick={this.onAdornmentClick}>
                                            <img src={calendar} alt="Calendar" className="expenses-svg" />
                                            <input
                                                className="yg-expenses-form-div-input input-calendar"
                                                type="text"
                                                value={
                                                    this.state.inputValue.substring(8, 10) + " " +
                                                    (this.state.inputValue.substring(5, 7) === "01" ? "January" :
                                                        this.state.inputValue.substring(5, 7) === "02" ? "February" :
                                                            this.state.inputValue.substring(5, 7) === "03" ? "March" :
                                                                this.state.inputValue.substring(5, 7) === "04" ? "April" :
                                                                    this.state.inputValue.substring(5, 7) === "05" ? "May" :
                                                                        this.state.inputValue.substring(5, 7) === "06" ? "June" :
                                                                            this.state.inputValue.substring(5, 7) === "07" ? "July" :
                                                                                this.state.inputValue.substring(5, 7) === "08" ? "August" :
                                                                                    this.state.inputValue.substring(5, 7) === "09" ? "September" :
                                                                                        this.state.inputValue.substring(5, 7) === "10" ? "October" :
                                                                                            this.state.inputValue.substring(5, 7) === "11" ? "November" :
                                                                                                this.state.inputValue.substring(5, 7) === "12" ? "December" :
                                                                                                    null
                                                    ) + " - " + this.state.inputValue.substring(21, 23) + " " +
                                                    (this.state.inputValue.substring(18, 20) === "01" ? "January" :
                                                        this.state.inputValue.substring(18, 20) === "02" ? "February" :
                                                            this.state.inputValue.substring(18, 20) === "03" ? "March" :
                                                                this.state.inputValue.substring(18, 20) === "04" ? "April" :
                                                                    this.state.inputValue.substring(18, 20) === "05" ? "May" :
                                                                        this.state.inputValue.substring(18, 20) === "06" ? "June" :
                                                                            this.state.inputValue.substring(18, 20) === "07" ? "July" :
                                                                                this.state.inputValue.substring(18, 20) === "08" ? "August" :
                                                                                    this.state.inputValue.substring(18, 20) === "09" ? "September" :
                                                                                        this.state.inputValue.substring(18, 20) === "10" ? "October" :
                                                                                            this.state.inputValue.substring(18, 20) === "11" ? "November" :
                                                                                                this.state.inputValue.substring(18, 20) === "12" ? "December" :
                                                                                                    null)
                                                }
                                                placeholder={t('please_choose_date_range')}
                                                readOnly
                                            />
                                        </div>
                                    </Popover>
                                </div>
                                <div className="pt-5">
                                    <span className="yg-card-alwn-costs-q-xs">
                                        {this.state.payments?.[0]?.executed_at?.substring(8, 10)}&nbsp;
                                        {
                                            this.state.payments?.[0]?.executed_at?.substring(5, 7) === "01" ?
                                                "JANUARY" :
                                                this.state.payments?.[0]?.executed_at?.substring(5, 7) === "02" ?
                                                    "FEBRUARY" :
                                                    this.state.payments?.[0]?.executed_at?.substring(5, 7) === "03" ?
                                                        "MARCH" :
                                                        this.state.payments?.[0]?.executed_at?.substring(5, 7) === "04" ?
                                                            "APRIL" :
                                                            this.state.payments?.[0]?.executed_at?.substring(5, 7) === "05" ?
                                                                "MAY" :
                                                                this.state.payments?.[0]?.executed_at?.substring(5, 7) === "06" ?
                                                                    "JUNE" :
                                                                    this.state.payments?.[0]?.executed_at?.substring(5, 7) === "07" ?
                                                                        "JULY" :
                                                                        this.state.payments?.[0]?.executed_at?.substring(5, 7) === "08" ?
                                                                            "AUGUST" :
                                                                            this.state.payments?.[0]?.executed_at?.substring(5, 7) === "09" ?
                                                                                "SEPTEMBER" :
                                                                                this.state.payments?.[0]?.executed_at?.substring(5, 7) === "10" ?
                                                                                    "OCTOBER" :
                                                                                    this.state.payments?.[0]?.executed_at?.substring(5, 7) === "11" ?
                                                                                        "NOVEMBER" :
                                                                                        this.state.payments?.[0]?.executed_at?.substring(5, 7) === "12" ?
                                                                                            "DECEMBER" :
                                                                                            null
                                        }
                                    </span>
                                    {
                                        this.state.payments?.map((i, k) => {
                                            return (
                                                <div key={k} className="yg-card-head-payments mt-2 py-2">
                                                    <div>
                                                        <img src={profile_pic} alt="Avatar" className="yg-card-avatar-min" />
                                                        <span className="card-header yg-payments-span">
                                                            {i.beneficiary_name}
                                                            {
                                                                i.payment_type === "allowance" ? t('s_weekly_allowance') :
                                                                    i.payment_type === "single_payment" ? t('s_bank_transfer') :
                                                                        t('s_bank_transfer')
                                                            }
                                                        </span>
                                                    </div>
                                                    <span className="yg-card-number-small-time">{i.executed_at?.substring(11, 16)}</span>
                                                    <div>
                                                        <span className="yg-card-number-small-time">£</span>
                                                        <span className="yg-card-number-min-s">{i.amount}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                    }
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPaymentAllRequest: (body) => dispatch(BankActions.getPaymentAllRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank,
        parent: state.parent
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsChild)