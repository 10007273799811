import React, { Component } from 'react'
import { connect } from 'react-redux'
import ParentActions from '../../../../reducers/ParentRedux'
import { toast } from 'react-toastify'
import calendar from '../../../../svg/calendar.svg'
import {
    AreaChart,
    GridlineSeries,
    Gridline,
    AreaSeries,
    PointSeries,
    LinearXAxis,
    LinearYAxis,
    LinearXAxisTickSeries,
    Line,
    Area,
    Gradient,
    GradientStop,
    LinearYAxisTickSeries,
    LinearYAxisTickLabel,
    LinearXAxisTickLabel
} from 'reaviz'

class GoalsSavings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            amount: "5",
            period: "onetime",
            otherValue: 0,
            goalAdded: false,
            onSaveButtonClicked: false
        }
    }

    componentDidUpdate() {
        if (this.props.parent?.addGoalResponse?.data?.messages?.message === "Chores added successfully" && this.state.goalAdded) {
            toast.success('Goal successfully added!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                goalAdded: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        } else if (this.props.parent?.addGoalResponse?.response?.data?.messages?.message === "Please provide child_sub, name, period, amount attributes" && this.state.onSaveButtonClicked) {
            toast.error('Please fill up all fields!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                onSaveButtonClicked: false
            })
        }
    }

    onNameChange = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    onAmountChange = (e) => {
        this.setState({
            amount: e.target.id
        })
    }

    onPeriodChange = (e) => {
        this.setState({
            period: e.target.id
        })
    }

    onOtherChange = (e) => {
        this.setState({
            otherValue: e.target.value
        })
    }

    onAddGoalToApi = () => {
        this.props.addGoalRequest({
            child_sub: this.props.selectedChild.sub,
            name: this.state.name,
            period: this.state.period === "onetime" ? 1 : this.state.period === "daily" ? 2 : 3,
            amount: this.state.amount === "other" ? parseInt(this.state.otherValue) : parseInt(this.state.amount)
        })
        this.setState({
            goalAdded: true,
            onSaveButtonClicked: true
        })
    }

    render() {
        const { t } = this.props

        const data_values = [
            {
                key: 0,
                data: 5
            },
            {
                key: 5,
                data: 10
            },
            {
                key: 10,
                data: 14
            },
            {
                key: 15,
                data: 5
            },
            {
                key: 20,
                data: 18
            }
        ]

        return (
            <div>
                <div className="yg-expenses-form-div mt-4">
                    <img src={calendar} alt="Calendar" className="expenses-svg" />
                    <span className="yg-headline-label-light-xs">Last 6 weeks</span>
                </div>
                <div className="mt-4">
                    <div
                        style={{
                            width: '100%',
                            height: '120px'
                        }}
                    >
                        <AreaChart
                            gridlines={
                                <GridlineSeries
                                    line={
                                        <Gridline
                                            direction="y"
                                            strokeColor={this.props.childInfo?.color === "1" ? '#03A6F7' : '#ff5b02'}
                                            strokeWidth={0.7}
                                        />
                                    }
                                />}
                            data={data_values}
                            yAxis={<LinearYAxis
                                type="value"
                                axisLine={null}
                                tickSeries={
                                    <LinearYAxisTickSeries
                                        line={null}
                                        label={<LinearYAxisTickLabel padding={15} />}
                                    />
                                }
                            />}
                            xAxis={<LinearXAxis
                                type="value"
                                axisLine={null}
                                tickSeries={
                                    <LinearXAxisTickSeries
                                        line={null}
                                        label={
                                            <LinearXAxisTickLabel
                                                padding={15}
                                            />
                                        }
                                    />
                                }
                            />}
                            series={
                                <AreaSeries
                                    colorScheme={this.props.childInfo?.color === "1" ? '#03A6F7' : '#ff5b02'}
                                    symbols={<PointSeries show={true} />}
                                    area={
                                        <Area
                                            gradient={
                                                <Gradient
                                                    stops={[
                                                        <GradientStop offset="30%" stopOpacity={0} />,
                                                        <GradientStop offset="95%" stopOpacity={1} />
                                                    ]}
                                                />
                                            }
                                        />
                                    }
                                    line={<Line strokeWidth={1} />}
                                />
                            }
                        />
                    </div>
                </div>
                <div className="yg-carddtls-savingaccount mt-3">
                    <div className="yg-carddtls-expdtls">
                        <span className="yg-headline-label-dark py-2">{t('total_savings')}</span>
                        <div className="yg-balance-header">
                            <div>
                                <span className="yg-card-alwn-costs-q-xs">£</span>
                                <span className="yg-card-number-min">
                                    {this.props.common?.goalsListResponse?.data?.data?.savingAccount?.data?.current}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="yg-carddtls-expdtls">
                        <div className="yg-carddtls-exprow">
                            <div className="yg-balance-header-left">
                                <span className="yg-cc-topname yg-balance-headername-min">12 September</span>
                                <span className="yg-card-number-small-time">09:30</span>
                            </div>
                        </div>
                        <div className="yg-balance-header">
                            <div>
                                <span className="pound-gray">£</span>
                                <span className="yg-headline-label-dark">5</span>
                            </div>
                        </div>
                    </div>
                    <div className="yg-carddtls-expdtls">
                        <div className="yg-carddtls-exprow">
                            <div className="yg-balance-header-left">
                                <span className="yg-cc-topname yg-balance-headername-min">5 September</span>
                                <span className="yg-card-number-small-time">04:30</span>
                            </div>
                        </div>
                        <div className="yg-balance-header">
                            <div>
                                <span className="pound-gray">£</span>
                                <span className="yg-headline-label-dark">3.50</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addGoalRequest: (body) => dispatch(ParentActions.addGoalRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent,
        common: state.common
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoalsSavings)