import React, { Component } from 'react'
import cancel from '../../../../svg/cancel.svg'
import chore_white from '../../../../svg/chore_white.svg'
import logo_single_big from '../../../../svg/logos/logo_single_big.svg'
import EditChore from './edit-chore'
import AddChore from './add-chore'
import { connect } from 'react-redux'
import CommonActions from '../../../../reducers/CommonRedux'
import ParentActions from '../../../../reducers/ParentRedux'
import PureModal from 'react-pure-modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-pure-modal/dist/react-pure-modal.min.css'

class ChoresDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedChore: null,
            addChoreModal: false,
            editChoreModal: false,
            selectedChild: null,
            todo: [],
            done: [],
            earned: [],
            pending: [],
            approveModal: false,
            choreId: "",
            choreApproved: false,
            choreDenied: false,            
        }
    }

    componentDidMount() {
        let selectedChild = this.props.child !== undefined ? this.props.child : this.props.childrenList[0]
        this.props.choresListRequest({
            userId: selectedChild.sub
        })
        //setTimeout(() => {
        // this.setState({
        //     todo: this.props.common?.choresListResponse?.data?.data.todo,
        //     done: this.props.common?.choresListResponse?.data?.data.done,
        //     earned: this.props.common?.choresListResponse?.data?.data.earned,
        //     pending: this.props.common?.choresListResponse?.data?.data.pending
        // })
        //}, 500)
    }

    componentDidUpdate(prevProps, prevState) {        
        if (prevProps.common?.fetchingApiButton && this.props.common?.choresListResponse?.data?.data !== undefined){            
            this.setState({
                todo: this.props.common?.choresListResponse?.data?.data.todo,
                done: this.props.common?.choresListResponse?.data?.data.done,
                earned: this.props.common?.choresListResponse?.data?.data.earned,
                pending: this.props.common?.choresListResponse?.data?.data.pending
            })
        }
        if (prevProps.selectedOption !== this.props.selectedOption) {
            
            let selectedChild = this.props.child !== undefined ? this.props.child :
                this.props.selectedOption?.name === undefined ? this.props.childrenList[0] :
                    this.props.childrenList.find((e) => {
                        console.log(e.name)
                        return e.name === this.props.selectedOption?.name
                    })
            this.props.choresListRequest({
                userId: selectedChild.sub
            })            
        }
        if (this.props.parent.approveChoreResponse?.response?.data?.success === false && prevProps.parent.fetchingApiButton){
            toast.error(this.props.parent.approveChoreResponse.response?.data?.messages.message, {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        
        if (this.props.parent.approveChoreResponse?.data?.success & this.state.choreApproved) {
            toast.success('Chore successfully approved!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                choreApproved: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        }
        if (this.props.parent.approveChoreResponse?.data?.success & this.state.choreDenied) {
            toast.success('Chore successfully denied and moved back to "To Do"!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                choreDenied: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        }
    }

    onEditChore = (selectedChore) => {
        this.setState({
            editChoreModal: true,
            selectedChore: selectedChore
        })
        this.props.handleCheckEditChoreModal(true)
    }

    onAddChore = () => {
        this.setState({
            addChoreModal: true
        })
        this.props.handleCheckAddChoreModal(true)
    }

    onApproveChore = (selectedChore) => {
        this.setState({
            approveModal: true,
            choreId: selectedChore.id
        })
    }

    onCloseApproveModal = () => {
        this.setState({ approveModal: false })
    }

    onApproveChoreApi = () => {
        this.props.approveChoreRequest({
            userId: this.props.child?.sub, // Child sub
            id: this.state.choreId, // Chore ID
            approved: true
        })
        this.setState({ choreApproved: true })
    }

    onDenyChore = () => {
        this.props.approveChoreRequest({
            userId: this.props.child?.sub, // Child sub
            id: this.state.choreId, // Chore ID
            approved: false
        })
        this.setState({ choreDenied: true })
    }

    render() {
        const { t } = this.props

        let selectedChild =
            this.props.child !== undefined ? this.props.child :
                this.props.selectedOption?.value === undefined ? this.props.childrenList[0] :
                    this.props.childrenList.find((e) => {
                        return e.name === this.props.selectedOption?.value
                    })

        return (
            <>
                {
                    this.state.editChoreModal ?
                        // Edit Chore
                        <EditChore
                            t={t}
                            selectedChild={selectedChild}
                            selectedChore={this.state.selectedChore}
                            handleCloseModal={this.props.handleCloseModal}
                        /> :
                        // Add Chore
                        this.state.addChoreModal ?
                            <AddChore
                                t={t}
                                selectedChild={selectedChild}
                                handleCloseModal={this.props.handleCloseModal}
                            /> :
                            // Chores Details Table
                            <div className="pt-2">
                                <span className="yg-greeting-name">
                                    {(this.props.selectedOption?.name === undefined ? selectedChild.name : this.props.selectedOption?.name) + t('s_chores')}
                                </span>
                                {
                                    this.props.common.fetchingApiButton ?
                                        <button
                                            type="button"
                                            className="btn yg-btn yg-btn-black mt-3"
                                        >
                                            <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                        </button> :
                                        <>
                                            <button
                                                type="button"
                                                onClick={this.onAddChore}
                                                className="btn yg-parentdb-btn-dark mt-3 yg-headline-label-light-xs-white">
                                                <img src={chore_white} alt="Chore" className="expenses-svg" />&nbsp;{t('add_chore')}
                                            </button>
                                            <div className="yg-cc-pb-top mt-5">
                                                <span className="yg-headline-label-light-xs-black">{t('pending_approval')}</span>
                                                <span className="yg-headline-label-light-xs-black">{this.state.pending?.length}&nbsp;{this.state.pending?.length === 1 ? t('chore') : t('chores')}</span>
                                            </div>
                                            <div className="yg-password-form-div">
                                                {
                                                    this.state.pending?.map((i, k) => {
                                                        return (
                                                            <button
                                                                key={k}
                                                                className={selectedChild.color === "1" ? "yg-expenses-form-div-details-secondary-gradient-blue" : "yg-expenses-form-div-details-secondary-gradient-orange"}
                                                                onClick={() => this.onApproveChore(i)}
                                                            >
                                                                <div className="yg-radio-form-chores">
                                                                    <span className={selectedChild.color === "1" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                                        {i.name}
                                                                    </span>
                                                                    <div>
                                                                        <span className="pound-gray">£</span>
                                                                        <span className={selectedChild.color === "1" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                                            {i.amount}
                                                                        </span>
                                                                        <span className="pound-gray">
                                                                            /{i.period === 1 ? "One Time" : i.period === 2 ? "Daily" : "Weekly"}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <PureModal
                                                header={t('do_you_approve_your_child_done_chore')}
                                                footer={
                                                    <div className='yg-radio-form-sa'>
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn-min yg-btn-white-g"
                                                            onClick={this.onApproveChoreApi}
                                                        >
                                                            {t('approve')}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn-red w-100"
                                                            onClick={this.onDenyChore}
                                                        >
                                                            {t('deny')}
                                                        </button>
                                                    </div>
                                                }
                                                isOpen={this.state.approveModal}
                                                onClose={this.onCloseApproveModal}
                                            >
                                                <p>{t('your_child_will_be_notified_chore')}</p>
                                            </PureModal>
                                            <div className="yg-cc-pb-top">
                                                <span className="yg-headline-label-light-xs-black">{t('to_do')}</span>
                                                <span className="yg-headline-label-light-xs-black">{this.state.todo?.length}&nbsp;{this.state.todo?.length === 1 ? t('chore') : t('chores')}</span>
                                            </div>
                                            <div className="yg-password-form-div">
                                                {
                                                    this.state.todo?.map((i, k) => {
                                                        return (
                                                            <button
                                                                key={k}
                                                                className={selectedChild.color === "1" ? "yg-expenses-form-div-details-secondary-white" : "yg-expenses-form-div-details-white-primary"}
                                                                onClick={() => this.onEditChore(i)}
                                                            >
                                                                <div className="yg-radio-form-chores">
                                                                    <span className={selectedChild.color === "1" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                                        {i.name}
                                                                    </span>
                                                                    <div>
                                                                        <span className="pound-gray">£</span>
                                                                        <span className={selectedChild.color === "1" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                                            {i.amount}
                                                                        </span>
                                                                        <span className="pound-gray">
                                                                            /{i.period === 1 ? "One Time" : i.period === 2 ? "Daily" : "Weekly"}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="yg-cc-pb-top">
                                                <span className="yg-headline-label-light-xs-black">{t('done')}</span>
                                                <span className="yg-headline-label-light-xs-black">{this.state.done?.length}&nbsp;{this.state.done?.length === 1 ? t('chore') : t('chores')}</span>
                                            </div>
                                            {
                                                this.state.done?.map((i, k) => {
                                                    return (
                                                        <div className="yg-expenses-form-div-details-secondary-green">
                                                            <div className="yg-radio-form-chores">
                                                                <div className="yg-radio-form-sa">
                                                                    <label className="yg-check-green">
                                                                        <input type="checkbox" checked />
                                                                        <span className="checkmark-green"></span>
                                                                    </label>
                                                                    <span className="yg-headline-label-light-xs-green">
                                                                        {i.name}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="pound-gray">£</span>
                                                                    <span className="yg-headline-label-light-s-green">
                                                                        {i.amount}
                                                                    </span>
                                                                    <span className="pound-gray">
                                                                        /{i.period === 1 ? "One Time" : i.period === 2 ? "Daily" : "Weekly"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="yg-cc-pb-top pt-4">
                                                <span className="yg-headline-label-light-xs-black">{t('earnings')}</span>
                                                <span className="yg-headline-label-light-xs-black"><span className="pound-gray">£</span>0</span>
                                            </div>
                                            <div>
                                                <div className="yg-password-form-div">
                                                    {
                                                        this.state.earned?.map((i, k) => {
                                                            return (
                                                                <button key={k} className={"yg-expenses-form-div-details-" + i.backcolor}>
                                                                    <div className="yg-radio-form-chores">
                                                                        <span className={i.backcolor === "secondary-white" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                                            {i.name}
                                                                        </span>
                                                                        <div>
                                                                            <span className="pound-gray">£</span>
                                                                            <span className={i.backcolor === "secondary-white" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                                                {i.spent}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                }
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        choresListRequest: (body) => dispatch(CommonActions.choresListRequest(body)),
        approveChoreRequest: (body) => dispatch(ParentActions.approveChoreRequest(body)),
    }
}
const mapStateToProps = (state) => {
    return {
        parent: state.parent,
        common: state.common
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChoresDetails)