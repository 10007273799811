import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable'
import rootSaga from '../sagas/'
import store from './store'
import localforage from 'localforage'

const transformerConfig = {
    whitelistPerReducer: {
    },
}

const persistConfig = {
    key: 'root',
    storage: localforage,
    stateReconciler: seamlessImmutableReconciler,
    transforms: [seamlessImmutableTransformCreator(transformerConfig)]
}

/* ------------- Redux Configuration ------------- */
//const middleware = []
//const enhancers = []

/* ------------- Assemble Middleware ------------- */
//enhancers.push(applyMiddleware(...middleware))

const appReducer = combineReducers({
    user: require('./UserRedux').reducer,
    parent: require('./ParentRedux').reducer,
    child: require('./ChildRedux').reducer,
    common: require('./CommonRedux').reducer,
    verification: require('./VerificationRedux').reducer,
    bank: require('./BankRedux').reducer,
    main: require('./MainRedux').reducer,
    notification: require('./NotificationRedux').reducer
})

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_REDUX') {
        state = undefined
    }
    return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const reduxStore = store(persistedReducer, rootSaga)

export const persistor = persistStore(reduxStore)