import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getParentDashboardRequest: [],
    getParentDashboardSuccess: ['data'],
    getParentDashboardFailure: ['error'],

    getChildListRequest: [],
    getChildListSuccess: ['data'],
    getChildListFailure: ['error'],

    addChildRequest: ['body'],
    addChildSuccess: ['data'],
    addChildFailure: ['error'],

    setAllowanceRequest: ['body'],
    setAllowanceSuccess: ['data'],
    setAllowanceFailure: ['error'],

    setVrpAllowanceRequest: ['body'],
    setVrpAllowanceSuccess: ['data'],
    setVrpAllowanceFailure: ['error'],

    setVrpProviderRequest: ['body'],
    setVrpProviderSuccess: ['data'],
    setVrpProviderFailure: ['error'],

    setAllowanceRequest: ['body'],
    setAllowanceSuccess: ['data'],
    setAllowanceFailure: ['error'],

    addChoresRequest: ['body'],
    addChoresSuccess: ['data'],
    addChoresFailure: ['error'],

    editChoreRequest: ['body'],
    editChoreSuccess: ['data'],
    editChoreFailure: ['error'],

    deleteChoreRequest: ['body'],
    deleteChoreSuccess: ['data'],
    deleteChoreFailure: ['error'],

    approveChoreRequest: ['body'],
    approveChoreSuccess: ['data'],
    approveChoreFailure: ['error'],

    addGoalRequest: ['body'],
    addGoalSuccess: ['data'],
    addGoalFailure: ['error'],

    editGoalRequest: ['body'],
    editGoalSuccess: ['data'],
    editGoalFailure: ['error'],

    deleteGoalRequest: ['body'],
    deleteGoalSuccess: ['data'],
    deleteGoalFailure: ['error'],

    deleteChildRequest: ['body'],
    deleteChildSuccess: ['data'],
    deleteChildFailure: ['error'],

    generateTempPassRequest: ['body'],
    generateTempPassSuccess: ['data'],
    generateTempPassFailure: ['error'],

    revokeMandateRequest: ['body'],
    revokeMandateSuccess: ['data'],
    revokeMandateFailure: ['error']
})

export const ParentTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    getParentDashboardResponse: null,
    getParentDashboardError: false,
    getParentDashboardErrorMessage: '',

    getChildListResponse: null,
    getChildListError: false,
    getChildListErrorMessage: '',

    addChildResponse: null,
    addChildError: false,
    addChildErrorMessage: '',

    setAllowanceResponse: null,
    setAllowanceError: false,
    setAllowanceErrorMessage: '',

    setVrpAllowanceResponse: null,
    setVrpAllowanceError: false,
    setVrpAllowanceErrorMessage: '',

    setVrpProviderResponse: null,
    setVrpProviderError: false,
    setVrpProviderErrorMessage: '',

    addChoresResponse: null,
    addChoresError: false,
    addChoresErrorMessage: '',

    editChoreResponse: null,
    editChoreError: false,
    editChoreErrorMessage: '',

    deleteChoreResponse: null,
    deleteChoreError: false,
    deleteChoreErrorMessage: '',

    approveChoreResponse: null,
    approveChoreError: false,
    approveChoreErrorMessage: '',

    addGoalResponse: null,
    addGoalError: false,
    addGoalErrorMessage: '',

    editGoalResponse: null,
    editGoalError: false,
    editGoalErrorMessage: '',

    deleteGoalResponse: null,
    deleteGoalError: false,
    deleteGoalErrorMessage: '',

    deleteChildResponse: null,
    deleteChildError: false,
    deleteChildErrorMessage: '',

    generateTempPassResponse: null,
    generateTempPassError: false,
    generateTempPassErrorMessage: '',

    revokeMandateResponse: null,
    revokeMandateError: false,
    revokeMandateErrorMessage: '',

    fetchingApi: false,
    fetchingApiButton: false,
    fetchingApiAllowance: false,
})

/* ------------- Reducers ------------- */

/* ------------- Get Parent Dashboard ------------- */
export const getParentDashboardRequest = (state, action) => {
    return state.merge({
        getParentDashboardResponse: null,
        getParentDashboardError: false,
        getParentDashboardErrorMessage: '',
        fetchingApi: true
    })
}
export const getParentDashboardSuccess = (state, action) => {
    return state.merge({
        getParentDashboardResponse: action.data,
        getParentDashboardError: false,
        getParentDashboardErrorMessage: '',
        fetchingApi: false
    })
}
export const getParentDashboardFailure = (state, action) => {
    return state.merge({
        getParentDashboardError: true,
        getParentDashboardErrorMessage: action.error,
        fetchingApi: false
    })
}

/* ------------- Get Child List ------------- */
export const getChildListRequest = (state, action) => {
    return state.merge({
        getChildListResponse: null,
        getChildListError: false,
        getChildListErrorMessage: '',
        fetchingApi: true
    })
}
export const getChildListSuccess = (state, action) => {
    return state.merge({
        getChildListResponse: action.data,
        getChildListError: false,
        getChildListErrorMessage: '',
        fetchingApi: false
    })
}
export const getChildListFailure = (state, action) => {
    return state.merge({
        getChildListError: true,
        getChildListErrorMessage: action.error,
        fetchingApi: false
    })
}

/* ------------- Add Child ------------- */
export const addChildRequest = (state, action) => {
    return state.merge({
        addChildResponse: null,
        addChildError: false,
        addChildErrorMessage: '',
        fetchingApiButton: true
    })
}
export const addChildSuccess = (state, action) => {
    return state.merge({
        addChildResponse: action.data,
        addChildError: false,
        addChildErrorMessage: '',
        fetchingApiButton: false
    })
}
export const addChildFailure = (state, action) => {
    return state.merge({
        addChildError: true,
        addChildErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Set Allowance ------------- */
export const setAllowanceRequest = (state, action) => {
    return state.merge({
        setAllowanceResponse: null,
        setAllowanceError: false,
        setAllowanceErrorMessage: '',
        fetchingApiButton: true
    })
}
export const setAllowanceSuccess = (state, action) => {
    return state.merge({
        setAllowanceResponse: action.data,
        setAllowanceError: false,
        addChildErrorMessage: '',
        fetchingApiButton: false
    })
}
export const setAllowanceFailure = (state, action) => {
    return state.merge({
        setAllowanceError: true,
        setAllowanceErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Set VRP Allowance ------------- */

export const setVrpAllowanceRequest = (state, action) => {
    return state.merge({
        setVrpAllowanceResponse: null,
        setVrpAllowanceError: false,
        setVrpAllowanceErrorMessage: '',
        fetchingApiButton: true
    })
}
export const setVrpAllowanceSuccess = (state, action) => {
    return state.merge({
        setVrpAllowanceResponse: action.data,
        setVrpAllowanceError: false,
        // addChildErrorMessage: '',
        fetchingApiButton: false
    })
}
export const setVrpAllowanceFailure = (state, action) => {
    return state.merge({
        setVrpAllowanceError: true,
        setVrpAllowanceErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Set VRP Provider ------------- */

export const setVrpProviderRequest = (state, action) => {
    return state.merge({
        setVrpProviderResponse: null,
        setVrpProviderError: false,
        setVrpProviderErrorMessage: '',
        fetchingApiAllowance: true
    })
}
export const setVrpProviderSuccess = (state, action) => {
    return state.merge({
        setVrpProviderResponse: action.data,
        setVrpProviderError: false,
        // addChildErrorMessage: '',
        fetchingApiAllowance: false
    })
}
export const setVrpProviderFailure = (state, action) => {
    return state.merge({
        setVrpProviderError: true,
        setVrpProviderErrorMessage: action.error,
        fetchingApiAllowance: false
    })
}

/* ------------- Revoke Mandate ------------- */

export const revokeMandateRequest = (state, action) => {
    return state.merge({
        revokeMandateResponse: null,
        revokeMandateError: false,
        revokeMandateErrorMessage: '',
        fetchingApiButton: true
    })
}
export const revokeMandateSuccess = (state, action) => {
    return state.merge({
        revokeMandateResponse: action.data,
        revokeMandateError: false,
        revokeMandateErrorMessage: '',
        fetchingApiButton: false
    })
}
export const revokeMandateFailure = (state, action) => {
    return state.merge({
        revokeMandateResponse: null,
        revokeMandateError: true,
        revokeMandateErrorMessage: action.error,
        fetchingApiButton: false
    })
}


/* ------------- Add Chores ------------- */
export const addChoresRequest = (state, action) => {
    return state.merge({
        addChoresResponse: null,
        addChoresError: false,
        addChoresErrorMessage: ''
    })
}
export const addChoresSuccess = (state, action) => {
    return state.merge({
        addChoresResponse: action.data,
        addChoresError: false,
        addChoresErrorMessage: ''
    })
}
export const addChoresFailure = (state, action) => {
    return state.merge({
        addChoresError: true,
        addChoresErrorMessage: action.error
    })
}

/* ------------- Edit Chore ------------- */
export const editChoreRequest = (state, action) => {
    return state.merge({
        editChoreResponse: null,
        editChoreError: false,
        editChoreErrorMessage: ''
    })
}
export const editChoreSuccess = (state, action) => {
    return state.merge({
        editChoreResponse: action.data,
        editChoreError: false,
        editChoreErrorMessage: ''
    })
}
export const editChoreFailure = (state, action) => {
    return state.merge({
        editChoreError: true,
        editChoreErrorMessage: action.error
    })
}

/* ------------- Delete Chore ------------- */
export const deleteChoreRequest = (state, action) => {
    return state.merge({
        deleteChoreResponse: null,
        deleteChoreError: false,
        deleteChoreErrorMessage: ''
    })
}
export const deleteChoreSuccess = (state, action) => {
    return state.merge({
        deleteChoreResponse: action.data,
        deleteChoreError: false,
        deleteChoreErrorMessage: ''
    })
}
export const deleteChoreFailure = (state, action) => {
    return state.merge({
        deleteChoreError: true,
        deleteChoreErrorMessage: action.error
    })
}

/* ------------- Approve Chore ------------- */
export const approveChoreRequest = (state, action) => {
    return state.merge({
        approveChoreResponse: null,
        approveChoreError: false,
        approveChoreErrorMessage: '',
        fetchingApiButton: true    
    })
}
export const approveChoreSuccess = (state, action) => {
    return state.merge({
        approveChoreResponse: action.data,
        approveChoreError: false,
        approveChoreErrorMessage: '',
        fetchingApiButton: false
    })
}
export const approveChoreFailure = (state, action) => {
    return state.merge({
        approveChoreError: true,
        approveChoreErrorMessage: action.error,
        fetchingApiButton: false
    })
}

/* ------------- Add Goal ------------- */
export const addGoalRequest = (state, action) => {
    return state.merge({
        addGoalResponse: null,
        addGoalError: false,
        addGoalErrorMessage: ''
    })
}
export const addGoalSuccess = (state, action) => {
    return state.merge({
        addGoalResponse: action.data,
        addGoalError: false,
        addGoalErrorMessage: ''
    })
}
export const addGoalFailure = (state, action) => {
    return state.merge({
        addGoalError: true,
        addGoalErrorMessage: action.error
    })
}

/* ------------- Edit Goal ------------- */
export const editGoalRequest = (state, action) => {
    return state.merge({
        editGoalResponse: null,
        editGoalError: false,
        editGoalErrorMessage: ''
    })
}
export const editGoalSuccess = (state, action) => {
    return state.merge({
        editGoalResponse: action.data,
        editGoalError: false,
        editGoalErrorMessage: ''
    })
}
export const editGoalFailure = (state, action) => {
    return state.merge({
        editGoalError: true,
        editGoalErrorMessage: action.error
    })
}

/* ------------- Delete Goal ------------- */
export const deleteGoalRequest = (state, action) => {
    return state.merge({
        deleteGoalResponse: null,
        deleteGoalError: false,
        deleteGoalErrorMessage: ''
    })
}
export const deleteGoalSuccess = (state, action) => {
    return state.merge({
        deleteGoalResponse: action.data,
        deleteGoalError: false,
        deleteGoalErrorMessage: ''
    })
}
export const deleteGoalFailure = (state, action) => {
    return state.merge({
        deleteGoalError: true,
        deleteGoalErrorMessage: action.error
    })
}

/* ------------- Delete Child ------------- */
export const deleteChildRequest = (state, action) => {
    return state.merge({
        deleteChildResponse: null,
        deleteChildError: false,
        deleteChildErrorMessage: ''
    })
}
export const deleteChildSuccess = (state, action) => {
    return state.merge({
        deleteChildResponse: action.data,
        deleteChildError: false,
        deleteChildErrorMessage: ''
    })
}
export const deleteChildFailure = (state, action) => {
    return state.merge({
        deleteChildError: true,
        deleteChildErrorMessage: action.error
    })
}

/* ------------- Generate New Pass ------------- */
export const generateTempPassRequest = (state, action) => {
    return state.merge({
        generateTempPassResponse: null,
        generateTempPassError: false,
        generateTempPassErrorMessage: ''
    })
}
export const generateTempPassSuccess = (state, action) => {
    return state.merge({
        generateTempPassResponse: action.data,
        generateTempPassError: false,
        generateTempPassErrorMessage: ''
    })
}
export const generateTempPassFailure = (state, action) => {
    return state.merge({
        generateTempPassError: true,
        generateTempPassErrorMessage: action.error
    })
}

/* ------------- Connection Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_PARENT_DASHBOARD_REQUEST]: getParentDashboardRequest,
    [Types.GET_PARENT_DASHBOARD_SUCCESS]: getParentDashboardSuccess,
    [Types.GET_PARENT_DASHBOARD_FAILURE]: getParentDashboardFailure,

    [Types.GET_CHILD_LIST_REQUEST]: getChildListRequest,
    [Types.GET_CHILD_LIST_SUCCESS]: getChildListSuccess,
    [Types.GET_CHILD_LIST_FAILURE]: getChildListFailure,

    [Types.ADD_CHILD_REQUEST]: addChildRequest,
    [Types.ADD_CHILD_SUCCESS]: addChildSuccess,
    [Types.ADD_CHILD_FAILURE]: addChildFailure,

    [Types.SET_ALLOWANCE_REQUEST]: setAllowanceRequest,
    [Types.SET_ALLOWANCE_SUCCESS]: setAllowanceSuccess,
    [Types.SET_ALLOWANCE_FAILURE]: setAllowanceFailure,

    [Types.SET_VRP_ALLOWANCE_REQUEST]: setVrpAllowanceRequest,
    [Types.SET_VRP_ALLOWANCE_SUCCESS]: setVrpAllowanceSuccess,
    [Types.SET_VRP_ALLOWANCE_FAILURE]: setVrpAllowanceFailure,

    [Types.SET_VRP_PROVIDER_REQUEST]: setVrpProviderRequest,
    [Types.SET_VRP_PROVIDER_SUCCESS]: setVrpProviderSuccess,
    [Types.SET_VRP_PROVIDER_FAILURE]: setVrpProviderFailure,

    [Types.ADD_CHORES_REQUEST]: addChoresRequest,
    [Types.ADD_CHORES_SUCCESS]: addChoresSuccess,
    [Types.ADD_CHORES_FAILURE]: addChoresFailure,

    [Types.EDIT_CHORE_REQUEST]: editChoreRequest,
    [Types.EDIT_CHORE_SUCCESS]: editChoreSuccess,
    [Types.EDIT_CHORE_FAILURE]: editChoreFailure,

    [Types.DELETE_CHORE_REQUEST]: deleteChoreRequest,
    [Types.DELETE_CHORE_SUCCESS]: deleteChoreSuccess,
    [Types.DELETE_CHORE_FAILURE]: deleteChoreFailure,

    [Types.APPROVE_CHORE_REQUEST]: approveChoreRequest,
    [Types.APPROVE_CHORE_SUCCESS]: approveChoreSuccess,
    [Types.APPROVE_CHORE_FAILURE]: approveChoreFailure,

    [Types.ADD_GOAL_REQUEST]: addGoalRequest,
    [Types.ADD_GOAL_SUCCESS]: addGoalSuccess,
    [Types.ADD_GOAL_FAILURE]: addGoalFailure,

    [Types.EDIT_GOAL_REQUEST]: editGoalRequest,
    [Types.EDIT_GOAL_SUCCESS]: editGoalSuccess,
    [Types.EDIT_GOAL_FAILURE]: editGoalFailure,

    [Types.DELETE_GOAL_REQUEST]: deleteGoalRequest,
    [Types.DELETE_GOAL_SUCCESS]: deleteGoalSuccess,
    [Types.DELETE_GOAL_FAILURE]: deleteGoalFailure,

    [Types.DELETE_CHILD_REQUEST]: deleteChildRequest,
    [Types.DELETE_CHILD_SUCCESS]: deleteChildSuccess,
    [Types.DELETE_CHILD_FAILURE]: deleteChildFailure,

    [Types.GENERATE_TEMP_PASS_REQUEST]: generateTempPassRequest,
    [Types.GENERATE_TEMP_PASS_SUCCESS]: generateTempPassSuccess,
    [Types.GENERATE_TEMP_PASS_FAILURE]: generateTempPassFailure,

    [Types.REVOKE_MANDATE_REQUEST]: revokeMandateRequest,
    [Types.REVOKE_MANDATE_SUCCESS]: revokeMandateSuccess,
    [Types.REVOKE_MANDATE_FAILURE]: revokeMandateFailure
})