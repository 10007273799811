import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { connect } from 'react-redux'
import ChildActions from '../../../reducers/ChildRedux'
import CommonActions from '../../../reducers/CommonRedux'
import { ToastContainer, toast } from 'react-toastify'
import PureModal from 'react-pure-modal'
import 'react-pure-modal/dist/react-pure-modal.min.css'

class Chores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addChoreModal: false,
            amount: "5",
            period: "onetime",
            choreName: "",
            changeChoreNameValue: false,
            confirmModal: false,
            todo: [],
            done: [],
            earned: [],
            pending: [],
            choreId: "",
            choreSentForApproval: false
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.props.choresListRequest({
            userId: this.props.user.userData.attributes.sub
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.child.completeChoresResponse?.data?.data?.completed && this.state.choreSentForApproval) {
            toast.success('Chore successfully sent for approval!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            this.setState({
                choreSentForApproval: false
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
            }, 1500)
        }
        if (prevProps.common.fetchingApiButton === true && this.props.common?.choresListResponse?.data?.data !== undefined){
            console.log("test")
            setTimeout(() => {
                this.setState({
                    todo: this.props.common?.choresListResponse?.data?.data.todo,
                    done: this.props.common?.choresListResponse?.data?.data.done,
                    earned: this.props.common?.choresListResponse?.data?.data.earned,
                    pending: this.props.common?.choresListResponse?.data?.data.pending
                })
            }, 500)
        }
    }

    onAddChore = () => {
        this.setState({ addChoreModal: true })
    }

    onAmountChange = (e) => {
        this.setState({ amount: e.target.id })
    }

    onPeriodChange = (e) => {
        this.setState({ period: e.target.id })
    }

    onChoreNameChange = (e) => {
        this.setState({ choreName: e.target.value })
    }

    handleChangeChoreName = (changeChoreName) => {
        this.setState({ changeChoreNameValue: changeChoreName })
    }

    onCloseConfirmModal = () => {
        this.setState({ confirmModal: false })
    }

    onConfirmChore = (e, item) => {
        if (e.target.checked) {
            this.setState({
                confirmModal: true,
                choreId: item.id
            })
        }
    }

    onConfirmChoreApi = (e, item) => {
        this.props.completeChoresRequest({
            id: this.state.choreId, // Chore ID
            completed: true
        })
        this.setState({
            choreSentForApproval: true
        })
    }

    render() {
        const { t } = this.props

        return (
            <Modal
                isOpen={this.props.showChoresModal}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                    <span className="yg-greeting-name">{t('chores')}</span>
                    {
                        this.props.common.fetchingApiButton ?
                            <button
                                type="button"
                                className="btn yg-btn yg-btn-black mt-5"
                            >
                                <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                            </button> :
                            <>
                                <div className="yg-cc-pb-top mt-5">
                                    <span className="yg-headline-label-light-xs-black">{t('pending_approval')}</span>
                                    <span className="yg-headline-label-light-xs-black">{this.state.pending?.length}&nbsp;{this.state.pending?.length === 1 ? t('chore') : t('chores')}</span>
                                </div>
                                <div className="yg-password-form-div">
                                    {
                                        this.state.pending?.map((i, k) => {
                                            return (
                                                <div
                                                    key={k}
                                                    className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-expenses-form-div-details-secondary-gradient-blue" : "yg-expenses-form-div-details-secondary-gradient-orange"}
                                                >
                                                    <div className="yg-radio-form-chores">
                                                        <span className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                            {i.name}
                                                        </span>
                                                        <div>
                                                            <span className="pound-gray">£</span>
                                                            <span className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                                {i.amount}
                                                            </span>
                                                            <span className="pound-gray">
                                                                /{i.period === 1 ? "One Time" : i.period === 2 ? "Daily" : "Weekly"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="yg-cc-pb-top">
                                    <span className="yg-headline-label-light-xs-black">{t('to_do')}</span>
                                    <span className="yg-headline-label-light-xs-black">{this.state.todo?.length}&nbsp;{this.state.todo?.length === 1 ? t('chore') : t('chores')}</span>
                                </div>
                                <div>
                                    <div className="yg-password-form-div">
                                        {
                                            this.state.todo?.map((i, k) => {
                                                
                                                return (
                                                    <div
                                                        key={k}
                                                        className={this.props.user.userData.attributes["custom:color"] === "2" ? "yg-expenses-form-div-details-white-primary" : "yg-expenses-form-div-details-secondary-white"}
                                                    >
                                                        <div className="yg-balance-header-left">
                                                            <span className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-headline-label-light-xs-blue" : "yg-headline-label-light-xs-orange"}>
                                                                {i?.name}
                                                            </span>
                                                            <div>
                                                                <span className="pound-gray">£</span>
                                                                <span className={this.props.user.userData.attributes["custom:color"] === "1" ? "yg-headline-label-light-s-blue" : "yg-headline-label-light-s-orange"}>
                                                                    {i.amount}
                                                                </span>
                                                                <span className="pound-gray">
                                                                    /{i?.period === 1 ? 'One Time' : i?.period === 2 ? 'Daily' : 'Weekly'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <label className="yg-check">
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => this.onConfirmChore(e, i)}
                                                                />
                                                                <span className={this.props.user.userData.attributes["custom:color"] === "2" ? "checkmark-orange" : "checkmark-blue"}></span>
                                                            </label>
                                                        </div>
                                                        <PureModal
                                                            header={t('are_you_sure_you_finished_this_chore')}
                                                            footer={
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn yg-btn-min yg-btn-white-w"
                                                                        onClick={(e) => this.onConfirmChoreApi(e, i)}
                                                                    >
                                                                        {t('yes_i_finished')}
                                                                    </button>
                                                                </div>
                                                            }
                                                            isOpen={this.state.confirmModal}
                                                            onClose={this.onCloseConfirmModal}
                                                        >
                                                            <p>{t('your_parent_will_be_notified_chore')}</p>
                                                        </PureModal>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="yg-cc-pb-top">
                                    <span className="yg-headline-label-light-xs-black">{t('done')}</span>
                                    <span className="yg-headline-label-light-xs-black">{this.state.done?.length}&nbsp;{this.state.done?.length === 1 ? t('chore') : t('chores')}</span>
                                </div>
                                {
                                    this.state.done?.map((i, k) => {
                                        return (
                                            <div className="yg-expenses-form-div-details-secondary-green">
                                                <div className="yg-radio-form-chores">
                                                    <div className="yg-radio-form-sa">
                                                        <label className="yg-check-green">
                                                            <input type="checkbox" checked />
                                                            <span className="checkmark-green"></span>
                                                        </label>
                                                        <span className="yg-headline-label-light-xs-green">
                                                            {i.name}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="pound-gray">£</span>
                                                        <span className="yg-headline-label-light-s-green">
                                                            {i.amount}
                                                        </span>
                                                        <span className="pound-gray">
                                                            /{i.period === 1 ? "One Time" : i.period === 2 ? "Daily" : "Weekly"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="yg-cc-pb-top pt-4">
                                    <span className="yg-headline-label-light-xs-black">{t('earnings')}</span>
                                    <span className="yg-headline-label-light-xs-black"><span className="pound-gray">£</span>14</span>
                                </div>
                                <div>
                                    <div className="yg-password-form-div">
                                        {
                                            this.state.earned?.map((i, k) => {
                                                return (
                                                    <div
                                                        key={k}
                                                        className={this.props.user.userData.attributes["custom:color"] === "2" ? "yg-expenses-form-div-details-white-primary" : "yg-expenses-form-div-details-secondary-white"}
                                                    >
                                                        <div className="yg-radio-form-chores">
                                                            <span className={"yg-headline-label-light-xs-blue"}>
                                                                {i?.name}
                                                            </span>
                                                            <div>
                                                                <span className="pound-gray">£</span>
                                                                <span className={"yg-headline-label-light-s-blue"}>
                                                                    {i?.amount}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        completeChoresRequest: (body) => dispatch(ChildActions.completeChoresRequest(body)),
        choresListRequest: (body) => dispatch(CommonActions.choresListRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        child: state.child,
        common: state.common,
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Chores)
