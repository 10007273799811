import React, { Component } from 'react'
import BankActions from '../../../reducers/BankRedux'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

class BankAccountsDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showChangeAccountModal: false,
            bankAccounts: [],
            account: {},
            chooseSavingsAccount: false,
            accountChosen: false
        }
    }

    componentDidMount() {
        this.props.getAccountsRequest({
            code: this.props.returnedCode
        })        
    }

    componentDidUpdate(prevProps,prevState){
        if (prevProps.bank?.getAccountsResponse === null && this.props.bank.getAccountsResponse.data.data.results){
            this.setState({
                bankAccounts: this.props.bank.getAccountsResponse.data.data.results
            })            
        }
    }

    onChooseAccount = (account) => {
        this.setState({
            account: account
        })
    }

    onSaveMainAccount = () => {
        this.props.setAccountRequest({
            accountId: this.state.account?.account_id,
            accountName: this.state.account?.display_name,
            accountIban: this.state.account?.account_number.iban,
            providerName: this.state.account?.provider.display_name,
            accountType: 1
        })
        if (this.props.role === "parent") {
            this.setState({
                accountChosen: true
            })
        } else {
            this.setState({
                chooseSavingsAccount: true
            })
        }
    }

    onSaveSavingsAccount = () => {
        this.props.setAccountRequest({
            accountId: this.state.account.account_id,
            accountName: this.state.account.display_name,
            accountIban: this.state.account.account_number.iban,
            providerName: this.state.account.provider.display_name,
            accountType: 2
        })
        this.setState({
            accountChosen: true
        })
        this.props.handleCloseModal();
    }

    render() {
        const { t } = this.props

        return (
            <div className={window.innerWidth < 769 ? "w-90" : "w-70"}>
                <span className="yg-greeting-name">{t('bank_account')}</span>
                {
                    this.props.bank.fetchingApiButton ?
                        <button
                            type="button"
                            className="btn yg-btn yg-btn-black mt-5"
                        >
                            <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                        </button> :
                        <>
                            {
                                this.state.chooseSavingsAccount ?
                                    <div className="bank-aclist-form">
                                        <span className="yg-headline-label pb-5">{t('you_should_select_savings_bank_account')}</span>
                                        <div className="bank-list w-100">
                                            {
                                                (this.state.bankAccounts?.filter((i) => i.display_name?.includes("SAVINGS"))).map((i, k) => {
                                                    return (
                                                        <button
                                                            className="yg-gradient bank-accounts w-100 mb-3"
                                                            onClick={() => this.onChooseAccount(i)}
                                                            key={k}
                                                        >
                                                            <span className="yg-headline-label-light-s-black pt-3 pb-5">{i.display_name}</span>
                                                            <span className="yg-card-alwn-days">{i.display_name}</span>
                                                            <span className="yg-headline-label-light-xs-black pt-2 pb-3">{i.account_number.number}</span>
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            this.props.bank.fetchingApiButton ?
                                                <button
                                                    type="button"
                                                    className="btn yg-btn yg-btn-black mt-5"
                                                >
                                                    <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                                </button> :
                                                <button
                                                    type="button"
                                                    onClick={this.onSaveSavingsAccount}
                                                    className="btn yg-btn yg-btn-black mt-5"
                                                    disabled={this.state.account === {} ? true : false}
                                                >
                                                    {t('save')}
                                                </button>
                                        }
                                    </div> :
                                    <div className="bank-aclist-form">
                                        <span className="yg-codelogin-headline-main pb-2">{t('your_bank_integration_is_complete')}</span>
                                        <span className="yg-headline-label pb-5">{t('you_should_select_main_bank_account')}</span>
                                        <div className="bank-list w-100">
                                            {
                                                (this.state.bankAccounts?.filter((i) => i.display_name?.includes("TRANSACTION"))).map((i, k) => {
                                                    return (
                                                        <button
                                                            className="yg-gradient bank-accounts w-100 mb-3"
                                                            onClick={() => this.onChooseAccount(i)}
                                                            key={k}
                                                        >
                                                            <span className="yg-headline-label-light-s-black pt-3 pb-5">{i.display_name}</span>
                                                            <span className="yg-card-alwn-days">{i.display_name}</span>
                                                            <span className="yg-headline-label-light-xs-black pt-2 pb-3">{i.account_number.number}</span>
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                        <button
                                            type="button"
                                            onClick={this.onSaveMainAccount}
                                            className="btn yg-btn yg-btn-black mt-5"
                                            disabled={this.state.account === {} ? true : false}
                                        >
                                            {t('save')}
                                        </button>
                                    </div>
                            }
                        </>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountsRequest : (body) => dispatch(BankActions.getAccountsRequest(body)),
        getAccountsDashboardRequest: () => dispatch(BankActions.getAccountsDashboardRequest()),
        setAccountRequest: (body) => dispatch(BankActions.setAccountRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BankAccountsDetails))