import React, { Component } from 'react'
import Countdown from 'react-countdown'
import phone from '../../../svg/illustrations/phone.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import { AutoTabProvider } from 'react-auto-tab'
import { connect } from 'react-redux'
import VerificationActions from '../../../reducers/VerificationRedux'
import UserActions from '../../../reducers/UserRedux'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'

class CheckPhone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code_1: '',
            code_2: '',
            code_3: '',
            code_4: '',
            code_5: '',
            code_6: '',
            userConfirmedInCognito: false,
            date: Date.now() + 60000,
            confirmCodeResent: false,
            loading: false,
            timerOut: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.confirmCodeResent) {
            this.setState({
                date: Date.now() + 60000,
                confirmCodeResent: false
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            userConfirmedInCognito: false
        })
    }

    // Code values for phone

    updateCode1Value = (e) => {
        this.setState({ code_1: e.target.value })
    }

    updateCode2Value = (e) => {
        this.setState({ code_2: e.target.value })
    }

    updateCode3Value = (e) => {
        this.setState({ code_3: e.target.value })
    }

    updateCode4Value = (e) => {
        this.setState({ code_4: e.target.value })
    }

    updateCode5Value = (e) => {
        this.setState({ code_5: e.target.value })
    }

    updateCode6Value = (e) => {
        this.setState({ code_6: e.target.value })
    }

    onSubmitPhone = async () => {
        let finalCode = this.state.code_1 + this.state.code_2 + this.state.code_3 + this.state.code_4 + this.state.code_5 + this.state.code_6

        try {
            await this.setState({ loading: true }, () => {
                Auth.confirmSignUp(this.props.username, finalCode).then(result => {
                    toast.success('Phone number successfully confirmed!', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    })
                    this.setState({
                        loading: false
                    })
                    setTimeout(() => {
                        this.props.handleGoToNextStep(true)
                    }, 1500);
                }).catch(() => {
                    this.setState({ loading: false })
                })
            })
        } catch (error) {
            toast.error('Provided code is wrong!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        const user = await Auth.signIn(this.props.username, this.props.password)
        this.props.setUserData(user)
        const session = await Auth.currentSession()
        this.props.getCodeRequest({
            access_token: session.getAccessToken().getJwtToken(),
            type: "email"
        })
        this.setState({
            userConfirmedInCognito: true
        })
    }

    resendConfirmationCode = async () => {
        try {
            await Auth.resendSignUp(this.props.username)
            toast.success('The confirmation code resent!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                confirmCodeResent: true
            })
        } catch (error) {
            toast.error('There was an error with resending the code!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }
    }

    onReceiveCode = () => {
        this.setState({
            timerOut: true
        })
    }

    render() {
        const { t } = this.props

        const countRenderer = ({ minutes, seconds }) => {
            return (
                <span className="yg-card-number-small-time">{minutes}:{seconds}</span>
            )
        }

        return (
            <div className="code-form">
                <img src={phone} alt="Phone" className="yg-parentdb-nochildimg" />
                <div className="form-group yg-form">
                    <span className="yg-codelogin-headline-main">{t('check_your_phone')}</span>
                    <div className="yg-codelogin-form">
                        <label htmlFor="yg-password" className="yg-headline-label">{t('enter_six_digit_code')}</label>
                        <AutoTabProvider className="yg-code-partitioned-div-cem">
                            <input
                                className={this.state.code_1.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                name="code"
                                type="text"
                                maxLength={1}
                                tabbable="true"
                                value={this.state.code_1}
                                onChange={this.updateCode1Value} />
                            <input
                                className={this.state.code_2.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                name="code"
                                type="text"
                                maxLength={1}
                                tabbable="true"
                                value={this.state.code_2}
                                onChange={this.updateCode2Value} />
                            <input
                                className={this.state.code_3.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                name="code"
                                type="text"
                                maxLength={1}
                                tabbable="true"
                                value={this.state.code_3}
                                onChange={this.updateCode3Value} />
                            <input
                                className={this.state.code_4.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                name="code"
                                type="text"
                                maxLength={1}
                                tabbable="true"
                                value={this.state.code_4}
                                onChange={this.updateCode4Value} />
                            <input
                                className={this.state.code_5.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                name="code"
                                type="text"
                                maxLength={1}
                                tabbable="true"
                                value={this.state.code_5}
                                onChange={this.updateCode5Value} />
                            <input
                                className={this.state.code_6.length > 0 ? "yg-code-partitioned" : "yg-code-partitioned-light"}
                                name="code"
                                type="text"
                                maxLength={1}
                                tabbable="true"
                                value={this.state.code_6}
                                onChange={this.updateCode6Value} />
                        </AutoTabProvider>
                        <Countdown
                            date={this.state.date}
                            renderer={countRenderer}
                            onComplete={this.onReceiveCode}
                        />
                    </div>
                    <div className="yg-btn-form">
                        {
                            this.state.loading ?
                                <button
                                    type="button"
                                    className="btn yg-btn yg-btn-black"
                                >
                                    <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                                </button> :
                                <button
                                    type="button"
                                    onClick={this.onSubmitPhone}
                                    className="btn yg-btn yg-btn-black"
                                    disabled={this.state.code_6.length === 0 ? true : false}
                                >
                                    {t('send')}
                                </button>
                        }
                    </div>
                    <div className="yg-notreceivedform">
                        <span>{t('didnt_receive_the_code')}</span>
                        <button
                            className={this.state.timerOut ? "yg-codeform-link" : "yg-codeform-link-disabled"}
                            disabled={this.state.timerOut ? false : true}
                            onClick={this.resendConfirmationCode}
                        >
                            {t('receive')}
                        </button>
                    </div>
                    <ToastContainer
                        position="bottom-left"
                        className="toast-container-mobile"
                        autoClose={1000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (userData) => dispatch(UserActions.setUserData(userData)),
        getCodeRequest: (body) => dispatch(VerificationActions.getCodeRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        verification: state.verification
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckPhone)