import React, { Component } from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import ChildrenCards from '../dashboard/children-cards'
import TransferChild from '../modals/parent/transfer-child'
import SetAllowance from '../modals/parent/set-allowance'
import AllExpenses from '../modals/parent/all-expenses'
import Chores from '../modals/parent/chores/chores'
import Goals from '../modals/parent/goals/goals'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ParentActions from '../../reducers/ParentRedux'
import goal_blue from '../../svg/goal_blue.svg'
import goal_orange from '../../svg/goal_orange.svg'
import chore_blue from '../../svg/chore_blue.svg'
import chore_orange from '../../svg/chore_orange.svg'
import budget_blue from '../../svg/budget_blue.svg'
import budget_orange from '../../svg/budget_orange.svg'
import transaction_blue from '../../svg/transaction_blue.svg'
import transaction_orange from '../../svg/transaction_orange.svg'
import transfer from '../../svg/transfer.svg'
import chore from '../../svg/chore.svg'
import goal from '../../svg/goal.svg'
import select_back_black from '../../svg/select_back_black.svg'
import PureModal from 'react-pure-modal'
import 'react-pure-modal/dist/react-pure-modal.min.css'
import { ToastContainer, toast } from 'react-toastify'
// Expenses icons
import shopping from '../../svg/expenses/shopping.svg'
import gambling from '../../svg/expenses/gambling.svg'
import fees from '../../svg/expenses/fees.svg'
import uncategorized from '../../svg/expenses/uncategorized.svg'
import transport from '../../svg/expenses/transport.svg'

class ChildCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnBack: false,
            transferToChildModal: false,
            amount: 0,
            setTransferValue: false,
            showExpensesModal: false,
            showChoresModal: false,
            showGoalsModal: false,
            showAllowanceModal: false,
            earnings: [],
            goals_list: [],
            cancelAllowanceWindow: false,
            revoked: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                earnings: this.props.common?.choresListResponse?.data?.data.earned,
                goals_list: this.props.common?.goalsListResponse?.data?.data.Items
            })
        }, 500)
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.parent.revokeMandateResponse?.data?.success === true && !prevProps.parent.revokeMandateResponse?.data?.success && this.state.revoked){
            // console.log("if statement check child card");
            // console.log("current props: ",this.props);
            // console.log("prev props: ",prevProps);
            this.props.getParentDashboardRequest();
            toast.success('Allowance is cancelled!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            this.setState({
                revoked:false
            })
        }
    }

    onReturnBack = () => {
        this.setState({
            returnBack: true
        })
    }

    // Modals
    onCancelAllowance = () => {
        this.setState({
            cancelAllowanceWindow: true
        })
    }

    onCloseCancelAllowance = () => {
        this.setState({
            cancelAllowanceWindow: false
        })
    }

    handleCloseModal = () => {
        this.setState({
            transferToChildModal: false,
            setTransferValue: false,
            showExpensesModal: false,
            showChoresModal: false,
            showGoalsModal: false,
            showAllowanceModal: false
        })
    }

    onTransferToChild = () => {
        this.setState({ transferToChildModal: true })
    }

    onShowAllowance = () => {
        this.setState({ showAllowanceModal: true })
    }

    onShowExpenses = () => {
        this.setState({ showExpensesModal: true })
    }

    onShowChores = () => {
        this.setState({ showChoresModal: true })
    }

    onShowGoals = () => {
        this.setState({ showGoalsModal: true })
    }

    cancelAllowance = () => {
        this.props.revokeMandateRequest({
            mandate_id: this.props.childInfo?.allowance?.mandateId
        })
        this.setState({
            cancelAllowanceWindow: false,
            revoked:true,
        })
    }

    render() {
        const { t } = this.props
        let child = this.props.childInfo
        
        return (
            <>
                <TransferChild
                    child={child}
                    t={t}
                    handleCloseModal={this.handleCloseModal}
                    transferToChildModal={this.state.transferToChildModal}
                />
                {
                    this.state.showAllowanceModal ?
                        <SetAllowance
                            child={child}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showAllowanceModal={this.state.showAllowanceModal}
                            defaultAllowance={this.props.childInfo?.allowance?.amount}
                        /> : null
                }
                {
                    this.state.showExpensesModal ?
                        <AllExpenses
                            childInfo={this.props.childInfo}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showExpensesModal={this.state.showExpensesModal}
                        /> : null
                }
                {
                    this.state.showChoresModal ?
                        <Chores
                            child={child}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showChoresModal={this.state.showChoresModal}
                        /> : null
                }
                {
                    this.state.showGoalsModal ?
                        <Goals
                            child={child}
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            showGoalsModal={this.state.showGoalsModal}
                        /> : null
                }
                { 
                                            <PureModal
                                                header={t('cancel_allowance')}
                                                footer={
                                                    <div className='yg-radio-form-sa'>
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn-min yg-btn-red"
                                                            onClick={this.cancelAllowance}
                                                        >
                                                            {t('yes_sure')}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn yg-btn-white-g w-100"
                                                            onClick={this.onCloseCancelAllowance}
                                                        >
                                                            {t('cancel')}
                                                        </button>
                                                    </div>
                                                }
                                                isOpen={this.state.cancelAllowanceWindow}
                                                onClose={this.onCloseCancelAllowance}
                                            >
                                                <p>{t('sure_cancel_allowance')}</p>
                                            </PureModal>
                }
                {
                    this.state.returnBack ? <ChildrenCards childrenList={this.props.data.childrenList} t={t} /> :
                        <>
                            <button className="yg-select-back-cards select-back-black" onClick={() => this.onReturnBack()}>
                                <img src={select_back_black} alt="Back" />
                            </button>
                            <div className="yg-greeting-parent pb-0 pt-0">
                                <div className="yg-greeting-parent-name">{child.name}{t('s_dashboard')}</div>
                                <div className="yg-greeting-parent-balance">
                                    <span className="yg-greeting-parent-balance-name">{child.name}{t('s_balance')}</span>
                                    <div>
                                        <span className="yg-card-alwn-costs-q">£</span>
                                        <span className="yg-greeting-parent-balance-number">{child.balance?.data?.current}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="yg-parentdb-buttons">
                                <button type="button" className="btn yg-parentdb-btn" onClick={this.onTransferToChild}>
                                    <img src={transfer} alt="Transfer" />&nbsp;{t('transfer')}
                                </button>
                                <button type="button" className="btn yg-parentdb-btn" onClick={this.onShowChores} >
                                    <img src={chore} alt="Chore" />&nbsp;{t('add_chore')}
                                </button>
                                <button type="button" className="btn yg-parentdb-btn" onClick={this.onShowGoals}>
                                    <img src={goal} alt="Goal" />&nbsp;{t('add_goal')}
                                </button>
                            </div>
                            <div className="yg-cards-grid">
                                <div className="yg-cards-grid-left">
                                    
                                        <div className={child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                            <div className="yg-selfcard-header-allowance">
                                                <span className="yg-headline-label-light-s-black">{t('allowance')}</span>
                                                {(child.allowance?.remainingDay === 0 && child.allowance?.amount === 0) || child.allowance?.completed === false ? <></> : <button className={child.color === "1" ? "yg-btn-blue" : "yg-btn-orange"} onClick={this.onCancelAllowance}>{t('cancel')}</button>
                                            }
                                                </div>
                                            <button onClick={this.onShowAllowance} className="card-button">
                                            {
                                                (child.allowance?.remainingDay === 0 && child.allowance?.amount === 0) || child.allowance?.completed === false ?
                                                    <div className={child.color === "1" ? "yg-selfcard-alwn-blue" : "yg-selfcard-alwn-orange"}>
                                                        <button
                                                            type="button"
                                                            className="btn yg-parentdb-btn-dark mt-2 yg-headline-label-light-xs-white">
                                                            {t('setup')}
                                                        </button>
                                                    </div> :
                                                    <div className={child.color === "1" ? "yg-selfcard-alwn-blue" : "yg-selfcard-alwn-orange"}>
                                                        <div className="yg-balance-header-left">
                                                            <span className="yg-balance-headerleft-name">{t('next')}</span>
                                                            <div>
                                                                <span className={child.color === "1" ? "yg-card-number-blue" : "yg-card-number-orange"}>{child.allowance?.remainingDay}</span>
                                                                <span className="yg-card-number-small-time">{t('days_later')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="yg-balance-header">
                                                            <span className="yg-balance-headername">{t('total')}</span>
                                                            <div>
                                                                <span className="yg-card-number-small-time">£</span>
                                                                <span className={child.color === "1" ? "yg-card-number-blue" : "yg-card-number-orange"}>{child.allowance?.amount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            </button>
                                        </div>
                                    
                                    <button onClick={this.onShowExpenses} className="card-button">
                                        <div className={child?.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                            <div className="yg-carddtls-head">
                                                <div className="yg-cc-pb-icon">
                                                    {child.color === "1" ? <img src={transaction_blue} alt="Transaction" className="yg-cc-icon" /> : <img src={transaction_orange} alt="Transaction" className="yg-cc-icon" />}
                                                </div>
                                                <span className="yg-headline-label-light-s-black">{t('expenses')}</span>
                                            </div>
                                            <div className="yg-carddtls-expdtls pt-2 pb-1">
                                                <span className="yg-card-number-small-time">{t('weekly')}</span>
                                                <div>
                                                    <span className="yg-card-number-small-time">£</span>
                                                    <span className={child.color === "1" ? "yg-card-number-blue-s" : "yg-card-number-orange-s"}>
                                                        {child?.expenses?.total}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={child.color === "1" ? "yg-carddtls-expenses-blue" : "yg-carddtls-expenses-orange"}>
                                                {child.expenses?.data?.map((i, k) => {
                                                    return (
                                                        <div key={k}>
                                                            {
                                                                <div className={child.color === "1" ? "yg-carddtls-expdtls-blue" : "yg-carddtls-expdtls-orange"}>
                                                                    <div className="yg-carddtls-exprow">
                                                                        <CircularProgressbarWithChildren
                                                                            className="expenses-progress"
                                                                            value={i.total}
                                                                            maxValue={child?.expenses?.total}
                                                                            styles={buildStyles({
                                                                                pathColor: child.color === "1" ? "#03A6F7" : "#ff5b02",
                                                                                trailColor: child.color === "1" ? "#CBEEFF" : "#ffe0cf"
                                                                            })}>
                                                                            <img
                                                                                className="yg-balance-header-img-s"
                                                                                src={
                                                                                    i.type?.includes("Shopping") ? shopping :
                                                                                        i.type?.includes("Gambling") ? gambling :
                                                                                            i.type?.includes("FeeCharges") ? fees :
                                                                                                i.type?.includes("Transport") ? transport :
                                                                                                    uncategorized
                                                                                }
                                                                                alt="Source"
                                                                            />
                                                                        </CircularProgressbarWithChildren>
                                                                        <div className="yg-balance-header-left">
                                                                            <span className="yg-cc-topname">{i?.name}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="yg-balance-header">
                                                                        <div>
                                                                            <span className="yg-card-alwn-costs-q-small">£</span>
                                                                            <span className="yg-card-number-small">{i.total}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div>
                                    <div className={child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                        <div className="yg-selfcard-head">
                                            <div className="yg-cc-pb-icon">
                                                {child.color === "1" ? <img src={budget_blue} className="yg-cc-icon" alt="Budget" /> : <img src={budget_orange} className="yg-cc-icon" alt="Budget" />}
                                            </div>
                                            <span className="yg-headline-label-light-s-black">{t('todays_budget')}</span>
                                        </div>
                                        <div className="yg-cc-pb-selfcard">
                                            <div className={child.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                                <div
                                                    className={child.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                    role="progressbar"
                                                    style={{ width: ((child.dailyBudget?.expensesAmount) / (child.dailyBudget?.maxBudget) * 100).toString() + "%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100" />
                                            </div>
                                            <div className="yg-selfcard-costs">
                                                <span className="yg-card-alwn-costs-q-small">£</span>
                                                <span className="yg-card-number-small">{child.dailyBudget?.expensesAmount}/{child.dailyBudget?.maxBudget}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={this.onShowChores} className="card-button">
                                        <div className={child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                            <div className="yg-selfcard-head">
                                                <div className="yg-cc-pb-icon">
                                                    {child.color === "1" ? <img src={chore_blue} className="yg-cc-icon" alt="Chore" /> : <img src={chore_orange} className="yg-cc-icon" alt="Chore" />}
                                                </div>
                                                <span className="yg-headline-label-light-s-black">{t('chores')}</span>
                                            </div>
                                            <div className="yg-cc-pb-selfcard">
                                                <div className={child.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                                    <div
                                                        className={child.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                        role="progressbar"
                                                        style={{ width: ((child.chores?.completedChoresAmount) / (child.chores?.totalChoresAmount) * 100).toString() + "%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100" />
                                                </div>
                                                <div className="yg-selfcard-costs">
                                                    <span className="yg-card-alwn-costs-q-small">£</span>
                                                    <span className="yg-card-number-small">{child.chores?.completedChoresAmount}/{child.chores?.totalChoresAmount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                    <button onClick={this.onShowGoals} className="card-button">
                                        <div className={child.color === "1" ? "col-12 card yg-selfcard-blue" : "col-12 card yg-selfcard-orange"}>
                                            <div className="yg-selfcard-head">
                                                <div className="yg-cc-pb-icon">
                                                    {child.color === "1" ? <img src={goal_blue} className="yg-cc-icon" alt="Goal" /> : <img src={goal_orange} className="yg-cc-icon" alt="Goal" />}
                                                </div>
                                                <span className="yg-headline-label-light-s-black">{t('goals')}</span>
                                            </div>
                                            <div className="yg-cc-pb-selfcard">
                                                <div className={child.color === "1" ? "progress yg-pb-blue-selfcard" : "progress yg-pb-orange-selfcard"}>
                                                    <div
                                                        className={child.color === "1" ? "progress-bar yg-pbb-blue" : "progress-bar yg-pbb-orange"}
                                                        role="progressbar"
                                                        style={{ width: ((child.goals?.totalGoalsBalance) / (child.goals?.totalGoalsTarget) * 100).toString() + "%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100" />
                                                </div>
                                                <div className="yg-selfcard-costs">
                                                    <span className="yg-card-alwn-costs-q-small">£</span>
                                                    <span className="yg-card-number-small">{child.goals?.totalGoalsBalance}/{child.goals?.totalGoalsTarget}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                
                            </div>
                        </>
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        revokeMandateRequest: (body) => dispatch(ParentActions.revokeMandateRequest(body)),
        getParentDashboardRequest: () => dispatch(ParentActions.getParentDashboardRequest()),
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        parent: state.parent,
        common: state.common
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChildCard))