import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'

class ChangeName extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: ''
        }
    }

    componentDidMount() {
        this.setState({
            name: this.props.name
        })
    }

    onInputNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    onChangeNameCognito = async () => {
        const user = await Auth.currentAuthenticatedUser()
        await Auth.updateUserAttributes(user, {
            name: this.state.name
        }).then((data) => {
            toast.success('Name successfully changed!', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            setTimeout(() => {
                this.props.handleCloseModal(true)
                this.props.handleSendChangedName(this.state.name)
            }, 1500)
        })
    }

    render() {
        const { t } = this.props

        return (
            <>
                <div className="w-80">
                    <span className="yg-greeting-parent-name">{t('change_full_name')}</span>
                    <div className="change-email-modal-form">
                        <div className="form-group yg-form-100 pt-5">
                            <label htmlFor="yg-email" className="yg-headline-label-dark w-100">{t('new_full_name')}</label>
                            <div className="yg-password-form w-100">
                                <input
                                    placeholder={t('ellie_smith')}
                                    name="email"
                                    type="text"
                                    className="form-control yg-form-input"
                                    id="yg-email"
                                    value={this.state.name}
                                    onChange={this.onInputNameChange}
                                />
                            </div>
                            <div className="yg-btn-form" style={{ display: 'flex' }}>
                                <button
                                    type="button"
                                    className="btn yg-btn yg-btn-black"
                                    onClick={this.onChangeNameCognito}
                                    disabled={this.state.name.length === 0 ? true : false}
                                >
                                    {t('save')}
                                </button>
                                <button
                                    type="button"
                                    className="btn yg-btn yg-btn-gray"
                                    onClick={this.props.handleCloseModal}
                                >
                                    {t('cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="bottom-left"
                    className="toast-container-mobile"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        )
    }
}

const mapDispatchToProps = () => {
    return {}
}
const mapStateToProps = (state) => {
    return {
        verification: state.verification,
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeName)