import React, { Component } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { connect } from 'react-redux'
import BankActions from '../../../reducers/BankRedux'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import moment from "moment"
import { Popover } from 'react-tiny-popover'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import calendar from '../../../svg/calendar.svg'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
// Expenses icons
import shopping from '../../../svg/expenses/shopping.svg'
import gambling from '../../../svg/expenses/gambling.svg'
import fees from '../../../svg/expenses/fees.svg'
import uncategorized from '../../../svg/expenses/uncategorized.svg'
import transport from '../../../svg/expenses/transport.svg'

class Expense extends Component {

    dateFormat = "YYYY-MM-DD"

    constructor(props) {
        super(props)
        this.state = {
            displayCalendar: false,
            inputValue: "",
            anchorEl: null,
            fromDate: undefined,
            toDate: undefined,
            calendarIsClosed: true,
            getTransictionsCalled: false,
            expenses: [],
            showExpense: false,
            expense: {}
        }
    }

    componentDidMount() {

        this.setState({
            inputValue: this.props.inputValue
        })
        this.setState({
            expense: this.props.expense,
            expenses: this.props.expenses
        })
    }

    componentDidUpdate() {
        if (this.state.getTransictionsCalled) {
            !this.props.bank.fetchingApiButton &&
                this.setState({
                    expenses: this.props.bank.getAccountTransactionsResponse?.data?.data?.data,
                    getTransictionsCalled: false
                })
        }
    }


    processInputValue(value) {
        let [fromDate, toDate] = value.split("-").map(elm => elm.trim())

        fromDate = moment(fromDate, this.dateFormat)
        fromDate = fromDate.isValid() ? fromDate.toDate() : undefined

        toDate = moment(toDate, this.dateFormat)
        toDate = toDate.isValid() ? toDate.toDate() : undefined

        return { fromDate, toDate }
    }

    onAdornmentClick = (e) => {
        this.setState({
            displayCalendar: true,
            anchorEl: e.currentTarget
        })
    }

    render() {
        const { t } = this.props        
        return (
            <div className={window.innerWidth < 769 ? "w-90" : "w-80"}>
                <span className="yg-greeting-name">{t('expenses')}</span>
                <div className="pt-4 expenses-with-chart">
                    {
                        this.props.bank.fetchingApiButton ?
                            <button
                                type="button"
                                className="btn yg-btn yg-btn-black"
                            >
                                <img id="animlogo-signup" src={logo_single_big} alt="Logo" />
                            </button> :
                            <Popover
                                isOpen={this.state.displayCalendar}
                                positions={['top', 'bottom', 'left', 'right']}
                                content={() => (
                                    <DateRangePicker
                                        ranges={[
                                            {
                                                startDate: this.props.fromDate,
                                                endDate: this.props.toDate,
                                                key: "selection"
                                            }
                                        ]}
                                        editableDateInputs={true}
                                        moveRangeOnFirstSelection={false}
                                        onChange={this.props.onSelectDateRanges}
                                        showMonthAndYearPickers={true}
                                        showDateDisplay={false}
                                        scroll={{ enabled: true }}
                                    />
                                )}
                                onClickOutside={this.props.onPopoverClose}
                            >
                                <div className="yg-expenses-form-main" onClick={this.onAdornmentClick}>
                                    <img src={calendar} alt="Calendar" className="expenses-svg" />
                                    <input
                                        className="yg-expenses-form-div-input input-calendar"
                                        type="text"
                                        value={this.props.inputValue}
                                        placeholder={t('please_choose_date_range')}
                                    />
                                </div>
                            </Popover>
                    }
                    <div className="expenses-chart">
                        <CircularProgressbarWithChildren
                            value={this.state.expense.amount}
                            maxValue={this.props.childInfo.expenses.totalAmount}
                            styles={buildStyles({
                                pathColor: this.props.childInfo.color === "1" ? "#03A6F7" : "#ff5b02",
                                trailColor: this.props.childInfo.color === "1" ? "#CBEEFF" : "#ffe0cf",
                            })}>
                            <img
                                className="yg-balance-header-img-details"
                                style={{ width: 50 }}
                                src={
                                    this.props.expense?.name?.includes("Shopping") ? shopping :
                                    this.props.expense?.name?.includes("Gambling") ? gambling :
                                    this.props.expense?.name?.includes("Fees") ? fees :
                                    this.props.expense?.name?.includes("Transport") ? transport :
                                                    uncategorized
                                }
                                alt="Icon"
                            />
                        </CircularProgressbarWithChildren>
                    </div>
                </div>
                <div className="yg-password-form-div">
                    <label htmlFor="yg-gender" className="yg-headline-label-dark">{t('expenses')}</label>
                    {
                        this.props.bank.fetchingApiButton ? null :
                            <div className="yg-carddtls-expenses">
                                <span className="yg-headline-label-dark py-2">Today</span>
                                {this.state.expense.items?.slice(0, 9)?.map((i, k) => {                                    
                                    return (
                                        <div key={k} className="yg-carddtls-expdtls">
                                            <div className="yg-carddtls-exprow">
                                                <div className="yg-balance-header-left">
                                                    <span className="yg-cc-topname yg-balance-headername-min">{i.description}</span>
                                                    <span className="yg-card-number-small-time">00:00</span>
                                                </div>
                                            </div>
                                            <div className="yg-balance-header">
                                                <div>
                                                    <span className="pound-gray">£</span>
                                                    <span className="yg-headline-label-dark">{i.amount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountTransactionsRequest: (body) => dispatch(BankActions.getAccountTransactionsRequest(body))
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Expense)