import { API } from 'aws-amplify'

const create = () => {

    const connectUrl = (token) => {
        // console.log(token)
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        };
        return API.get("YounGoMoneyAPI", "/bank/connect/url", requestOptions)
            .then(response => {                
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    const setAccount = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("   ", "/bank/setAccount", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return error;
            });
    }

    const getAccounts = (body, token) => {
        // console.log(token)
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        };
        return API.post("YounGoMoneyAPI", "/bank/accounts", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    const getAccountTransactions = (body, token) => {   
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/bank/account/transactions", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return error;
            })
    }

    const getAccountsDashboard = (token) => {
        // console.log(token)
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        }
        return API.get("YounGoMoneyAPI", "/bank/getAccounts", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            })
    }

    const transferMoney = (body, token) => {                
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/bank/transferMoney", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error.response.data);
                return { 'error': error.response.data }
            })
    }

    const getAccountBalance = (token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            response: true
        };
        return API.get("YounGoMoneyAPI", "/bank/account/balance", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return error;
            });
    }

    const getPaymentAll = (body, token) => {
        const requestOptions = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body,
            response: true
        }
        return API.post("YounGoMoneyAPI", "/bank/payment/all", requestOptions)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
                return { 'error': true }
            })
    }

    return {
        connectUrl,
        setAccount,
        getAccounts,
        getAccountBalance,
        getAccountsDashboard,
        getAccountTransactions,
        transferMoney,
        getPaymentAll
    }
}

export default {
    create
}