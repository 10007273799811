import React, { Component } from 'react'
import Modal from 'react-modal'
import cancel from '../../../svg/cancel.svg'
import BankActions from '../../../reducers/BankRedux'
import logo_single_big from '../../../svg/logos/logo_single_big.svg'
import BankAccountsDetails from '../common/bank-accounts-details'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BankConnectWindow from '../../helpers/bank-connect-window'

class BankAccountsChild extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showChangeAccountModal: false,
            responseUrl: null,
        }
    }

    componentDidMount() {
        this.props.getAccountsDashboardRequest()
    }

    componentDidUpdate(){
        console.log(this.props)
    }

    onChangeAccount = () => {
        this.setState({ showChangeAccountModal: true })
    }

    onWindowResponse = (err, res) => {
        if (err) {
            this.setState({ responseUrl: res })
        }
        else{
            console.log("connection successfull")
            this.setState({
                showChangeAccountModal:true,
                responseUrl: res
            })
        }
        console.log(res)        
    }

    handleCloseModal = () => {
        this.setState({showChangeAccountModal:false})
        this.props.getAccountsDashboardRequest()
    }

    render() {
        const { t } = this.props

        return (
            <Modal
                isOpen={this.props.showBankAccountsChild}
                className="transfer-modal"
                overlayClassName="transfer-modal-overlay"
                onRequestClose={this.props.handleCloseModal}
                ariaHideApp={false}
            >
                {
                    this.state.showChangeAccountModal ?
                        <BankAccountsDetails
                            t={t}
                            handleCloseModal={this.handleCloseModal}
                            role="child"
                            returnedCode={this.state.responseUrl}                          
                        /> :
                        <div className={window.innerWidth < 769 ? "w-90" : "w-70"}>
                            <span className="yg-greeting-name">{t('bank_account')}</span>
                            {                                
                                    <>
                                        <div className="yg-btn-form-lets-start pb-4 pt-5">
                                            {/* <button
                                                type="button"
                                                className="btn yg-btn-thin yg-btn-black mr-5"
                                                onClick={this.onChangeAccount}
                                            >
                                                <span className="yg-headline-label-light-xs-white">{t('change_account')}</span>
                                            </button> */}
                                            {
                                            <BankConnectWindow
                                                bridge={this.onWindowResponse}
                                            >
                                                <span className="yg-headline-label-light-xs-white">{t('change_account')}</span>
                                            </BankConnectWindow>
                                            }
                                        </div>
                                        {
                                            this.state.responseUrl === true ? <></> :
                                            this.props.bank?.getAccountsDashboardResponse?.data?.data?.accounts.map((i, k) => {
                                                return (
                                                    <div key={k} className="yg-gradient bank-accounts mb-3">
                                                        <span className="yg-headline-label-light-s-black pt-3 pb-5">{i.accountName}</span>
                                                        <span className="yg-card-alwn-days">{i.providerName}</span>
                                                        <span className="yg-headline-label-light-xs-black pt-2 pb-3">{i.accountIban}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                            }
                        </div>
                }
                <button className="btn-onclose-modal" onClick={this.props.handleCloseModal}>
                    <img src={cancel} alt="Cancel" />
                </button>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAccountsDashboardRequest: () => dispatch(BankActions.getAccountsDashboardRequest()),
        connectUrlRequest: () => dispatch(BankActions.connectUrlRequest()),        
    }
}
const mapStateToProps = (state) => {
    return {
        bank: state.bank
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BankAccountsChild))