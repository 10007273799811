import React, { useEffect, useState } from "react"
import { withRouter, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

function ConnectBankSuccess({ history }) {
    let location = useLocation()
    const [code, setCode] = useState(null)

    useEffect(() => {
        console.log("location", location)
        const code = new URLSearchParams(location.search).get("code")
        console.log("code", code)
        setCode(code)
    }, [location])

    const close = () => {
        window.opener.onSuccess(code)
        window.close()
    }

    const onRedirectMobile = () => {
        window.location.href = "co.teengle://redirect-page?code=" + code
    }

    return (
        <div className="connectBS-div">
            <span className="create-account-form-h1">
                Connect Bank Success
            </span>
            <button
                type="button"
                onClick={isMobile ? onRedirectMobile : close}
                className="btn yg-btn yg-btn-black connectBS-btn"
            >
                Go Home
            </button>
        </div>
    )
}

export default withRouter(ConnectBankSuccess)