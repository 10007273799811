import { call, put } from 'redux-saga/effects'
import NotificationTypes from '../reducers/NotificationRedux'
import MainTypes from '../reducers/MainRedux'
import { Auth } from 'aws-amplify'

const getToken = async () => {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
}

export function* getNotifications(api, action) {
    try {
        yield put(MainTypes.setLoader(true))
        let token = yield call(getToken)
        const response = yield call(api.getNotifications, action.body, token)
        yield put(MainTypes.setLoader(false))
        if (response.hasOwnProperty('error')) {
            yield put(NotificationTypes.getNotificationsFailure(response))
        } else {
            yield put(NotificationTypes.getNotificationsSuccess(response))
        }
    } catch (error) {
        yield put(MainTypes.setLoader(false))
        yield put(NotificationTypes.getNotificationsFailure(error))
    }
}

export function* getPermissions(api, action) {
    try {
        yield put(MainTypes.setLoader(true))
        let token = yield call(getToken)
        const response = yield call(api.getPermissions, action.body, token)
        yield put(MainTypes.setLoader(false))
        if (response.hasOwnProperty('error')) {
            yield put(NotificationTypes.getPermissionsFailure(response))
        } else {
            yield put(NotificationTypes.getPermissionsSuccess(response))
        }
    } catch (error) {
        yield put(MainTypes.setLoader(false))
        yield put(NotificationTypes.getPermissionsFailure(error))
    }
}

export function* changePermissions(api, action) {
    try {
        yield put(MainTypes.setLoader(true))
        let token = yield call(getToken)
        const response = yield call(api.changePermissions, action.body, token)
        yield put(MainTypes.setLoader(false))
        if (response.hasOwnProperty('error')) {
            yield put(NotificationTypes.changePermissionsFailure(response))
        } else {
            yield put(NotificationTypes.changePermissionsSuccess(response))
        }
    } catch (error) {
        yield put(MainTypes.setLoader(false))
        yield put(NotificationTypes.changePermissionsFailure(error))
    }
}