import React, { Component } from 'react'
import addchild from '../../../svg/illustrations/addchild.svg'
import AddChild from './add-child.js'

export default class AddChildBefore extends Component {

    constructor(props) {
        super(props)
        this.state = {
            add_child: false
        }
    }

    onSelect = () => {
        if (this.state.add_child) {
            this.setState({
                add_child: false
            })
        } else if (!this.state.add_child) {
            this.setState({
                add_child: true
            })
        }
    }

    onSendData = (data) => { this.props.handleSendData(data) }

    onAddChildLater = () => { this.props.goToLetsStartStep(true) }

    render() {
        const { t } = this.props

        return (
            <>
                {
                    !this.state.add_child ?
                        <div className="code-form">
                            <img src={addchild} alt="Add Child" className="yg-parentdb-nochildimg" />
                            <div className="form-group yg-form">
                                <span className="yg-codelogin-headline-main">{t("no_child_added")}</span>
                                <div className="yg-codelogin-form">
                                    <label htmlFor="yg-password" className="yg-headline-label">{t("no_child_desc")}</label>
                                </div>
                                <div className="yg-btn-form">
                                    <button
                                        type="button"
                                        onClick={this.onSelect}
                                        className="btn  yg-btn yg-btn-black"
                                    >
                                        {t('add_child')}
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    className="btn yg-btn yg-btn-gray mt-2"
                                    onClick={this.onAddChildLater}
                                >
                                    {t('later')}
                                </button>
                            </div>
                        </div> :
                        <AddChild t={t} handleGoToNextStep={this.props.handleGoToNextStep} handleSendData={this.onSendData} />
                }
            </>
        )
    }
}